@import "assets/styles/variables.scss";

.buttonWidget {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0;
  .buttonWidgetBtn {
    box-shadow: 0 0 5px 0px rgba(197, 223, 255, 0.5);
    border: 1px solid rgba(117, 135, 145, 0.25);
    border-radius: 6px;
    position: relative;
    width: 100%;
    margin-right: 0;
    &::after {
      content: "\f138";
      position: absolute;
      font-family: "FontAwesome";
      height: 100%;
      top: 0;
      bottom: 0;
      right: 20px;
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #3a5163;
    }
    .box:before,
    label:before,
    .box:after,
    label:after {
      display: none !important;
    }
    label {
      color: $blueGrey !important;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      margin-bottom: 0 !important;
      padding: 0 !important;

      #button-label {
        padding: 1.25rem 0;
        font-size: 16px;
      }

      [id$="-tooltip-icon"] {
        position: absolute;
        left: 10px;
        top: 10px;
        cursor: pointer;
        z-index: 9999;
        display: block;
      }
    }
  }

  @media screen and (min-width: 320px) and (max-width: 400px) {
    min-width: 18rem;
    text-align: left;
    .buttonWidgetBtn {
      #button-label {
        padding-left: 20px !important;
      }
      [id$="-tooltip-icon"] {
        display: none !important;
      }
    }
  }
  @media screen and (min-width: 400px) and (max-width: 720px) {
    min-width: 20rem;
    [id$="-tooltip-icon"] {
      display: none !important;
    }
  }
}
