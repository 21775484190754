@import 'assets/styles/variables.scss';

 .multistepForm {
  max-width: 800px;
  margin: auto;
}
.multistepForm .ui.horizontal.segments {
  height: 450px;
  margin-top: 104px;
}
.multistepForm .ui.horizontal.segments > .segment.leftSegment {
  background-color: rgb($secondaryLight);
  width: 266px;
}
.multistepForm .ui.horizontal.segments > .segment.rightSegment {
  width: 534px;
  height: 450px;
  display: flex;
}
.multistepForm .rightDiv {
  margin: auto;
}
.multistepForm .ui.form .fields {
  margin: 0px;
  padding: 0px;
  width: 506px;
}
.multistepForm .ui.form.mobile .fields {
  width: 100%;
  height: 100%;
}
.multistepForm .contentDiv{
  width: max-content;
  margin: auto auto 16px;
}
.multistepForm .fieldWidth {
  width: 200px !important;
  margin: auto !important;
}
.multistepForm.mobile {
  height: 540px;
}
.multistepForm.mobile .rightDiv {
  height: 100%;
  width: 100%;
  padding-top: 120px;
}
.multistepForm.mobile .rightDiv .ui.form .fields {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
}
.multistepForm .inputField .incomeField {
  text-align: center;
}
.multistepForm .inputField .incomeField .nextStepIcon{
  cursor: pointer;
}


