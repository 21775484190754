/*******************************
             Header
 *******************************/

/* Standard */

.ui.header {
  border: none;
  margin: 0.5em 0;
  padding: 0em 0em;
  font-size: 1rem;
  font-family: $font-family-r, "Helvetica Neue", Arial, Helvetica, sans-serif;
  line-height: 1.28571429em;
  text-transform: none;
  color: $blueGrey;
}

.ui.header:first-child {
  margin-top: -0.14285714em;
}

.ui.header:last-child {
  margin-bottom: 0em;
}

/*--------------
   Sub Header
---------------*/

.ui.header .sub.header {
  display: block;
  font-weight: normal;
  padding: 0em;
  margin: 0.5em 0;
  font-size: 1rem;
  line-height: normal;
  color: rgba(0, 0, 0, 0.6);
}

/*--------------
      Icon
---------------*/

.ui.header > .icon {
  display: table-cell;
  opacity: 1;
  font-size: 1em;
  padding-top: 0em;
  vertical-align: middle;
}

/* With Text Node */

.ui.header .icon:only-child {
  display: inline-block;
  padding: 0em;
  margin-right: 0.15rem;
}

/*-------------------
        Image
--------------------*/

.ui.header > .image:not(.icon),
.ui.header > img {
  display: inline-block;
  margin-top: 0.14285714em;
  width: 2.5em;
  height: auto;
  vertical-align: middle;
}

.ui.header > .image:not(.icon):only-child,
.ui.header > img:only-child {
  margin-right: 0.75rem;
}

/*--------------
     Content
---------------*/

.ui.header .content {
  display: inline-block;
  vertical-align: top;
}

/* After Image */

.ui.header > img + .content,
.ui.header > .image + .content {
  padding-left: 0.75rem;
  vertical-align: middle;
}

/* After Icon */

.ui.header > .icon + .content {
  padding-left: 0.75rem;
  display: table-cell;
  vertical-align: middle;
}

/*--------------
 Loose Coupling
---------------*/

.ui.header .ui.label {
  font-size: "";
  margin-left: 0.5rem;
  vertical-align: middle;
}

/* Positioning */

.ui.header + p {
  margin-top: 0em;
}

/*******************************
            Types
*******************************/

/*--------------
     Page
---------------*/
h1.ui.header {
  font-family: $font-family-l;
  font-size: 6em;
  letter-spacing: -0.015625em;
}
h2.ui.header {
  font-family: $font-family-l;
  font-size: 3.75em;
  letter-spacing: -0.025em;
}
h3.ui.header {
  font-family: $font-family-r;
  font-size: 3em;
  letter-spacing: 0em;
}
h4.ui.header {
  font-family: $font-family-r;
  font-size: 2.125em;
  letter-spacing: 0.007352941em;
}
h5.ui.header {
  font-family: $font-family-m;
  font-size: 1.5625em;
  letter-spacing: 0em;
}
h6.ui.header {
  font-family: $font-family-m;
  font-size: 1.25em;
  letter-spacing: 0.0075em;
}
.ui.header.regular {
  font-family: $font-family-m;
}

/* Sub Header */

h1.ui.header .sub.header {
  font-size: 0.57142857em;
}

h2.ui.header .sub.header {
  font-size: 0.66666666em;
}

h3.ui.header .sub.header {
  font-size: 0.77777777em;
}

h4.ui.header .sub.header {
  font-size: 0.93333333em;
}

h5.ui.header .sub.header {
  font-size: 0.92857143em;;
}

h6.ui.header .sub.header {
  font-family: $font-family-r;
  font-size: 0.8em;
}

/*--------------
 Content Heading
---------------*/

.ui.huge.header {
  font-family: $font-family-r;
  font-size: 1em;
  letter-spacing: 0.009375em;
}

.ui.large.header {
  font-family: $font-family-m;
  font-size: $font-size-m;
  letter-spacing: .007142857em;
}

.ui.medium.header {
  font-family: $font-family-r;
  font-size: 1em;
  letter-spacing: 0.03125em;
  margin: 0.25em 0;
}

.ui.small.header {
  font-family: $font-family-r;
  font-size: 0.875em;
  letter-spacing: 0.01785714em;
  margin: 0.25em 0;
}

.ui.tiny.header {
  font-family: $font-family-r;
  font-size: 0.75em;
  letter-spacing: 0.03333333em;
  margin: 0.25em 0;
}

/* Sub Header */

.ui.huge.header .sub.header {
  font-size: 0.875em;
}

.ui.large.header .sub.header {
  font-size: 0.875em;
}

.ui.header .sub.header {
  font-size: 1rem;
}

.ui.small.header .sub.header {
  font-size: 0.875rem;
}

.ui.tiny.header .sub.header {
  font-size: 0.92857143em;
}

/*--------------
   Sub Heading
---------------*/

.ui.sub.header {
  padding: 0em;
  margin: 0.25rem 0;
  font-family: $font-family-r;
  font-size: $font-size-xs;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.ui.small.sub.header {
  font-size: 0.78571429em;
}

.ui.sub.header {
  font-size: $font-size-xs;
}

.ui.large.sub.header {
  font-size: $font-size-s;
}

.ui.huge.sub.header {
  font-size: $font-size-m;
}

/*-------------------
        Icon
--------------------*/

.ui.icon.header {
  display: inline-block;
  text-align: center;
  margin: 2rem 0em 1rem;
}

.ui.icon.header:after {
  content: "";
  display: block;
  height: 0px;
  clear: both;
  visibility: hidden;
}

.ui.icon.header:first-child {
  margin-top: 0em;
}

.ui.icon.header .icon {
  float: none;
  display: block;
  width: auto;
  height: auto;
  line-height: 1;
  padding: 0em;
  font-size: 3em;
  margin: 0em auto 0.5rem;
  opacity: 1;
}

.ui.icon.header .content {
  display: block;
  padding: 0em;
}

.ui.icon.header .circular.icon {
  font-size: 2em;
}

.ui.icon.header .square.icon {
  font-size: 2em;
}

.ui.block.icon.header .icon {
  margin-bottom: 0em;
}

.ui.icon.header.aligned {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/*******************************
            States
*******************************/

.ui.disabled.header {
  opacity: 0.45;
}

/*******************************
           Variations
*******************************/

/*-------------------
      Inverted
--------------------*/

.ui.inverted.header {
  color: $white;
}

.ui.inverted.header .sub.header {
  color: rgba(255, 255, 255, 0.8);
}

.ui.inverted.attached.header {
  background: #545454 -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.05)));
  background: #545454 -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  background: #545454 linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: transparent;
}

.ui.inverted.block.header {
  background: #545454 -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.05)));
  background: #545454 -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  background: #545454 linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui.inverted.block.header {
  border-bottom: none;
}

/*-------------------
       Colors
--------------------*/

/*--- Red ---*/

.ui.red.header {
  color: #db2828 !important;
}

a.ui.red.header:hover {
  color: #d01919 !important;
}

.ui.red.dividing.header {
  border-bottom: 2px solid #db2828;
}

/* Inverted */

.ui.inverted.red.header {
  color: #ff695e !important;
}

a.ui.inverted.red.header:hover {
  color: #ff5144 !important;
}

/*--- Orange ---*/

.ui.orange.header {
  color: #f2711c !important;
}

a.ui.orange.header:hover {
  color: #f26202 !important;
}

.ui.orange.dividing.header {
  border-bottom: 2px solid #f2711c;
}

/* Inverted */

.ui.inverted.orange.header {
  color: #ff851b !important;
}

a.ui.inverted.orange.header:hover {
  color: #ff7701 !important;
}

/*--- Olive ---*/

.ui.olive.header {
  color: #b5cc18 !important;
}

a.ui.olive.header:hover {
  color: #a7bd0d !important;
}

.ui.olive.dividing.header {
  border-bottom: 2px solid #b5cc18;
}

/* Inverted */

.ui.inverted.olive.header {
  color: #d9e778 !important;
}

a.ui.inverted.olive.header:hover {
  color: #d8ea5c !important;
}

/*--- Yellow ---*/

.ui.yellow.header {
  color: #fbbd08 !important;
}

a.ui.yellow.header:hover {
  color: #eaae00 !important;
}

.ui.yellow.dividing.header {
  border-bottom: 2px solid #fbbd08;
}

/* Inverted */

.ui.inverted.yellow.header {
  color: #ffe21f !important;
}

a.ui.inverted.yellow.header:hover {
  color: #ffdf05 !important;
}

/*--- Green ---*/

.ui.green.header {
  color: $green !important;
}

a.ui.green.header:hover {
  color: #16ab39 !important;
}

.ui.green.dividing.header {
  border-bottom: 2px solid $green;
}

/* Inverted */

.ui.inverted.green.header {
  color: #2ecc40 !important;
}

a.ui.inverted.green.header:hover {
  color: #22be34 !important;
}

/*--- Teal ---*/

.ui.teal.header {
  color: #00b5ad !important;
}

a.ui.teal.header:hover {
  color: #009c95 !important;
}

.ui.teal.dividing.header {
  border-bottom: 2px solid #00b5ad;
}

/* Inverted */

.ui.inverted.teal.header {
  color: #6dffff !important;
}

a.ui.inverted.teal.header:hover {
  color: #54ffff !important;
}

/*--- Blue ---*/

.ui.blue.header {
  color: $blue !important;
}

a.ui.blue.header:hover {
  color: #1678c2 !important;
}

.ui.blue.dividing.header {
  border-bottom: 2px solid $blue;
}

/* Inverted */

.ui.inverted.blue.header {
  color: #54c8ff !important;
}

a.ui.inverted.blue.header:hover {
  color: #3ac0ff !important;
}

/*--- Violet ---*/

.ui.violet.header {
  color: #6435c9 !important;
}

a.ui.violet.header:hover {
  color: #5829bb !important;
}

.ui.violet.dividing.header {
  border-bottom: 2px solid #6435c9;
}

/* Inverted */

.ui.inverted.violet.header {
  color: #a291fb !important;
}

a.ui.inverted.violet.header:hover {
  color: #8a73ff !important;
}

/*--- Purple ---*/

.ui.purple.header {
  color: #a333c8 !important;
}

a.ui.purple.header:hover {
  color: #9627ba !important;
}

.ui.purple.dividing.header {
  border-bottom: 2px solid #a333c8;
}

/* Inverted */

.ui.inverted.purple.header {
  color: #dc73ff !important;
}

a.ui.inverted.purple.header:hover {
  color: #d65aff !important;
}

/*--- Pink ---*/

.ui.pink.header {
  color: #e03997 !important;
}

a.ui.pink.header:hover {
  color: #e61a8d !important;
}

.ui.pink.dividing.header {
  border-bottom: 2px solid #e03997;
}

/* Inverted */

.ui.inverted.pink.header {
  color: #ff8edf !important;
}

a.ui.inverted.pink.header:hover {
  color: #ff74d8 !important;
}

/*--- Brown ---*/

.ui.brown.header {
  color: #a5673f !important;
}

a.ui.brown.header:hover {
  color: #975b33 !important;
}

.ui.brown.dividing.header {
  border-bottom: 2px solid #a5673f;
}

/* Inverted */

.ui.inverted.brown.header {
  color: #d67c1c !important;
}

a.ui.inverted.brown.header:hover {
  color: #c86f11 !important;
}

/*--- Grey ---*/

.ui.grey.header {
  color: $grey !important;
}

a.ui.grey.header:hover {
  color: #838383 !important;
}

.ui.grey.dividing.header {
  border-bottom: 2px solid $grey;
}

/* Inverted */

.ui.inverted.grey.header {
  color: #dcddde !important;
}

a.ui.inverted.grey.header:hover {
  color: #cfd0d2 !important;
}

/*-------------------
       Aligned
--------------------*/

.ui.left.aligned.header {
  text-align: left;
}

.ui.right.aligned.header {
  text-align: right;
}

.ui.centered.header,
.ui.center.aligned.header {
  text-align: center;
}

.ui.justified.header {
  text-align: justify;
}

.ui.justified.header:after {
  display: inline-block;
  content: "";
  width: 100%;
}

/*-------------------
       Floated
--------------------*/

.ui.floated.header,
.ui[class*="left floated"].header {
  float: left;
  margin-top: 0em;
  margin-right: 0.5em;
}

.ui[class*="right floated"].header {
  float: right;
  margin-top: 0em;
  margin-left: 0.5em;
}

/*-------------------
       Fitted
--------------------*/

.ui.fitted.header {
  padding: 0em;
}

/*-------------------
      Dividing
--------------------*/

.ui.dividing.header {
  padding-bottom: 0.21428571rem;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.dividing.header .sub.header {
  padding-bottom: 0.21428571rem;
}

.ui.dividing.header .icon {
  margin-bottom: 0em;
}

.ui.inverted.dividing.header {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

/*-------------------
        Block
--------------------*/

.ui.block.header {
  background: #f3f4f5;
  padding: 0.78571429rem 1rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #d4d4d5;
  border-radius: 0.28571429rem;
}

.ui.tiny.block.header {
  font-size: 0.85714286rem;
}

.ui.small.block.header {
  font-size: 0.92857143rem;
}

.ui.block.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
  font-size: 1rem;
}

.ui.large.block.header {
  font-size: 1.14285714rem;
}

.ui.huge.block.header {
  font-size: 1.42857143rem;
}

/*-------------------
       Attached
--------------------*/

.ui.attached.header {
  background: $white;
  padding: 0.78571429rem 1rem;
  margin-left: -1px;
  margin-right: -1px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #d4d4d5;
}

.ui.attached.block.header {
  background: #f3f4f5;
}

.ui.attached:not(.top):not(.bottom).header {
  margin-top: 0em;
  margin-bottom: 0em;
  border-top: none;
  border-radius: 0em;
}

.ui.top.attached.header {
  margin-bottom: 0em;
  border-radius: 0.28571429rem 0.28571429rem 0em 0em;
}

.ui.bottom.attached.header {
  margin-top: 0em;
  border-top: none;
  border-radius: 0em 0em 0.28571429rem 0.28571429rem;
}

/* Attached Sizes */

.ui.tiny.attached.header {
  font-size: 0.85714286em;
}

.ui.small.attached.header {
  font-size: 0.92857143em;
}

.ui.attached.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
  font-size: 1em;
}

.ui.large.attached.header {
  font-size: 1.14285714em;
}

.ui.huge.attached.header {
  font-size: 1.42857143em;
}