
.policySummary .ui.statistic.alignLeft > .label, .policySummary .ui.statistic.alignLeft > .value {
  text-align: left;
  padding-left: 0px;
}
.policySummary .ui.list > .item.benefitsListItem {
  display: flex;
  -webkit-box-align: center;
   -moz-align-items: center;
     -ms-flex-align: center;
        align-items: center;
}
.policySummary .benefitsListItem .riderCaption {
    padding-left: 8px;
    padding-right: 6px;
}
.policySummary .claimSettled img {
  display: inline !important;
  margin-left: 0.25em;
}