
 /*******************************
             List
 *******************************/
 
 ul.ui.list,
 ol.ui.list,
 .ui.list {
   list-style-type: none;
   margin: 1em 0em;
   padding: 0em 0em;
 }
 
 ul.ui.list:first-child,
 ol.ui.list:first-child,
 .ui.list:first-child {
   margin-top: 0em;
   padding-top: 0em;
 }
 
 ul.ui.list:last-child,
 ol.ui.list:last-child,
 .ui.list:last-child {
   margin-bottom: 0em;
   padding-bottom: 0em;
 }
 
 /*******************************
             Content
 *******************************/
 
 /* List Item */
 
 ul.ui.list li,
 ol.ui.list li,
 .ui.list > .item,
 .ui.list .list > .item {
   display: list-item;
   table-layout: fixed;
   list-style-type: none;
   list-style-position: outside;
   padding: 0.21428571em 0em;
   line-height: 1.14285714em;
 }
 
 ul.ui.list > li:first-child:after,
 ol.ui.list > li:first-child:after,
 .ui.list > .list > .item,
 .ui.list > .item:after {
   content: '';
   display: block;
   height: 0;
   clear: both;
   visibility: hidden;
 }
 
 ul.ui.list li:first-child,
 ol.ui.list li:first-child,
 .ui.list .list > .item:first-child,
 .ui.list > .item:first-child {
   padding-top: 0em;
 }
 
 ul.ui.list li:last-child,
 ol.ui.list li:last-child,
 .ui.list .list > .item:last-child,
 .ui.list > .item:last-child {
   padding-bottom: 0em;
 }
 
 /* Child List */
 
 ul.ui.list ul,
 ol.ui.list ol,
 .ui.list .list {
   clear: both;
   margin: 0em;
   padding: 0.75em 0em 0.25em 0.5em;
 }
 
 /* Child Item */
 
 ul.ui.list ul li,
 ol.ui.list ol li,
 .ui.list .list > .item {
   padding: 0.14285714em 0em;
   line-height: inherit;
 }
 
 /* Icon */
 
 .ui.list .list > .item > i.icon,
 .ui.list > .item > i.icon {
   display: table-cell;
   margin: 0em;
   padding-top: 0em;
   padding-right: 0.28571429em;
   vertical-align: top;
   -webkit-transition: color 0.1s ease;
   transition: color 0.1s ease;
 }
 
 .ui.list .list > .item > i.icon:only-child,
 .ui.list > .item > i.icon:only-child {
   display: inline-block;
   vertical-align: top;
 }
 
 /* Image */
 
 .ui.list .list > .item > .image,
 .ui.list > .item > .image {
   display: table-cell;
   background-color: transparent;
   margin: 0em;
   vertical-align: top;
 }
 
 .ui.list .list > .item > .image:not(:only-child):not(img),
 .ui.list > .item > .image:not(:only-child):not(img) {
   padding-right: 0.5em;
 }
 
 .ui.list .list > .item > .image img,
 .ui.list > .item > .image img {
   vertical-align: top;
 }
 
 .ui.list .list > .item > img.image,
 .ui.list .list > .item > .image:only-child,
 .ui.list > .item > img.image,
 .ui.list > .item > .image:only-child {
   display: inline-block;
 }
 
 /* Content */
 
 .ui.list .list > .item > .content,
 .ui.list > .item > .content {
   line-height: 1.14285714em;
 }
 
 .ui.list .list > .item > .image + .content,
 .ui.list .list > .item > .icon + .content,
 .ui.list > .item > .image + .content,
 .ui.list > .item > .icon + .content {
   display: table-cell;
   width: 100%;
   padding: 0em 0em 0em 0.5em;
   vertical-align: top;
 }
 
 .ui.list .list > .item > img.image + .content,
 .ui.list > .item > img.image + .content {
   display: inline-block;
   width: auto;
 }
 
 .ui.list .list > .item > .content > .list,
 .ui.list > .item > .content > .list {
   margin-left: 0em;
   padding-left: 0em;
 }
 
 /* Header */
 
 .ui.list .list > .item .header,
 .ui.list > .item .header {
   display: block;
   margin: 0em;
   font-family: $font-family, 'Helvetica Neue', Arial, Helvetica, sans-serif;
   font-weight: bold;
   color: $textColor;
 }
 
 /* Description */
 
 .ui.list .list > .item .description,
 .ui.list > .item .description {
   display: block;
   color: rgba(0, 0, 0, 0.7);
 }
 
 /* Child Link */
 
 .ui.list > .item a,
 .ui.list .list > .item a {
   cursor: pointer;
 }
 
 /* Linking Item */
 
 .ui.list .list > a.item,
 .ui.list > a.item {
   cursor: pointer;
   color: #4183C4;
 }
 
 .ui.list .list > a.item:hover,
 .ui.list > a.item:hover {
   color: #1e70bf;
 }
 
 /* Linked Item Icons */
 
 .ui.list .list > a.item i.icon,
 .ui.list > a.item i.icon {
   color: rgba(0, 0, 0, 0.4);
 }
 
 /* Header Link */
 
 .ui.list .list > .item a.header,
 .ui.list > .item a.header {
   cursor: pointer;
   color: #4183C4;
 }
 
 .ui.list .list > .item a.header:hover,
 .ui.list > .item a.header:hover {
   color: #1e70bf !important;
 }
 
 /* Floated Content */
 
 .ui[class*="left floated"].list {
   float: left;
 }
 
 .ui[class*="right floated"].list {
   float: right;
 }
 
 .ui.list .list > .item [class*="left floated"],
 .ui.list > .item [class*="left floated"] {
   float: left;
   margin: 0em 1em 0em 0em;
 }
 
 .ui.list .list > .item [class*="right floated"],
 .ui.list > .item [class*="right floated"] {
   float: right;
   margin: 0em 0em 0em 1em;
 }
 
 /*******************************
             Coupling
 *******************************/
 
 .ui.menu .ui.list > .item,
 .ui.menu .ui.list .list > .item {
   display: list-item;
   table-layout: fixed;
   background-color: transparent;
   list-style-type: none;
   list-style-position: outside;
   padding: 0.21428571em 0em;
   line-height: 1.14285714em;
 }
 
 .ui.menu .ui.list .list > .item:before,
 .ui.menu .ui.list > .item:before {
   border: none;
   background: none;
 }
 
 .ui.menu .ui.list .list > .item:first-child,
 .ui.menu .ui.list > .item:first-child {
   padding-top: 0em;
 }
 
 .ui.menu .ui.list .list > .item:last-child,
 .ui.menu .ui.list > .item:last-child {
   padding-bottom: 0em;
 }
 
 /*******************************
             Types
 *******************************/
 
 /*-------------------
       Horizontal
 --------------------*/
 
 .ui.horizontal.list {
   display: inline-block;
   font-size: 0em;
 }
 
 .ui.horizontal.list > .item {
   display: inline-block;
   margin-left: 1em;
   font-size: 1rem;
 }
 
 .ui.horizontal.list:not(.celled) > .item:first-child {
   margin-left: 0em !important;
   padding-left: 0em !important;
 }
 
 .ui.horizontal.list .list {
   padding-left: 0em;
   padding-bottom: 0em;
 }
 
 .ui.horizontal.list > .item > .image,
 .ui.horizontal.list .list > .item > .image,
 .ui.horizontal.list > .item > .icon,
 .ui.horizontal.list .list > .item > .icon,
 .ui.horizontal.list > .item > .content,
 .ui.horizontal.list .list > .item > .content {
   vertical-align: middle;
 }
 
 /* Padding on all elements */
 
 .ui.horizontal.list > .item:first-child,
 .ui.horizontal.list > .item:last-child {
   padding-top: 0.21428571em;
   padding-bottom: 0.21428571em;
 }
 
 /* Horizontal List */
 
 .ui.horizontal.list > .item > i.icon {
   margin: 0em;
   padding: 0em 0.25em 0em 0em;
 }
 
 .ui.horizontal.list > .item > .icon,
 .ui.horizontal.list > .item > .icon + .content {
   float: none;
   display: inline-block;
 }
 
 /*******************************
              States
 *******************************/
 
 /*-------------------
        Disabled
 --------------------*/
 
 .ui.list .list > .disabled.item,
 .ui.list > .disabled.item {
   pointer-events: none;
   color: rgba(40, 40, 40, 0.3) !important;
 }
 
 .ui.inverted.list .list > .disabled.item,
 .ui.inverted.list > .disabled.item {
   color: rgba(225, 225, 225, 0.3) !important;
 }
 
 /*-------------------
         Hover
 --------------------*/
 
 .ui.list .list > a.item:hover .icon,
 .ui.list > a.item:hover .icon {
   color: $textColor;
 }
 
 /*******************************
            Variations
 *******************************/
 
 /*-------------------
        Inverted
 --------------------*/
 
 .ui.inverted.list .list > a.item > .icon,
 .ui.inverted.list > a.item > .icon {
   color: rgba(255, 255, 255, 0.7);
 }
 
 .ui.inverted.list .list > .item .header,
 .ui.inverted.list > .item .header {
   color: rgba(255, 255, 255, 0.9);
 }
 
 .ui.inverted.list .list > .item .description,
 .ui.inverted.list > .item .description {
   color: rgba(255, 255, 255, 0.7);
 }
 
 /* Item Link */
 
 .ui.inverted.list .list > a.item,
 .ui.inverted.list > a.item {
   cursor: pointer;
   color: rgba(255, 255, 255, 0.9);
 }
 
 .ui.inverted.list .list > a.item:hover,
 .ui.inverted.list > a.item:hover {
   color: #1e70bf;
 }
 
 /* Linking Content */
 
 .ui.inverted.list .item a:not(.ui) {
   color: rgba(255, 255, 255, 0.9) !important;
 }
 
 .ui.inverted.list .item a:not(.ui):hover {
   color: #1e70bf !important;
 }
 
 /*-------------------
        Aligned
 --------------------*/
 
 .ui.list[class*="top aligned"] .image,
 .ui.list[class*="top aligned"] .content,
 .ui.list [class*="top aligned"] {
   vertical-align: top !important;
 }
 
 .ui.list[class*="middle aligned"] .image,
 .ui.list[class*="middle aligned"] .content,
 .ui.list [class*="middle aligned"] {
   vertical-align: middle !important;
 }
 
 .ui.list[class*="bottom aligned"] .image,
 .ui.list[class*="bottom aligned"] .content,
 .ui.list [class*="bottom aligned"] {
   vertical-align: bottom !important;
 }
 
 /*-------------------
        Link
 --------------------*/
 
 .ui.link.list .item,
 .ui.link.list a.item,
 .ui.link.list .item a:not(.ui) {
   color: rgba(0, 0, 0, 0.4);
   -webkit-transition: 0.1s color ease;
   transition: 0.1s color ease;
 }
 
 .ui.link.list.list a.item:hover,
 .ui.link.list.list .item a:not(.ui):hover {
   color: rgba(0, 0, 0, 0.8);
 }
 
 .ui.link.list.list a.item:active,
 .ui.link.list.list .item a:not(.ui):active {
   color: rgba(0, 0, 0, 0.9);
 }
 
 .ui.link.list.list .active.item,
 .ui.link.list.list .active.item a:not(.ui) {
   color: rgba(0, 0, 0, 0.95);
 }
 
 /* Inverted */
 
 .ui.inverted.link.list .item,
 .ui.inverted.link.list a.item,
 .ui.inverted.link.list .item a:not(.ui) {
   color: rgba(255, 255, 255, 0.5);
 }
 
 .ui.inverted.link.list.list a.item:hover,
 .ui.inverted.link.list.list .item a:not(.ui):hover {
   color: $white;
 }
 
 .ui.inverted.link.list.list a.item:active,
 .ui.inverted.link.list.list .item a:not(.ui):active {
   color: $white;
 }
 
 .ui.inverted.link.list.list a.active.item,
 .ui.inverted.link.list.list .active.item a:not(.ui) {
   color: $white;
 }
 
 /*-------------------
       Selection
 --------------------*/
 
 .ui.selection.list .list > .item,
 .ui.selection.list > .item {
   cursor: pointer;
   background: transparent;
   padding: 0.5em 0.5em;
   margin: 0em;
   color: rgba(0, 0, 0, 0.4);
   border-radius: 0.5em;
   -webkit-transition: 0.1s color ease, 0.1s padding-left ease, 0.1s background-color ease;
   transition: 0.1s color ease, 0.1s padding-left ease, 0.1s background-color ease;
 }
 
 .ui.selection.list .list > .item:last-child,
 .ui.selection.list > .item:last-child {
   margin-bottom: 0em;
 }
 
 .ui.selection.list.list > .item:hover,
 .ui.selection.list > .item:hover {
   background: rgba(0, 0, 0, 0.03);
   color: rgba(0, 0, 0, 0.8);
 }
 
 .ui.selection.list .list > .item:active,
 .ui.selection.list > .item:active {
   background: rgba(0, 0, 0, 0.05);
   color: rgba(0, 0, 0, 0.9);
 }
 
 .ui.selection.list .list > .item.active,
 .ui.selection.list > .item.active {
   background: rgba(0, 0, 0, 0.05);
   color: rgba(0, 0, 0, 0.95);
 }
 
 /* Inverted */
 
 .ui.inverted.selection.list > .item,
 .ui.inverted.selection.list > .item {
   background: transparent;
   color: rgba(255, 255, 255, 0.5);
 }
 
 .ui.inverted.selection.list > .item:hover,
 .ui.inverted.selection.list > .item:hover {
   background: rgba(255, 255, 255, 0.02);
   color: $white;
 }
 
 .ui.inverted.selection.list > .item:active,
 .ui.inverted.selection.list > .item:active {
   background: rgba(255, 255, 255, 0.08);
   color: $white;
 }
 
 .ui.inverted.selection.list > .item.active,
 .ui.inverted.selection.list > .item.active {
   background: rgba(255, 255, 255, 0.08);
   color: $white;
 }
 
 /* Celled / Divided Selection List */
 
 .ui.celled.selection.list .list > .item,
 .ui.divided.selection.list .list > .item,
 .ui.celled.selection.list > .item,
 .ui.divided.selection.list > .item {
   border-radius: 0em;
 }
 
 /*-------------------
        Animated
 --------------------*/
 
 .ui.animated.list > .item {
   -webkit-transition: 0.25s color ease 0.1s, 0.25s padding-left ease 0.1s, 0.25s background-color ease 0.1s;
   transition: 0.25s color ease 0.1s, 0.25s padding-left ease 0.1s, 0.25s background-color ease 0.1s;
 }
 
 .ui.animated.list:not(.horizontal) > .item:hover {
   padding-left: 1em;
 }
 
 /*-------------------
        Fitted
 --------------------*/
 
 .ui.fitted.list:not(.selection) .list > .item,
 .ui.fitted.list:not(.selection) > .item {
   padding-left: 0em;
   padding-right: 0em;
 }
 
 .ui.fitted.selection.list .list > .item,
 .ui.fitted.selection.list > .item {
   margin-left: -0.5em;
   margin-right: -0.5em;
 }
 
 /*-------------------
       Bulleted
 --------------------*/
 
 ul.ui.list,
 .ui.bulleted.list {
   margin-left: 1.25rem;
 }
 
 ul.ui.list li,
 .ui.bulleted.list .list > .item,
 .ui.bulleted.list > .item {
   position: relative;
 }
 
 ul.ui.list li:before,
 .ui.bulleted.list .list > .item:before,
 .ui.bulleted.list > .item:before {
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   pointer-events: none;
   position: absolute;
   top: auto;
   left: auto;
   font-weight: normal;
   margin-left: -1.25rem;
   content: '•';
   opacity: 1;
   color: inherit;
   vertical-align: top;
 }
 
 ul.ui.list li:before,
 .ui.bulleted.list .list > a.item:before,
 .ui.bulleted.list > a.item:before {
   color: $textColor;
 }
 
 ul.ui.list ul,
 .ui.bulleted.list .list {
   padding-left: 1.25rem;
 }
 
 /* Horizontal Bulleted */
 
 ul.ui.horizontal.bulleted.list,
 .ui.horizontal.bulleted.list {
   margin-left: 0em;
 }
 
 ul.ui.horizontal.bulleted.list li,
 .ui.horizontal.bulleted.list > .item {
   margin-left: 1.75rem;
 }
 
 ul.ui.horizontal.bulleted.list li:first-child,
 .ui.horizontal.bulleted.list > .item:first-child {
   margin-left: 0em;
 }
 
 ul.ui.horizontal.bulleted.list li::before,
 .ui.horizontal.bulleted.list > .item::before {
   color: $textColor;
 }
 
 ul.ui.horizontal.bulleted.list li:first-child::before,
 .ui.horizontal.bulleted.list > .item:first-child::before {
   display: none;
 }
 
 /*-------------------
        Ordered
 --------------------*/
 
 ol.ui.list,
 .ui.ordered.list,
 .ui.ordered.list .list,
 ol.ui.list ol {
   counter-reset: ordered;
   margin-left: 1.25rem;
   list-style-type: none;
 }
 
 ol.ui.list li,
 .ui.ordered.list .list > .item,
 .ui.ordered.list > .item {
   list-style-type: none;
   position: relative;
 }
 
 ol.ui.list li:before,
 .ui.ordered.list .list > .item:before,
 .ui.ordered.list > .item:before {
   position: absolute;
   top: auto;
   left: auto;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   pointer-events: none;
   margin-left: -1.25rem;
   counter-increment: ordered;
   content: counters(ordered, ".") " ";
   text-align: right;
   color: $textColor;
   vertical-align: middle;
   opacity: 0.8;
 }
 
 ol.ui.inverted.list li:before,
 .ui.ordered.inverted.list .list > .item:before,
 .ui.ordered.inverted.list > .item:before {
   color: rgba(255, 255, 255, 0.7);
 }
 
 /* Value */
 
 .ui.ordered.list > .list > .item[data-value],
 .ui.ordered.list > .item[data-value] {
   content: attr(data-value);
 }
 
 ol.ui.list li[value]:before {
   content: attr(value);
 }
 
 /* Child Lists */
 
 ol.ui.list ol,
 .ui.ordered.list .list {
   margin-left: 1em;
 }
 
 ol.ui.list ol li:before,
 .ui.ordered.list .list > .item:before {
   margin-left: -2em;
 }
 
 /* Horizontal Ordered */
 
 ol.ui.horizontal.list,
 .ui.ordered.horizontal.list {
   margin-left: 0em;
 }
 
 ol.ui.horizontal.list li:before,
 .ui.ordered.horizontal.list .list > .item:before,
 .ui.ordered.horizontal.list > .item:before {
   position: static;
   margin: 0em 0.5em 0em 0em;
 }
 
 /*-------------------
        Divided
 --------------------*/
 
 .ui.divided.list > .item {
   border-top: 1px solid rgba(34, 36, 38, 0.15);
 }
 
 .ui.divided.list .list > .item {
   border-top: none;
 }
 
 .ui.divided.list .item .list > .item {
   border-top: none;
 }
 
 .ui.divided.list .list > .item:first-child,
 .ui.divided.list > .item:first-child {
   border-top: none;
 }
 
 /* Sub Menu */
 
 .ui.divided.list:not(.horizontal) .list > .item:first-child {
   border-top-width: 1px;
 }
 
 /* Divided bulleted */
 
 .ui.divided.bulleted.list:not(.horizontal),
 .ui.divided.bulleted.list .list {
   margin-left: 0em;
   padding-left: 0em;
 }
 
 .ui.divided.bulleted.list > .item:not(.horizontal) {
   padding-left: 1.25rem;
 }
 
 /* Divided Ordered */
 
 .ui.divided.ordered.list {
   margin-left: 0em;
 }
 
 .ui.divided.ordered.list .list > .item,
 .ui.divided.ordered.list > .item {
   padding-left: 1.25rem;
 }
 
 .ui.divided.ordered.list .item .list {
   margin-left: 0em;
   margin-right: 0em;
   padding-bottom: 0.21428571em;
 }
 
 .ui.divided.ordered.list .item .list > .item {
   padding-left: 1em;
 }
 
 /* Divided Selection */
 
 .ui.divided.selection.list .list > .item,
 .ui.divided.selection.list > .item {
   margin: 0em;
   border-radius: 0em;
 }
 
 /* Divided horizontal */
 
 .ui.divided.horizontal.list {
   margin-left: 0em;
 }
 
 .ui.divided.horizontal.list > .item:not(:first-child) {
   padding-left: 0.5em;
 }
 
 .ui.divided.horizontal.list > .item:not(:last-child) {
   padding-right: 0.5em;
 }
 
 .ui.divided.horizontal.list > .item {
   border-top: none;
   border-left: 1px solid rgba(34, 36, 38, 0.15);
   margin: 0em;
   line-height: 0.6;
 }
 
 .ui.horizontal.divided.list > .item:first-child {
   border-left: none;
 }
 
 /* Inverted */
 
 .ui.divided.inverted.list > .item,
 .ui.divided.inverted.list > .list,
 .ui.divided.inverted.horizontal.list > .item {
   border-color: rgba(255, 255, 255, 0.1);
 }
 
 /*-------------------
         Celled
 --------------------*/
 
 .ui.celled.list > .item,
 .ui.celled.list > .list {
   border-top: 1px solid rgba(34, 36, 38, 0.15);
   padding-left: 0.5em;
   padding-right: 0.5em;
 }
 
 .ui.celled.list > .item:last-child {
   border-bottom: 1px solid rgba(34, 36, 38, 0.15);
 }
 
 /* Padding on all elements */
 
 .ui.celled.list > .item:first-child,
 .ui.celled.list > .item:last-child {
   padding-top: 0.21428571em;
   padding-bottom: 0.21428571em;
 }
 
 /* Sub Menu */
 
 .ui.celled.list .item .list > .item {
   border-width: 0px;
 }
 
 .ui.celled.list .list > .item:first-child {
   border-top-width: 0px;
 }
 
 /* Celled Bulleted */
 
 .ui.celled.bulleted.list {
   margin-left: 0em;
 }
 
 .ui.celled.bulleted.list .list > .item,
 .ui.celled.bulleted.list > .item {
   padding-left: 1.25rem;
 }
 
 .ui.celled.bulleted.list .item .list {
   margin-left: -1.25rem;
   margin-right: -1.25rem;
   padding-bottom: 0.21428571em;
 }
 
 /* Celled Ordered */
 
 .ui.celled.ordered.list {
   margin-left: 0em;
 }
 
 .ui.celled.ordered.list .list > .item,
 .ui.celled.ordered.list > .item {
   padding-left: 1.25rem;
 }
 
 .ui.celled.ordered.list .item .list {
   margin-left: 0em;
   margin-right: 0em;
   padding-bottom: 0.21428571em;
 }
 
 .ui.celled.ordered.list .list > .item {
   padding-left: 1em;
 }
 
 /* Celled Horizontal */
 
 .ui.horizontal.celled.list {
   margin-left: 0em;
 }
 
 .ui.horizontal.celled.list .list > .item,
 .ui.horizontal.celled.list > .item {
   border-top: none;
   border-left: 1px solid rgba(34, 36, 38, 0.15);
   margin: 0em;
   padding-left: 0.5em;
   padding-right: 0.5em;
   line-height: 0.6;
 }
 
 .ui.horizontal.celled.list .list > .item:last-child,
 .ui.horizontal.celled.list > .item:last-child {
   border-bottom: none;
   border-right: 1px solid rgba(34, 36, 38, 0.15);
 }
 
 /* Inverted */
 
 .ui.celled.inverted.list > .item,
 .ui.celled.inverted.list > .list {
   border-color: 1px solid rgba(255, 255, 255, 0.1);
 }
 
 .ui.celled.inverted.horizontal.list .list > .item,
 .ui.celled.inverted.horizontal.list > .item {
   border-color: 1px solid rgba(255, 255, 255, 0.1);
 }
 
 /*-------------------
        Relaxed
 --------------------*/
 
 .ui.relaxed.list:not(.horizontal) > .item:not(:first-child) {
   padding-top: 0.42857143em;
 }
 
 .ui.relaxed.list:not(.horizontal) > .item:not(:last-child) {
   padding-bottom: 0.42857143em;
 }
 
 .ui.horizontal.relaxed.list .list > .item:not(:first-child),
 .ui.horizontal.relaxed.list > .item:not(:first-child) {
   padding-left: 1rem;
 }
 
 .ui.horizontal.relaxed.list .list > .item:not(:last-child),
 .ui.horizontal.relaxed.list > .item:not(:last-child) {
   padding-right: 1rem;
 }
 
 /* Very Relaxed */
 
 .ui[class*="very relaxed"].list:not(.horizontal) > .item:not(:first-child) {
   padding-top: 0.85714286em;
 }
 
 .ui[class*="very relaxed"].list:not(.horizontal) > .item:not(:last-child) {
   padding-bottom: 0.85714286em;
 }
 
 .ui.horizontal[class*="very relaxed"].list .list > .item:not(:first-child),
 .ui.horizontal[class*="very relaxed"].list > .item:not(:first-child) {
   padding-left: 1.5rem;
 }
 
 .ui.horizontal[class*="very relaxed"].list .list > .item:not(:last-child),
 .ui.horizontal[class*="very relaxed"].list > .item:not(:last-child) {
   padding-right: 1.5rem;
 }
 
 /*-------------------
       Sizes
 --------------------*/
 
 .ui.mini.list {
   font-size: 0.78571429em;
 }
 
 .ui.tiny.list {
   font-size: 0.85714286em;
 }
 
 .ui.small.list {
   font-size: 0.92857143em;
 }
 
 .ui.list {
   font-size: 1em;
 }
 
 .ui.large.list {
   font-size: 1.14285714em;
 }
 
 .ui.big.list {
   font-size: 1.28571429em;
 }
 
 .ui.huge.list {
   font-size: 1.42857143em;
 }
 
 .ui.massive.list {
   font-size: 1.71428571em;
 }
 
 .ui.mini.horizontal.list .list > .item,
 .ui.mini.horizontal.list > .item {
   font-size: 0.78571429rem;
 }
 
 .ui.tiny.horizontal.list .list > .item,
 .ui.tiny.horizontal.list > .item {
   font-size: 0.85714286rem;
 }
 
 .ui.small.horizontal.list .list > .item,
 .ui.small.horizontal.list > .item {
   font-size: 0.92857143rem;
 }
 
 .ui.horizontal.list .list > .item,
 .ui.horizontal.list > .item {
   font-size: 1rem;
 }
 
 .ui.large.horizontal.list .list > .item,
 .ui.large.horizontal.list > .item {
   font-size: 1.14285714rem;
 }
 
 .ui.big.horizontal.list .list > .item,
 .ui.big.horizontal.list > .item {
   font-size: 1.28571429rem;
 }
 
 .ui.huge.horizontal.list .list > .item,
 .ui.huge.horizontal.list > .item {
   font-size: 1.42857143rem;
 }
 
 .ui.massive.horizontal.list .list > .item,
 .ui.massive.horizontal.list > .item {
   font-size: 1.71428571rem;
 }