@media only screen and (min-width: 320px) and (max-width: 767px) {
    .MuiListItemText-root {
        .MuiTypography-body1 {
            font-size: 0.75rem !important;
        }
    }

    .MuiList-root,
    .MuiList-padding {
        .MuiListItem-gutters {
            padding-left: 0.5rem !important;
        }
    }
}
