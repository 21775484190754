@import "assets/styles/variables.scss";

.inspectionSummary {
  .heading {
    color: rgb($secondary);
  }
  .description-card {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 25px;
    .description-message {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;
      margin: 0;
      .alert-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5em;
        border-radius: 50%;
        box-shadow: 0px 0px 0px 1px rgba(2, 112, 246, 0.4) inset !important;
        border: 1px solid rgba(2, 112, 246, 0.4) !important;
        background-color: rgba(2, 112, 246, 0.2) !important;
        i {
          color: rgb($secondary) !important;
          font-size: 14px;
        }
      }
      .ui.small.header {
        margin: 0;
      }
    }

    .button-container {
      display: flex;
      gap: 10px;
      margin: 0;
      button {
        text-transform: uppercase !important;
        margin: 0 !important;
      }
      & > button:last-child {
        box-shadow: 0px 0px 0px 1px rgb($secondary) inset, 0px 2px 3px 0px rgba(0, 0, 0, 0.1) !important;
        color: rgb($secondary) !important;
      }
    }
  }
}

@media screen and (max-width: 510px) {
  .inspectionSummary {
    .description-card {
      align-items: center;
      gap: 20px;
      .description-message {
        text-align: justify;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        .alert-icon {
          padding: 1em;
          i {
            font-size: 32px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .inspectionSummary {
    .description-card {
      .description-message {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        .alert-icon {
          padding: 0.8em;
          i {
            font-size: 24px;
          }
        }
      }
      .button-container {
        button {
          padding: 0.7em 0.6em;
        }
      }
    }
  }
}
