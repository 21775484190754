@import "assets/styles/variables.scss";
.group-clear {
	clear: both;
	*zoom: 1;
	&:after {
		clear: both;
	}
}
.inputElement.filter {
	max-width: 10rem;
}
.flexCol {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100%;
	justify-content: center;
}

.filterContentMobile {
	padding-bottom: 2rem !important;

	.item {
		padding: 1rem !important;
	}
}
.updateIcon {
	cursor: pointer;
}
.personalDetails,
.healthPersonalDetails {
	flex-direction: column;
	align-items: start !important;
	.content.edit {
		cursor: pointer;
	}
}

.annuityPersonalDetails {
	flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-webkit-flex-direction: column;
	align-items: start !important;
	padding: 0.5rem 1rem 0 1rem !important;
	.content.edit {
		cursor: pointer;
	}
}

.annuityPlanType {
	flex: 1;
	width: 100%;
	.radioButtonsFiltermenu {
		margin-bottom: 0;
		gap: 0.5rem;
		display: flex;
		flex-direction: column;

		.field {
			display: flex;
			margin-right: 0;
			flex-wrap: wrap;
		}
	}
}
@media screen and (max-width: 1270px) {
	.annuityPayoutFilter {
		width: 10rem;
	}
}
@media screen and (max-width: 1155px) {
	.annuityPersonalDetails {
		font-size: 0.85rem;
	}
	.annuityPayoutFilter {
		width: 9.5rem;
	}

	.annuityPlanType {
		width: 12rem;
		label {
			font-size: 0.8rem !important;
		}
	}
}

@media screen and (max-width: 992px) {
	.purchasePricePriceAnnuityPayout {
		.flexContainer {
			gap: 0 !important;
		}
		input,
		.dropdown.label {
			padding: 0.5rem 0.25rem !important;
		}
	}
}
@media only screen and (min-width: 812px) and (max-width: 992px) {
	.healthPersonalDetails {
		width: 10rem;
		font-size: 0.85rem;
	}
	.personalDetails {
		width: 9rem;
		font-size: 0.85rem;
	}
	.annuityPersonalDetails {
		width: 9rem;
		font-size: 0.85rem;
	}
}

@media all and (min-width: 768px) and (max-width: 811px) {
	.personalDetails {
		width: 8rem;
		font-size: 0.8rem;
	}
	.healthPersonalDetails {
		width: 9rem;
		font-size: 0.8rem;
	}
}
// .filterMenuContainer .filterTop .ui.menu {
// 	-webkit-box-shadow: 0px 2px 4px #8c8c8c;
// 	-moz-box-shadow: 0px 2px 4px #8c8c8c;
// 	box-shadow: 0px 2px 4px #8c8c8c;
// }
.filterMenuContainer {
	border-radius: 4px;
	.item {
		align-items: flex-start !important;
	}
	.selected {
		color: rgb($secondary);
		font-weight: 600;
	}
	.mobile-selected {
		color: $white;
		background-color: $green;
		font-weight: 600;
	}
	.paymentFrequencySeperator {
		margin-left: 0.25rem;
		margin-right: 0.25rem;
	}
	.ui.menu {
		align-items: baseline;
	}
}
.editMembersDetails {
	color: rgb($secondary);
	cursor: pointer;
	margin-bottom: 0.5rem;
	font-family: RobotoMedium;
	font-size: 13px;
}

.editPersonalDetails {
	color: rgb($secondary);
	font-weight: 600;
	cursor: pointer;
	margin-left: 0.5rem;
}
.editPersonalDetailsAnnuity {
	color: rgb($secondary);
	font-weight: 600;
	cursor: pointer;
	margin-left: 1.1rem;
}
// .filterTop {
// 	background-color: $white;
// 	width: 100%;
// 	.ui {
// 		&.dropdown {
// 			margin: auto;
// 			&:not(.button) {
// 				& > .default {
// 					&.text {
// 						font-family: RobotoMedium;
// 						font-size: 0.625rem;
// 						letter-spacing: 0.15rem;
// 						text-transform: uppercase;
// 					}
// 				}
// 			}
// 		}
// 		&.menu {
// 			-webkit-border-radius: 0px;
// 			-moz-border-radius: 0px;
// 			border-radius: 0px;
// 			border: unset;
// 			-webkit-box-shadow: unset;
// 			-moz-box-shadow: unset;
// 			box-shadow: unset;
// 			max-height: 6rem !important;
// 			.item {
// 				&.removeAll {
// 					-webkit-box-align: baseline;
// 					-moz-align-items: baseline;
// 					-ms-flex-align: baseline;
// 					align-items: baseline;
// 					margin-left: auto;
// 					padding-right: 1.5rem;
// 				}
// 			}
// 		}
// 	}
// 	.dropDownContainer {
// 		-moz-flex-direction: column;
// 		-webkit-flex-direction: column;
// 		flex-direction: column;
// 		padding: 1.75rem 0.75rem 0rem 0.75rem;
// 		.labelHolder {
// 			flex: 2;
// 		}
// 		.ui {
// 			&.dropdown {
// 				flex: 3;
// 				padding: 0rem;
// 			}
// 		}
// 	}
// 	.changeLabel {
// 		cursor: pointer;
// 		&:hover {
// 			-webkit-box-shadow: $buttonBoxShadow;
// 			-moz-box-shadow: $buttonBoxShadow;
// 			box-shadow: $buttonBoxShadow;
// 		}
// 	}
// }
.verticalFirstItem {
	padding-top: 0.92857143em;
}

#removeFilters {
	cursor: pointer;
	padding-top: 1rem;
}
.mainDisplay {
	& > div {
		& > div {
			&:nth-child(2) {
				position: relative;
			}
		}
	}
}
.pushable {
	height: 100vh;
}
.filterMobile {
	height: 100vh;

	.ui.overlay {
		height: 60% !important;
		box-shadow: unset !important;
	}
	.mobileFilterCT {
		margin-right: 1rem;
	}
	.filterBlock {
		margin-left: auto;
	}
	.filterHeader {
		background-color: rgb($primaryLight);
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		padding: 0.75em 1em;
		// z-index: 10;
		// height: 5em;
		.doneText {
			margin-left: auto;
		}
		.header {
			&.filterText {
				margin: 0em;
			}
		}
		.filterBlock {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-evenly;
			.filterIcon {
				margin-top: 0.5em;
				width: 14px;
			}
		}
	}
	.ui {
		&.vertical {
			&.menu {
				border: none;
				.item {
					padding: 1rem;
					.header {
						text-align: left;
					}
				}
			}
		}
	}
	.sectionTitle {
		padding: 2rem 2rem 0rem 2rem;
	}
	.checkboxItem {
		.checkbox {
			margin-left: auto;
		}
		.header {
			margin-bottom: 1rem;
		}
	}
}
.filterMobile .filterHeader .filterBack,
.filterMobile .filterHeader .filterClose {
	width: 2rem;
	height: 2rem;
	cursor: pointer;
	img {
		width: 10px;
	}
}
.productsHolder {
	height: calc(100vh - #{$headerHeight} * 2);
}
// .filterHeader {
// 	&.overlay {
// 		position: fixed;
// 		top: 0px;
// 		left: 0px;
// 		width: 100%;
// 	}
// }
// .MobileFilterMenu, .termMobileFilterMenu, .annuityMobileFilterMenu {
// 	padding-top: 8em;
// 	padding-bottom: 4rem;
// }
// .carMobileFilterMenu, .bikeMobileFilterMenu {
// 	padding-top: 5em;
// 	padding-bottom: 4rem;

// 	.item {
// 		padding-left: 1rem !important;
// 		padding-bottom: 0 !important;
// 	}
// }

.editUserDetailsModal {
	width: 21.875rem;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
}

.editMemberDetailModal {
	max-width: 92%;
	min-width: 85%;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
}

// Hardocded padding for car insurance filters
// .filterTop .ui.menu #policyTerm,
// .filterTop .ui.menu #idv,
// .filterTop .ui.menu #policyType {
// 	padding-top: 0.5rem;
// 	padding-bottom: 0.5rem;
// }

@media only screen and (max-width: 1220px) {
	.filterMenuContainer {
		border-radius: 4px;
		.selected {
			color: rgb($secondary);
			font-weight: 600;
		}
		.paymentFrequencySeperator {
			margin-left: 0.25rem;
			margin-right: 0.25rem;
		}
		// .filterTop {
		// 	.inputFieldAnnuity {
		// 		width: 20rem;
		// 	}
		// }
	}
}
