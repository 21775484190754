@import 'assets/styles/variables.scss';

 .ui.form.jsonSchemaForm .field.secondary label {
  font-size: 0.90em;
  color: $blueGrey !important;
  display: block;
}
.ui.form.jsonSchemaForm input#panWidget {
  color: $textColor !important;
}
// To add space between each inputs inline
.ui.jsonSchemaForm.single-step-form, #nomineeModal {
  .ui.input, .ui.search.dropdown {
    margin-right: 0.5rem;
  }
}

.ui.form.jsonSchemaForm .clickableLink {
  color: rgb($secondary);
  text-decoration: underline;
  cursor: pointer;
}
.ui.form.jsonSchemaForm .verifyLink {
  cursor: pointer;
}
.ui.form.jsonSchemaForm label.secondary + .ui.search.dropdown {
  display: block;
}
.ui.form.jsonSchemaForm .ui.grid > .row.secondaryrows {
  padding: 0.5em;
}
// .ui.form.jsonSchemaForm .name-field .ui.input input {
//   padding: 0.5em 0em 0.5em 1em;
// }
// .ui.form.jsonSchemaForm .name-field .ui.dropdown {
//   padding: 0.5em 0em 0.5em 1em;
//   padding-bottom: calc(0.5rem - 1px);
// }
// .ui.form.jsonSchemaForm .name-field:focus-within .ui.input input, .ui.form.jsonSchemaForm .name-field:focus-within .ui.dropdown input {
//   border-color: rgb($secondaryLight);
// }
.ui.form.jsonSchemaForm .name-field {
  white-space: nowrap;
}
.ui.form.jsonSchemaForm .formButtons {
  position: absolute;
  bottom: 0px;
}
.ui.form.jsonSchemaForm {
  /* min-height: 50vh; */
}
.ui.form.jsonSchemaForm .rootField {
  padding-bottom: 80px;
}
.ui.form.jsonSchemaForm .subQuestion.ui.grid {
  margin: 0;
}
.ui.form.jsonSchemaForm .subQuestion.ui.grid > .row {
  padding: 0;
}
.ui.form.jsonSchemaForm .subQuestion.ui.grid .subQuestionField {
  margin-top: 8px;
  width: 60%;
}
.ui.form.jsonSchemaForm .subQuestion.ui.grid .subQuestionField.field2 {
  width: 120px;
  margin-top: -7px;
}
.ui.form.jsonSchemaForm .subQuestion.ui.grid .subQuestionLabel2 {
  display: inherit;
}
.ui.form.jsonSchemaForm .errorHolder {
  display: flex;
  align-items: center;
  font-size: 10px;
  letter-spacing: 0.75px;
  gap: 0.25rem;
  margin: 0;
  
  img.ui.image { 
    margin-top: 0;
    margin-right: 0;
  }
}


.otpWidgetHolder {
  margin: 2rem 0rem;
}

.ui.card.reviewCard {
  -webkit-box-shadow: 0 5px 15px 0 rgba(74, 84, 158, 0.15), 0 2px 4px 0 rgba(93,100,148,0.2);
  -moz-box-shadow: 0 5px 15px 0 rgba(37,44,97,0.15), 0 2px 4px 0 rgba(93,100,148,0.2);
  box-shadow: 0 5px 15px 0 rgba(37,44,97,0.15), 0 2px 4px 0 rgba(93,100,148,0.2);
  -webkit-transition: all 0.50s cubic-bezier(.25, .8, .25, 1) !important;
  -moz-transition: all 0.50s cubic-bezier(.25, .8, .25, 1) !important;
  -o-transition: all 0.50s cubic-bezier(.25, .8, .25, 1) !important;
  transition: all 0.50s cubic-bezier(.25, .8, .25, 1) !important;
}
.ui.card.reviewCard:hover {
  -webkit-box-shadow: 0 10px 28px rgba(157, 216, 242, 0.40), 0 10px 20px rgba(10, 0, 10, 0.22);
  -moz-box-shadow: 0 10px 28px rgba(157, 216, 242, 0.40), 0 10px 20px rgba(10, 0, 10, 0.22);
  box-shadow: 0 10px 28px rgba(157, 216, 242, 0.40), 0 10px 20px rgba(10, 0, 10, 0.22);
}
.ui.card.reviewCard .listItemContent .displayValue {
  margin-left: 0.5em;
  color: $blueGrey;
  word-break: break-all;
}