@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .userApplicationDetails{
    div.featureType{
      // background-color: aquamarine;
      margin-right: 2rem;
      padding-right: 0 !important;
    }
  }
  .userApplicationFormSteps {
    div > .applicationSteps > div {
      div.stepsName {
        flex: 0.7 !important;
      }
  
      div.statusFlag {
        flex: 0.3 !important;
      }
    }
  }
}


.userApplicationDetails {
  .titleAndDownloadBrochure {
    margin: 1.4rem 1.4rem 2rem 1.38rem !important;
    display: flex;
    justify-content: space-between;
    // background-color: cornsilk;
  }

  .ui.list > .applicationsDetails {
    margin: 0 1.4rem 2.6rem 1.4rem;
    // background-color: cyan;
    // div.backButton {
    //   background-color: white;
    // }

    .eachRow {
      display: flex;
      //background-color: grey;

      div.table {
        flex: 1;
        // margin: 1rem 1rem 1rem 1rem;
        align-items: center;
        // background-color: sandybrown;

        div.row {
          display: flex;
          align-content: center;
          width: 100%;
          // background-color: yellow;
          border: none;
          div.columnData {
            display: flex;
            flex-basis: 50%;
            // background-color: mediumaquamarine;
          }

          div.td {
            display: flex;
            flex: 1;
            justify-content: space-between;
            margin-right: 1rem;
            // background-color: sandybrown;

            .hrtag {
              // background-color: lawngreen;
              margin-top: 0.5rem;
              margin-bottom: 1rem;
              // margin-right: 4rem;
            }

            .TDataTitle {
              // background-color: lawngreen;
              display: flex;
              flex-basis: 3rem;
            }

            .TDataValue {
              // background-color: lightcoral;
              display: flex;
              flex-basis: 3rem;
              word-break: keep-all;

              .amountWithIcon {
                margin: 0;
                display: flex;
                justify-content: flex-start;
                // background-color: aqua;
              }
            }

            div.A {
              margin-left: 0.5rem;
            }
          }

          .caption {
            // background-color: burlywood;
            // margin-top: 0.5rem;
          }
        }

        div.riderData {
          // border: 1px solid green;
          display: flex;
          flex: 1;
          flex-direction: column;

          div.riderContentBody {
            // border: 1px solid red;
            padding-bottom: 1rem;
            margin: 0.5rem;
            margin-left: 0;
            margin-right: 0.3rem;
            word-break: keep-all;
            border-bottom: 1px solid rgba(41, 41, 40, 0.678);
            // background-color: aquamarine;
            display: flex;
            .riderNameAndDescription {
              flex: 0.5;
              // background-color: aquamarine;
            }
            .riderNameAndDescription p {
              margin-bottom: 0;
            }
            // .riderValues {
            //   border: 1px solid crimson;
            //   flex: 0.25 !important;
            //   word-break: keep-all;
            //   // font-size: $iPadFontSize !important;
            // }

            .riderValues {
              flex: 0.25;
              margin-left: 0.3rem;
              margin-right: 0.3rem;
              display: flex;
              align-items: center;
              flex-direction: column;
              // border: 1px solid crimson;
              .iconAndAmount {
                // border: 1px solid crimson;
                display: flex;
                align-items: center;
              }
              .amount {
                font-size: medium;
                margin-left: -0.2rem;
              }
            }
          }
          div.riderContentBody:last-child {
            border-bottom: none;
          }
        }

        div.titleHeader {
          //  background-color: bisque;
          margin: 2rem 0rem 0.5rem 0rem;
          color: #283593 !important;
        }

        div.productBenefitsData {
          display: flex;
          flex: 1;
          flex-direction: column;
          // background-color: cadetblue;
          .titleHeader {
            margin-bottom: 1rem;
          }
          .pbData {
            border-bottom: 1px solid grey;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
          }
          .pbData p {
            margin-bottom: 0;
          }
          .pbData:last-child {
            border-bottom: none;
          }
        }

        // div.a {
        //   border: 1px solid chocolate;
        // }
        div.features > div {
          // background-color: aqua;
          width: 100%;
          .titleHeader {
            margin-bottom: 1rem;
          }
          .featureContent {
            // border: 1px solid red;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid grey;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            .featureHeadingAndDescription {
              // background-color: aliceblue;
              flex: 0.55;
            }
            .featureHeadingAndDescription p{
              margin-bottom: 0;
            }
            .featureType {
              // background-color: aquamarine;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              flex: 0.45;
              margin-left: 1rem;
              padding-right: 5rem;
              .featureTypeText {
                color: #61b151;
                font-size: medium;
              }
            }
          }
          .featureContent:last-child {
            border-bottom: none;
          }
        }

        div.dataButton {
          margin-top: 1.5rem;
          margin-bottom: -2.4375rem;
          color: black;
          // background-color: bisque;
          font-weight: 500;
          font-size: 0.82rem;
          cursor: pointer;
        }

        // div.dataButtonViewLess {
        //   // background-color: grey;
        //   margin-top: 1.5rem;
        //   margin-bottom: -2.4375rem;
        //   color: blue;
        //   font-size: small;
        //   cursor: pointer;
        // }
      }
    }
  }
}

// For mobile screens
.userApplicationDetailsMobile {
  padding: 0.5rem !important;
  .titleAndDownloadBrochure {
    margin: 1.4rem 0rem 2rem 0rem !important;
    display: flex;
    justify-content: space-between;
    .sectionTitle {
      flex: 1;
    }
  }

  .ui.list > .applicationsDetails {
    margin: 0 0rem 2.6rem 0rem;
    // background-color: cyan;
    // div.backButton {
    //   background-color: white;
    // }

    .eachRow {
      display: flex;
      //background-color: grey;

      div.table {
        flex: 1;
        // margin: 1rem 1rem 1rem 1rem;
        align-items: center;
        // background-color: sandybrown;

        div.row {
          display: flex;
          align-content: center;
          width: 100%;
          // background-color: yellow;
          border: none;
          div.columnData {
            display: flex;
            flex-basis: 50%;
            // background-color: mediumaquamarine;
          }

          div.td {
            display: flex;
            flex: 1;
            justify-content: space-between;
            margin-right: 1rem;
            // background-color: sandybrown;

            .hrtag {
              // background-color: lawngreen;
              margin-top: 0.5rem;
              margin-bottom: 1rem;
              // margin-right: 4rem;
            }

            .TDataTitle {
              // background-color: lawngreen;
              display: flex;
              flex-basis: 3rem;
            }

            .TDataValue {
              // background-color: lightcoral;
              display: flex;
              flex-basis: 3rem;
              word-break: keep-all;

              .amountWithIcon {
                margin: 0;
                display: flex;
                justify-content: flex-start;
                // background-color: aqua;
              }
            }

            div.A {
              margin-left: 0.5rem;
            }
          }

          .caption {
            // background-color: burlywood;
            // margin-top: 0.5rem;
          }
        }

        div.riderData {
          // border: 1px solid green;
          display: flex;
          flex: 1;
          flex-direction: column;

          div.riderContentBody {
            // border: 1px solid red;
            padding-bottom: 1rem;
            margin: 0.5rem;
            margin-left: 0;
            margin-right: 0.3rem;
            word-break: keep-all;
            border-bottom: 1px solid rgba(41, 41, 40, 0.678);
            // background-color: aquamarine;
            .riderNameAndDescription {
              flex: 0.5;
              // background-color: aquamarine;
            }
            .riderNameAndDescription p {
              margin-bottom: 0;
            }
            // .riderValues {
            //   border: 1px solid crimson;
            //   flex: 0.25 !important;
            //   word-break: keep-all;
            //   // font-size: $iPadFontSize !important;
            // }
            .sumAssuredAndPremiumDiv {
              display: flex;
              justify-content: space-between;
              margin-top: 0.5rem;
              .riderValues {
                flex: 0.25;
                margin-left: 0.3rem;
                margin-right: 0.3rem;
                display: flex;
                align-items: center;
                flex-direction: column;
                // border: 1px solid crimson;
                .iconAndAmount {
                  // border: 1px solid crimson;
                  display: flex;
                  align-items: center;
                }
                .amount {
                  font-size: medium;
                  margin-left: -0.2rem;
                }
              }
            }
          }
          div.riderContentBody:last-child {
            border-bottom: none;
          }
        }

        div.titleHeader {
          //  background-color: bisque;
          margin: 2rem 0rem 0.5rem 0rem;
          color: #283593 !important;
        }

        div.productBenefitsData {
          display: flex;
          flex: 1;
          flex-direction: column;
          // background-color: cadetblue;
          .titleHeader {
            margin-bottom: 1rem;
          }
          .pbData {
            border-bottom: 1px solid grey;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
          }
          .pbData p {
            margin-bottom: 0;
          }
          .pbData:last-child {
            border-bottom: none;
          }
        }

        // div.a {
        //   border: 1px solid chocolate;
        // }
        div.features > div {
          // background-color: aqua;
          width: 100%;
          .titleHeader {
            margin-bottom: 1rem;
          }
          .featureContent {
            // border: 1px solid red;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid grey;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            .featureHeadingAndDescription {
              // background-color: aliceblue;
              flex: 0.7;
            }
            .featureHeadingAndDescription p{
              margin-bottom: 0;
            }
            .featureType {
              // background-color: aquamarine;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              flex: 0.3;
              margin-left: 1rem;
              .featureTypeText {
                color: #61b151;
                font-size: medium;
              }
            }
          }
          .featureContent:last-child {
            border-bottom: none;
          }
        }

        div.dataButton {
          margin-top: 1.5rem;
          margin-bottom: -2.4375rem;
          color: black;
          // background-color: bisque;
          font-weight: 500;
          font-size: 0.82rem;
          cursor: pointer;
        }

        // div.dataButtonViewLess {
        //   // background-color: grey;
        //   margin-top: 1.5rem;
        //   margin-bottom: -2.4375rem;
        //   color: blue;
        //   font-size: small;
        //   cursor: pointer;
        // }
      }
    }
  }
}

.userApplicationFormSteps {
  .h6 {
    // border: 1px solid crimson;
    margin: 1.4rem 1.4rem 2rem 1.38rem !important;
  }
  .empty {
    text-align: center;
    margin-top: 5rem;
    font-size: 1.4rem !important;
    // background-color: cornflowerblue;
  }

  div > .applicationSteps > div {
    display: flex;
    // background-color: rgb(187, 223, 228);
    padding-bottom: 0.8rem;
    margin: 0 1.4rem 1rem 1.4rem;
    align-items: center;
    border-bottom: 2px solid rgba(231, 231, 231, 0.925);

    div.stepsName {
      display: flex;
      flex: 1;
      // background-color: lightcoral
    }

    div.statusFlag {
      flex: 0.25;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 0.2rem;
      // background-color: #f5f6fa;

      div.statusFlagIcon {
        flex: 0.4;
        padding-top: 0.1rem;
        justify-content: flex-end;
        display: flex;
        //background-color: cyan;
      }

      div.statusFlagText {
        flex: 0.6;
        align-items: center;
        //background-color: burlywoodproductBenefitsHeader
      }
    }
  }

  div.continueButton {
    margin: 3rem 1.4rem 1.4rem 1.4rem;
    display: flex;
    // background-color: blanchedalmond;
  }
}

// For mobile views
.userApplicationFormStepsMobile {
  padding: 0.5rem !important;
  .h6 {
    // border: 1px solid crimson;
    margin: 1.4rem 0rem 2rem 0rem !important;
  }
  .empty {
    text-align: center;
    margin-top: 5rem;
    font-size: 1.4rem !important;
    // background-color: cornflowerblue;
  }

  div > .applicationSteps > div {
    display: flex;
    // background-color: rgb(187, 223, 228);
    padding-bottom: 0.8rem;
    margin: 0 0rem 1rem 0rem;
    align-items: center;
    border-bottom: 2px solid rgba(231, 231, 231, 0.925);
    div.stepsName {
      display: flex;
      flex: 0.7;
      // background-color: lightcoral
    }

    div.statusFlag {
      flex: 0.3;
      display: flex;
      justify-content: flex-end;
      // background-color: #f5f6fa;

      div.statusFlagIcon {
        flex: 0.4;
        padding-top: 0.1rem;
        padding-right: 0.5rem;
        justify-content: flex-end;
        display: flex;
        //background-color: cyan;
      }

      div.statusFlagText {
        flex: 0.6;
        align-items: center;
        //background-color: burlywoodproductBenefitsHeader
      }
    }
  }

  div.continueButton {
    margin: 3rem 0rem 1.4rem 0rem;
    display: flex;
    // background-color: blanchedalmond;
  }
}