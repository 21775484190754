
 /*******************************
              Rails
 *******************************/
 
 .ui.rail {
  position: absolute;
  top: 0%;
  width: 300px;
  height: 100%;
}

.ui.left.rail {
  left: auto;
  right: 100%;
  padding: 0em 2rem 0em 0em;
  margin: 0em 2rem 0em 0em;
}

.ui.right.rail {
  left: 100%;
  right: auto;
  padding: 0em 0em 0em 2rem;
  margin: 0em 0em 0em 2rem;
}

/*******************************
           Variations
*******************************/

/*--------------
     Internal
---------------*/

.ui.left.internal.rail {
  left: 0%;
  right: auto;
  padding: 0em 0em 0em 2rem;
  margin: 0em 0em 0em 2rem;
}

.ui.right.internal.rail {
  left: auto;
  right: 0%;
  padding: 0em 2rem 0em 0em;
  margin: 0em 2rem 0em 0em;
}

/*--------------
    Dividing
---------------*/

.ui.dividing.rail {
  width: 302.5px;
}

.ui.left.dividing.rail {
  padding: 0em 2.5rem 0em 0em;
  margin: 0em 2.5rem 0em 0em;
  border-right: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.right.dividing.rail {
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0em 0em 0em 2.5rem;
  margin: 0em 0em 0em 2.5rem;
}

/*--------------
    Distance
---------------*/

.ui.close.rail {
  width: calc( 300px  +  1em );
}

.ui.close.left.rail {
  padding: 0em 1em 0em 0em;
  margin: 0em 1em 0em 0em;
}

.ui.close.right.rail {
  padding: 0em 0em 0em 1em;
  margin: 0em 0em 0em 1em;
}

.ui.very.close.rail {
  width: calc( 300px  +  0.5em );
}

.ui.very.close.left.rail {
  padding: 0em 0.5em 0em 0em;
  margin: 0em 0.5em 0em 0em;
}

.ui.very.close.right.rail {
  padding: 0em 0em 0em 0.5em;
  margin: 0em 0em 0em 0.5em;
}

/*--------------
    Attached
---------------*/

.ui.attached.left.rail,
.ui.attached.right.rail {
  padding: 0em;
  margin: 0em;
}

/*--------------
     Sizing
---------------*/

.ui.mini.rail {
  font-size: 0.78571429rem;
}

.ui.tiny.rail {
  font-size: 0.85714286rem;
}

.ui.small.rail {
  font-size: 0.92857143rem;
}

.ui.rail {
  font-size: 1rem;
}

.ui.large.rail {
  font-size: 1.14285714rem;
}

.ui.big.rail {
  font-size: 1.28571429rem;
}

.ui.huge.rail {
  font-size: 1.42857143rem;
}

.ui.massive.rail {
  font-size: 1.71428571rem;
}