@import 'assets/styles/variables.scss';
.infoScreen {
    position: relative;
    height: max-content;
    min-height: calc(100vh - #{$headerHeight});
}
.infoScreen.filterIncluded {
  min-height: calc(100vh - #{$headerHeight} - 8.75em);
}
.mobile .infoScreen.filterIncluded .description {
  width: 80%;
  margin: auto;
}
.infoScreen .innerContainer {
  width: 25rem;
  -moz-text-align: center;
       text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
     -moz-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
       -o-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  width: 100%;
}
.infoScreen .infoScreenImage {
  margin: auto;
  margin-bottom: 1.5rem;
}
.infoScreen .heading {
  margin-bottom: 0.75rem;
}
.infoScreen .button {
  margin-top: 2rem;
}
.infoScreen .description a {
  color: unset;
  -webkit-text-decoration: underline;
     -moz-text-decoration: underline;
          text-decoration: underline;
  font-style: italic;
}