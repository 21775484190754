/*******************************
             Accordion
 *******************************/

.ui.accordion,
.ui.accordion .accordion {
  max-width: 100%;
}

.ui.accordion .accordion {
  margin: 1em 0em 0em;
  padding: 0em;
}

/* Title */

.ui.accordion .title,
.ui.accordion .accordion .title {
  cursor: pointer;
}

/* Default Styling */

.ui.accordion .title:not(.ui) {
  padding: 0.5em 0em;
  font-family: $font-family, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1em;
  color: $textColor;
}

/* Content */

.ui.accordion .title ~ .content,
.ui.accordion .accordion .title ~ .content {
  display: none;
}

/* Default Styling */

.ui.accordion:not(.styled) .title ~ .content:not(.ui),
.ui.accordion:not(.styled) .accordion .title ~ .content:not(.ui) {
  margin: "";
  padding: 0.5em 0em 1em;
}

.ui.accordion:not(.styled) .title ~ .content:not(.ui):last-child {
  padding-bottom: 0em;
}

/* Arrow */

.ui.accordion .title .dropdown.icon,
.ui.accordion .accordion .title .dropdown.icon {
  display: inline-block;
  float: none;
  opacity: 1;
  width: 1.25em;
  height: 1em;
  margin: 0em 0.25rem 0em 0rem;
  padding: 0em;
  font-size: 1em;
  -webkit-transition: opacity 0.1s ease, -webkit-transform 0.1s ease;
  transition: opacity 0.1s ease, -webkit-transform 0.1s ease;
  transition: transform 0.1s ease, opacity 0.1s ease;
  transition: transform 0.1s ease, opacity 0.1s ease, -webkit-transform 0.1s ease;
  vertical-align: baseline;
  -webkit-transform: none;
  transform: none;
}

/*--------------
     Coupling
 ---------------*/

/* Menu */

.ui.accordion.menu .item .title {
  display: block;
  padding: 0em;
}

.ui.accordion.menu .item .title > .dropdown.icon {
  float: right;
  margin: 0.21425em 0em 0em 1em;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* Header */

.ui.accordion .ui.header .dropdown.icon {
  font-size: 1em;
  margin: 0em 0.25rem 0em 0rem;
}

/*******************************
             States
 *******************************/

.ui.accordion .active.title .dropdown.icon,
.ui.accordion .accordion .active.title .dropdown.icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.ui.accordion.menu .item .active.title > .dropdown.icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/*******************************
             Types
 *******************************/

/*--------------
      Styled
 ---------------*/

.ui.styled.accordion {
  width: 600px;
}

.ui.styled.accordion,
.ui.styled.accordion .accordion {
  border-radius: 0.28571429rem;
  background: $white;
  -webkit-box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15);
}

.ui.styled.accordion .title,
.ui.styled.accordion .accordion .title {
  margin: 0em;
  padding: 0.75em 1em;
  color: $textColorLight;
  font-weight: bold;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  -webkit-transition: background 0.1s ease, color 0.1s ease;
  transition: background 0.1s ease, color 0.1s ease;
}

.ui.styled.accordion > .title:first-child,
.ui.styled.accordion .accordion .title:first-child {
  border-top: none;
}

/* Content */

.ui.styled.accordion .content,
.ui.styled.accordion .accordion .content {
  margin: 0em;
  padding: 0.5em 1em 1.5em;
}

.ui.styled.accordion .accordion .content {
  padding: 0em;
  padding: 0.5em 1em 1.5em;
}

/* Hover */

.ui.styled.accordion .title:hover,
.ui.styled.accordion .active.title,
.ui.styled.accordion .accordion .title:hover,
.ui.styled.accordion .accordion .active.title {
  background: transparent;
  color: $textColor;
}

.ui.styled.accordion .accordion .title:hover,
.ui.styled.accordion .accordion .active.title {
  background: transparent;
  color: $textColor;
}

/* Active */

.ui.styled.accordion .active.title {
  background: transparent;
  color: rgba(0, 0, 0, 0.95);
}

.ui.styled.accordion .accordion .active.title {
  background: transparent;
  color: rgba(0, 0, 0, 0.95);
}

/*******************************
             States
 *******************************/

/*--------------
      Active
 ---------------*/

.ui.accordion .active.content,
.ui.accordion .accordion .active.content {
  display: block;
}

/*******************************
            Variations
 *******************************/

/*--------------
      Fluid
 ---------------*/

.ui.fluid.accordion,
.ui.fluid.accordion .accordion {
  width: 100%;
}

/*--------------
      Inverted
 ---------------*/

.ui.inverted.accordion .title:not(.ui) {
  color: rgba(255, 255, 255, 0.9);
}

/*******************************
          Theme Overrides
 *******************************/

@font-face {
  font-family: "Accordion";
  src: url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMggjB5AAAAC8AAAAYGNtYXAPfOIKAAABHAAAAExnYXNwAAAAEAAAAWgAAAAIZ2x5Zryj6HgAAAFwAAAAyGhlYWT/0IhHAAACOAAAADZoaGVhApkB5wAAAnAAAAAkaG10eAJuABIAAAKUAAAAGGxvY2EAjABWAAACrAAAAA5tYXhwAAgAFgAAArwAAAAgbmFtZfC1n04AAALcAAABPHBvc3QAAwAAAAAEGAAAACAAAwIAAZAABQAAAUwBZgAAAEcBTAFmAAAA9QAZAIQAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADw2gHg/+D/4AHgACAAAAABAAAAAAAAAAAAAAAgAAAAAAACAAAAAwAAABQAAwABAAAAFAAEADgAAAAKAAgAAgACAAEAIPDa//3//wAAAAAAIPDZ//3//wAB/+MPKwADAAEAAAAAAAAAAAAAAAEAAf//AA8AAQAAAAAAAAAAAAIAADc5AQAAAAABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQASAEkAtwFuABMAADc0PwE2FzYXFh0BFAcGJwYvASY1EgaABQgHBQYGBQcIBYAG2wcGfwcBAQcECf8IBAcBAQd/BgYAAAAAAQAAAEkApQFuABMAADcRNDc2MzIfARYVFA8BBiMiJyY1AAUGBwgFgAYGgAUIBwYFWwEACAUGBoAFCAcFgAYGBQcAAAABAAAAAQAAqWYls18PPPUACwIAAAAAAM/9o+4AAAAAz/2j7gAAAAAAtwFuAAAACAACAAAAAAAAAAEAAAHg/+AAAAIAAAAAAAC3AAEAAAAAAAAAAAAAAAAAAAAGAAAAAAAAAAAAAAAAAQAAAAC3ABIAtwAAAAAAAAAKABQAHgBCAGQAAAABAAAABgAUAAEAAAAAAAIAAAAAAAAAAAAAAAAAAAAAAAAADgCuAAEAAAAAAAEADAAAAAEAAAAAAAIADgBAAAEAAAAAAAMADAAiAAEAAAAAAAQADABOAAEAAAAAAAUAFgAMAAEAAAAAAAYABgAuAAEAAAAAAAoANABaAAMAAQQJAAEADAAAAAMAAQQJAAIADgBAAAMAAQQJAAMADAAiAAMAAQQJAAQADABOAAMAAQQJAAUAFgAMAAMAAQQJAAYADAA0AAMAAQQJAAoANABaAHIAYQB0AGkAbgBnAFYAZQByAHMAaQBvAG4AIAAxAC4AMAByAGEAdABpAG4AZ3JhdGluZwByAGEAdABpAG4AZwBSAGUAZwB1AGwAYQByAHIAYQB0AGkAbgBnAEYAbwBuAHQAIABnAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4ALgADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA)
      format("truetype"),
    url(data:application/font-woff;charset=utf-8;base64,d09GRk9UVE8AAASwAAoAAAAABGgAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABDRkYgAAAA9AAAAS0AAAEtFpovuE9TLzIAAAIkAAAAYAAAAGAIIweQY21hcAAAAoQAAABMAAAATA984gpnYXNwAAAC0AAAAAgAAAAIAAAAEGhlYWQAAALYAAAANgAAADb/0IhHaGhlYQAAAxAAAAAkAAAAJAKZAedobXR4AAADNAAAABgAAAAYAm4AEm1heHAAAANMAAAABgAAAAYABlAAbmFtZQAAA1QAAAE8AAABPPC1n05wb3N0AAAEkAAAACAAAAAgAAMAAAEABAQAAQEBB3JhdGluZwABAgABADr4HAL4GwP4GAQeCgAZU/+Lix4KABlT/4uLDAeLa/iU+HQFHQAAAHkPHQAAAH4RHQAAAAkdAAABJBIABwEBBw0PERQZHnJhdGluZ3JhdGluZ3UwdTF1MjB1RjBEOXVGMERBAAACAYkABAAGAQEEBwoNVp38lA78lA78lA77lA773Z33bxWLkI2Qj44I9xT3FAWOj5CNkIuQi4+JjoePiI2Gi4YIi/uUBYuGiYeHiIiHh4mGi4aLho2Ijwj7FPcUBYeOiY+LkAgO+92L5hWL95QFi5CNkI6Oj4+PjZCLkIuQiY6HCPcU+xQFj4iNhouGi4aJh4eICPsU+xQFiIeGiYaLhouHjYePiI6Jj4uQCA74lBT4lBWLDAoAAAAAAwIAAZAABQAAAUwBZgAAAEcBTAFmAAAA9QAZAIQAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADw2gHg/+D/4AHgACAAAAABAAAAAAAAAAAAAAAgAAAAAAACAAAAAwAAABQAAwABAAAAFAAEADgAAAAKAAgAAgACAAEAIPDa//3//wAAAAAAIPDZ//3//wAB/+MPKwADAAEAAAAAAAAAAAAAAAEAAf//AA8AAQAAAAEAADfYOJZfDzz1AAsCAAAAAADP/aPuAAAAAM/9o+4AAAAAALcBbgAAAAgAAgAAAAAAAAABAAAB4P/gAAACAAAAAAAAtwABAAAAAAAAAAAAAAAAAAAABgAAAAAAAAAAAAAAAAEAAAAAtwASALcAAAAAUAAABgAAAAAADgCuAAEAAAAAAAEADAAAAAEAAAAAAAIADgBAAAEAAAAAAAMADAAiAAEAAAAAAAQADABOAAEAAAAAAAUAFgAMAAEAAAAAAAYABgAuAAEAAAAAAAoANABaAAMAAQQJAAEADAAAAAMAAQQJAAIADgBAAAMAAQQJAAMADAAiAAMAAQQJAAQADABOAAMAAQQJAAUAFgAMAAMAAQQJAAYADAA0AAMAAQQJAAoANABaAHIAYQB0AGkAbgBnAFYAZQByAHMAaQBvAG4AIAAxAC4AMAByAGEAdABpAG4AZ3JhdGluZwByAGEAdABpAG4AZwBSAGUAZwB1AGwAYQByAHIAYQB0AGkAbgBnAEYAbwBuAHQAIABnAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4ALgADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA)
      format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Dropdown Icon */

.ui.accordion .title .dropdown.icon,
.ui.accordion .accordion .title .dropdown.icon {
  font-family: Accordion;
  line-height: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-weight: normal;
  font-style: normal;
  text-align: center;
}

.ui.accordion .title .dropdown.icon:before,
.ui.accordion .accordion .title .dropdown.icon:before {
  content: "\f0da";
}
