@import 'assets/styles/variables.scss';

.paymentSummary {
    position: relative;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    min-height: calc(100vh - 5em);
}
.paymentSummary .paymentPage {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40%;
    min-width: 48rem;
    margin: auto;
    left: 0;
    right: 0;

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .paymentSummary .paymentPage {
        min-width: 40rem;
    }
}
.paymentSummary .leftSegmentGrid.ui.grid {
    margin-top: 1em;
}
.paymentSummary .leftSegmentGrid .appNumber {
    padding: 0em 1em 1em !important;
}
.paymentSummary .ui.segment.rightSegment {
    padding: 1.5em;
    background-color: $backgroundGrey !important;
    .rightSegment {
        margin-top: 3.5em;
    }
}
.paymentSummary .paymentSummaryHeader {
    margin: -1.5em;
    padding: 1.5em;
    background-color: rgb($primary);
}
.paymentSummary .rightSegment .ui.divider {
    margin-bottom: 1.5em;
    margin-top: 1.5em;
}
.paymentSummary .ui.header.tnc {
    margin-top: 0em;
    margin-left: 0.75em;
}
.paymentSummary .tncCheckBox {
    margin-top: 3rem;
    margin-bottom: 0.5rem;
}
.paymentSummary .proceedButtons {
    margin-top: 2rem;
}

.paymentSummary .ui.sub.header.mb-md {
    margin-bottom: 1rem;
}
.caption{
    cursor: pointer;

}