@import 'assets/styles/variables.scss';

.cardWidget {
  height: 100%;

  .description {
    margin-top: 3.2rem !important;
    text-align: justify;
  }

  .extra.content .ui.list {
    text-align: left;
  }
  .tooltip-icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .cardWidgetBtn {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    margin-top: 0 !important;

    .box:before,
    label:before,
    .box:after,
    label:after {
      display: none !important;
    }
    label {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      margin-bottom: 0 !important;
      padding: 0 !important;

      #card-label {
        padding: 2.5rem 0;
      }
    }
  }
  &.selected {
    transition: 0.1s border ease-out !important;
    border: 4px solid rgba($secondaryLight, 0.3) !important;
    box-shadow: 0 5px 15px 0 rgba($secondaryLight, 0.2), 0 2px 4px 0 rgba($secondaryLight, 0.2) !important;

    .chip {
      position: absolute;
      left: 5px;
      top: 5px;
      color: #fff;
      background-color: rgba($secondaryLight, 0.8);
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 50%;

      @media screen and (min-width: 320px) and (max-width: 510px) {
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;

        .description {
          margin-top: 4rem !important;
        }
      }
    }
  }
}
