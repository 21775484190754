// this class is used in DeferredPeriod.js, AcpPeriod.js, IaPercent.js and RopPercent.js
.deferred-period-dropdown {
  input{
    width: 100%;
    height: 2.5rem;
    padding: 0.5em 1em;
    cursor: pointer;
    position: relative;
    display: inline-block;
    outline: none;
    text-align: left;
  }
}