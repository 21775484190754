/*******************************
             Dimmer
 *******************************/

.dimmable:not(body) {
  position: relative;
}

.ui.dimmer {
  display: none;
  position: absolute;
  top: 0em !important;
  left: 0em !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 0;
  line-height: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-transition: background-color 0.5s linear;
  transition: background-color 0.5s linear;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: opacity;
  z-index: 1000;
}

/* Dimmer Content */

.ui.dimmer > .content {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  color: $white;
}

/* Loose Coupling */

.ui.segment > .ui.dimmer {
  border-radius: inherit !important;
}

/* Scrollbars */

.ui.dimmer:not(.inverted)::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.ui.dimmer:not(.inverted)::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.25);
}

.ui.dimmer:not(.inverted)::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.15);
}

.ui.dimmer:not(.inverted)::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.35);
}

/*******************************
             States
 *******************************/

/* Animating */

.animating.dimmable:not(body),
.dimmed.dimmable:not(body) {
  overflow: hidden;
}

/* Animating / Active / Visible */

.dimmed.dimmable > .ui.animating.dimmer,
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
}

/* Disabled */

.ui.disabled.dimmer {
  width: 0 !important;
  height: 0 !important;
}

/*******************************
            Variations
 *******************************/

/*--------------
     Legacy
 ---------------*/

/* Animating / Active / Visible */

.dimmed.dimmable > .ui.animating.legacy.dimmer,
.dimmed.dimmable > .ui.visible.legacy.dimmer,
.ui.active.legacy.dimmer {
  display: block;
}

/*--------------
     Alignment
 ---------------*/

.ui[class*="top aligned"].dimmer {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.ui[class*="bottom aligned"].dimmer {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

/*--------------
       Page
 ---------------*/

.ui.page.dimmer {
  position: fixed;
  -webkit-transform-style: "";
  transform-style: "";
  -webkit-perspective: 2000px;
  perspective: 2000px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

body.animating.in.dimmable,
body.dimmed.dimmable {
  overflow: hidden;
}

body.dimmable > .dimmer {
  position: fixed;
}

/*--------------
     Blurring
 ---------------*/

.blurring.dimmable > :not(.dimmer) {
  -webkit-filter: blur(0px) grayscale(0);
  filter: blur(0px) grayscale(0);
  -webkit-transition: 800ms -webkit-filter ease;
  transition: 800ms -webkit-filter ease;
  transition: 800ms filter ease;
  transition: 800ms filter ease, 800ms -webkit-filter ease;
}

.blurring.dimmed.dimmable > :not(.dimmer) {
  -webkit-filter: blur(5px) grayscale(0.7);
  filter: blur(5px) grayscale(0.7);
}

/* Dimmer Color */

.blurring.dimmable > .dimmer {
  background-color: rgba(0, 0, 0, 0.6);
}

.blurring.dimmable > .inverted.dimmer {
  background-color: rgba(255, 255, 255, 0.6);
}

/*--------------
     Aligned
 ---------------*/

.ui.dimmer > .top.aligned.content > * {
  vertical-align: top;
}

.ui.dimmer > .bottom.aligned.content > * {
  vertical-align: bottom;
}

/*--------------
     Inverted
 ---------------*/

.ui.inverted.dimmer {
  background-color: rgba(255, 255, 255, 0.85);
}

.ui.inverted.dimmer > .content > * {
  color: $white;
}

/*--------------
      Simple
 ---------------*/

/* Displays without javascript */

.ui.simple.dimmer {
  display: block;
  overflow: hidden;
  opacity: 1;
  width: 0%;
  height: 0%;
  z-index: -100;
  background-color: rgba(0, 0, 0, 0);
}

.dimmed.dimmable > .ui.simple.dimmer {
  overflow: visible;
  opacity: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1;
}

.ui.simple.inverted.dimmer {
  background-color: rgba(255, 255, 255, 0);
}

.dimmed.dimmable > .ui.simple.inverted.dimmer {
  background-color: rgba(255, 255, 255, 0.85);
}
