@import "assets/styles/variables.scss";

.mobileKnowMore {
  // width: min-content;
  overflow: hidden;
}
.mobileKnowMore .primaryDetails {
  padding: 0.75rem 1rem;
  background-color: $backgroundGrey;
}
.mobileKnowMore .rightColumn {
  -moz-text-align: right;
  text-align: right;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: auto;
}
.mobileKnowMore .ui.statistic.alignLeft > .label,
.mobileKnowMore .ui.statistic.alignLeft > .value {
  text-align: left;
  padding-left: 0px;
}
.mobileKnowMore .otherDetailsSection {
  padding: 1rem;
  width: calc(100vw - 2em);
}
