.ui.headerLogo.ui.image {
  width: 12em;
}

@media only screen and (max-width: 767px) {
  .ui.headerLogo.ui.image {
    width: 10em;
    height: 2em;
  }
}
@media only screen and (width: 320px) {
  .ui.headerLogo.ui.image {
    width: 8.5em !important;
    height: 2em;
  }
}
