@import 'assets/styles/variables.scss';
.bookingForms {
  min-height: calc(100vh - $headerHeight);
}
.bookingForms.ui.grid {
  margin-left: 0em;
  margin-right: 0em;
  margin-top: 0;
  margin-bottom: 2.5rem;
}
.bookingForms.ui.grid > .row {
  padding-top: 0;
}
.bookingForms.ui.grid > .row > .column.leftColumn {
  padding: 64px 32px 0px 48px;
}
.bookingForms.ui.grid > .row > .column.rightColumn {
  height: 100%;
  right: 0px;
  position: fixed;
  background-color: #f7f7f7;
  padding: 64px 30px 0px;
}
.appplicationSuccess {
  text-align: center;
}