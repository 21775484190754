.fileUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
    } 
  .ui.huge.floated.button {
    width: inherit;
  }
  .inputfile {
    display: none; 
}