@import 'assets/styles/variables.scss';
.bg_onboard_form_card_health {
  background-color: white;
  min-width: fit-content;
  padding: 1rem;
  margin: auto;
  width: 30rem;
  #undefined__title{
    color: rgb($primaryLight);
  }
  // .ui.input{
  //   border: 1px solid rgb(192, 192, 192);
	// 		height: 42.5px;
	// 		::placeholder {
	// 			color: grey;
	// 			opacity: 1;
	// 		}
  // }
  // .react-datepicker-wrapper.calendar-input-wrapper{
  //   border: 1px solid rgb(192, 192, 192);
  //     padding: 2px;
	// 		height: 42.5px;
	// 		::placeholder {
	// 			color: grey;
	// 			opacity: 1;
	// 		}
  // }
  // .ui.fluid.floating.search.dropdown{
  //   border: 1px solid rgb(192, 192, 192);
  // }
  @media screen and (min-width: 320px) and (max-width: 510px) {
    width: 98%;
    padding: 1rem 0;
    form {
      margin: auto;
      padding: 0 0.5rem;
    }
  }

  @media screen and (min-width: 510px) and (max-width: 768px) {
    width: 98%;
  }

}
