@import "assets/styles/variables.scss";

.landing_container {
  background-color: $white;
  width: fit-content;
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: $shadowMdDark;
  margin-inline: auto;
  .landing_container_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .insurance_type_btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      margin: 0 !important;
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }

      .insurance_type_text {
        width: 100%;
        margin-bottom: 1rem;
        padding: 0.5rem 0;
        text-transform: capitalize;
        box-shadow: 0 1px 0 0 $dullGrey;
        color: $zambezi;
        font-size: large;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          border-right: 1px solid $dullGrey;
          height: 100%;
          top: 0;
          right: 0;
          width: 0;
        }
      }
      img {
        margin-inline: 1rem;
        padding-inline: 0.3rem;
      }

      &:hover {
        .insurance_type_text {
          color: rgb($secondaryLight);
          box-shadow: 0 2.5px 0 0 rgb($secondaryLight);
        }
      }
    }
  }
  .insurance_types_section:last-child {
    .insurance_type_btn {
      .insurance_type_text::after {
        border-right: 0px;
      }
    }
  }

  @media screen and (min-width: 320px) and (max-width: 510px) {
    // landing_container
    width: 98%;

    .landing_container_row {
      flex-wrap: unset;
      flex-direction: column;
      align-items: center;

      .insurance_type_btn {
        flex-wrap: unset;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-block: 1rem !important;

        .insurance_type_text {
          padding: 0.5rem 2rem;
          margin-right: 1.5rem;
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.landing_modal {
  width: 60% !important;
  .landing_modal_form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .row:first-child {
      justify-content: center;
    }
  }
  > .header {
    text-align: right;
    padding-right: 0 !important;
    padding-top: 0.5rem !important;
    border: none !important;
  }

  @media screen and (min-width: 320px) and (max-width: 512px) {
    width: 98% !important;
  }

  @media screen and (min-width: 512px) and (max-width: 1044px) {
    width: 80% !important;
  }
}

.bg_onboard_form_card_auto {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.175) !important;
  background-color: white;
  // min-width: fit-content;
  padding: 1rem;
  margin: auto;
  width: 30rem;
  
  form > div.row.form-group:nth-child(1) {
    justify-content: center;
  }
  @media screen and (min-width: 320px) and (max-width: 510px) {
    width: 98%;
    padding: 1rem 0;
    form {
      margin: auto;
      padding: 0 0.5rem;
      .row[class*="root__"] > .grid {
        flex: 1;
      }
    }
  }

  @media screen and (min-width: 510px) and (max-width: 768px) {
    width: 98%;

    form {
      .row[class*="root__"] > .grid {
        flex: 1;
      }
    }
  }
}