/*******************************
               Shape
 *******************************/

.ui.shape {
  position: relative;
  vertical-align: top;
  display: inline-block;
  -webkit-perspective: 2000px;
  perspective: 2000px;
  -webkit-transition: left 0.6s ease-in-out, width 0.6s ease-in-out, height 0.6s ease-in-out,
    -webkit-transform 0.6s ease-in-out;
  transition: left 0.6s ease-in-out, width 0.6s ease-in-out, height 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, left 0.6s ease-in-out, width 0.6s ease-in-out, height 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, left 0.6s ease-in-out, width 0.6s ease-in-out, height 0.6s ease-in-out,
    -webkit-transform 0.6s ease-in-out;
}

.ui.shape .sides {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.ui.shape .side {
  opacity: 1;
  width: 100%;
  margin: 0em !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.ui.shape .side {
  display: none;
}

.ui.shape .side * {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

/*******************************
              Types
 *******************************/

.ui.cube.shape .side {
  min-width: 15em;
  height: 15em;
  padding: 2em;
  background-color: #e6e6e6;
  color: $textColor;
  -webkit-box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
}

.ui.cube.shape .side > .content {
  width: 100%;
  height: 100%;
  display: table;
  text-align: center;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.ui.cube.shape .side > .content > div {
  display: table-cell;
  vertical-align: middle;
  font-size: 2em;
}

/*******************************
           Variations
 *******************************/

.ui.text.shape.animating .sides {
  position: static;
}

.ui.text.shape .side {
  white-space: nowrap;
}

.ui.text.shape .side > * {
  white-space: normal;
}

/*******************************
              States
 *******************************/

/*--------------
     Loading
 ---------------*/

.ui.loading.shape {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

/*--------------
     Animating
 ---------------*/

.ui.shape .animating.side {
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  z-index: 100;
}

.ui.shape .hidden.side {
  opacity: 0.6;
}

/*--------------
       CSS
 ---------------*/

.ui.shape.animating .sides {
  position: absolute;
}

.ui.shape.animating .sides {
  -webkit-transition: left 0.6s ease-in-out, width 0.6s ease-in-out, height 0.6s ease-in-out,
    -webkit-transform 0.6s ease-in-out;
  transition: left 0.6s ease-in-out, width 0.6s ease-in-out, height 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, left 0.6s ease-in-out, width 0.6s ease-in-out, height 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, left 0.6s ease-in-out, width 0.6s ease-in-out, height 0.6s ease-in-out,
    -webkit-transform 0.6s ease-in-out;
}

.ui.shape.animating .side {
  -webkit-transition: opacity 0.6s ease-in-out;
  transition: opacity 0.6s ease-in-out;
}

/*--------------
      Active
 ---------------*/

.ui.shape .active.side {
  display: block;
}
