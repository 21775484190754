/*-----------------
   Embed
 ------------------*/

.ui.embed {
  position: relative;
  max-width: 100%;
  height: 0px;
  overflow: hidden;
  background: #dcddde;
  padding-bottom: 56.25%;
}

/*-----------------
   Embedded Content
 ------------------*/

.ui.embed iframe,
.ui.embed embed,
.ui.embed object {
  position: absolute;
  border: none;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  margin: 0em;
  padding: 0em;
}

/*-----------------
       Embed
 ------------------*/

.ui.embed > .embed {
  display: none;
}

/*--------------
    Placeholder
 ---------------*/

.ui.embed > .placeholder {
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  display: block;
  width: 100%;
  height: 100%;
  background-color: radial-gradient(transparent 45%, rgba(0, 0, 0, 0.3));
}

/*--------------
       Icon
 ---------------*/

.ui.embed > .icon {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.ui.embed > .icon:after {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 3;
  content: "";
  background: -webkit-radial-gradient(transparent 45%, rgba(0, 0, 0, 0.3));
  background: radial-gradient(transparent 45%, rgba(0, 0, 0, 0.3));
  opacity: 0.5;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.ui.embed > .icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 4;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  color: $white;
  font-size: 6rem;
  text-shadow: 0px 2px 10px rgba(34, 36, 38, 0.2);
  -webkit-transition: opacity 0.5s ease, color 0.5s ease;
  transition: opacity 0.5s ease, color 0.5s ease;
  z-index: 10;
}

/*******************************
             States
 *******************************/

/*--------------
      Hover
 ---------------*/

.ui.embed .icon:hover:after {
  background: -webkit-radial-gradient(transparent 45%, rgba(0, 0, 0, 0.3));
  background: radial-gradient(transparent 45%, rgba(0, 0, 0, 0.3));
  opacity: 1;
}

.ui.embed .icon:hover:before {
  color: $white;
}

/*--------------
      Active
 ---------------*/

.ui.active.embed > .icon,
.ui.active.embed > .placeholder {
  display: none;
}

.ui.active.embed > .embed {
  display: block;
}

/*******************************
         Video Overrides
 *******************************/

/*******************************
          Site Overrides
 *******************************/

/*******************************
           Variations
 *******************************/

.ui.square.embed {
  padding-bottom: 100%;
}

.ui[class*="4:3"].embed {
  padding-bottom: 75%;
}

.ui[class*="16:9"].embed {
  padding-bottom: 56.25%;
}

.ui[class*="21:9"].embed {
  padding-bottom: 42.85714286%;
}
