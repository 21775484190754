/** Fonts **/
$font-family: var(--font-family);
$font-family-l: var(--font-family-l);
$font-family-r: var(--font-family-r);
$font-family-m: var(--font-family-m);

$font-size: var(--font-size);
$font-size-xs: var(--font-size-xs);
$font-size-s: var(--font-size-s);
$font-size-m: var(--font-size-m);
$font-size-l: var(--font-size-l);
$font-size-xl: var(--font-size-xl);

$white: var(--white);
$black: var(--black);

$secondary: var(--secondary);
$secondaryLight: var(--secondaryLight);
$primary: var(--primary);
$primaryDark: var(--primaryDark);
$primaryLight: var(--primaryLight);

$blue: var(--blue);
$paleBlue: var(--paleBlue);
$purple: var(--purple);
$grey: var(--grey);
$green: var(--green);
$yellow: var(--yellow);
$dullgreen: var(--dullgreen);
$lightblue: var(--lightblue);
$blueGrey: var(--blueGrey);
$dullGrey: var(--dullGrey);
$zambezi: var(--zambezi);
$red: var(--red);
$backgroundGrey: var(--backgroundGrey);
$greySeperator: var(--greySeperator);

/** Box Shadows **/
$buttonBoxShadow: var(--buttonBoxShadow);

$shadowSmDark: var(--shadow-sm-dark);
$shadowMdDark: var(--shadow-md-dark);
$shadowLgDark: var(--shadow-lg-dark);

$shadowSmLight: var(--shadow-sm-light);
$shadowMdLight: var(--shadow-md-light);
$shadowLgLight: var(--shadow-lg-light);

/** Text **/
$textColor: var(--text-color);
$textColorLight: var(--text-color-light);

/** Dropdown **/
$dropdownSelectedBg: rgba(var(--secondaryLight), 0.06);
$dropdownSelectedColor: rgba(var(--secondaryLight), 0.7);
$dropdownHoverBg: rgba(var(--secondaryLight), 0.02);
$dropdownHoverColor: rgba(var(--secondaryLight), 0.5);

$headerHeight: 4em;
:export {
  secondary: rgb($secondary);
  secondaryLight: rgb($secondaryLight);
  primary: rgb($primary);
  primaryDark: rgb($primaryDark);
  primaryLight: rgb($primaryLight);
  blue: $blue;
  paleBlue: $paleBlue;
  purple: $purple;
  grey: $grey;
  green: $green;
  black: $black;
  yellow: $yellow;
  dullgreen: $dullgreen;
  lightblue: $lightblue;
  blueGrey: $blueGrey;
  dullGrey: $dullGrey;
  red: $red;
  white: $white;
  backgroundGrey: $backgroundGrey;
  greySeperator: $greySeperator;
  headerHeight: $headerHeight;
  buttonBoxShadow: $buttonBoxShadow;
}
