/*--------------
       Card
 ---------------*/

.ui.cards > .card,
.ui.card {
  max-width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 0px;
  background: $white;
  padding: 0em;
  border: none;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  -webkit-transition: -webkit-box-shadow 0.1s ease, -webkit-transform 0.1s ease;
  transition: -webkit-box-shadow 0.1s ease, -webkit-transform 0.1s ease;
  transition: box-shadow 0.1s ease, transform 0.1s ease;
  transition: box-shadow 0.1s ease, transform 0.1s ease, -webkit-box-shadow 0.1s ease, -webkit-transform 0.1s ease;
  z-index: "";
}

.ui.card {
  margin: 1em 0em;
}

.ui.cards > .card a,
.ui.card a {
  cursor: pointer;
}

.ui.card:first-child {
  margin-top: 0em;
}

.ui.card:last-child {
  margin-bottom: 0em;
}

/*--------------
       Cards
 ---------------*/

.ui.cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -0.875em -0.5em;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ui.cards > .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0.875em 0.5em;
  float: none;
}

/* Clearing */

.ui.cards:after,
.ui.card:after {
  display: block;
  content: " ";
  height: 0px;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

/* Consecutive Card Groups Preserve Row Spacing */

.ui.cards ~ .ui.cards {
  margin-top: 0.875em;
}

/*--------------
   Rounded Edges
 ---------------*/

.ui.cards > .card > :first-child,
.ui.card > :first-child {
  border-radius: 0.28571429rem 0.28571429rem 0em 0em !important;
  border-top: none !important;
}

.ui.cards > .card > :last-child,
.ui.card > :last-child {
  border-radius: 0em 0em 0.28571429rem 0.28571429rem !important;
}

.ui.cards > .card > :only-child,
.ui.card > :only-child {
  border-radius: 0.28571429rem !important;
}

/*--------------
      Images
 ---------------*/

.ui.cards > .card > .image,
.ui.card > .image {
  position: relative;
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0em;
  background: rgba(0, 0, 0, 0.05);
}

.ui.cards > .card > .image > img,
.ui.card > .image > img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: inherit;
}

.ui.cards > .card > .image:not(.ui) > img,
.ui.card > .image:not(.ui) > img {
  border: none;
}

/*--------------
      Content
 ---------------*/

.ui.cards > .card > .content,
.ui.card > .content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  border: none;
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  background: none;
  margin: 0em;
  padding: 1em 1em;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 1em;
  border-radius: 0em;
}

.ui.cards > .card > .content:after,
.ui.card > .content:after {
  display: block;
  content: " ";
  height: 0px;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

.ui.cards > .card > .content > .header,
.ui.card > .content > .header {
  display: block;
  margin: "";
  font-family: $font-family, "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.85);
}

/* Default Header Size */

.ui.cards > .card > .content > .header:not(.ui),
.ui.card > .content > .header:not(.ui) {
  font-weight: bold;
  font-size: 1.28571429em;
  margin-top: -0.21425em;
  line-height: 1.28571429em;
}

.ui.cards > .card > .content > .meta + .description,
.ui.cards > .card > .content > .header + .description,
.ui.card > .content > .meta + .description,
.ui.card > .content > .header + .description {
  margin-top: 0.5em;
}

/*----------------
  Floated Content
 -----------------*/

.ui.cards > .card [class*="left floated"],
.ui.card [class*="left floated"] {
  float: left;
}

.ui.cards > .card [class*="right floated"],
.ui.card [class*="right floated"] {
  float: right;
}

/*--------------
      Aligned
 ---------------*/

.ui.cards > .card [class*="left aligned"],
.ui.card [class*="left aligned"] {
  text-align: left;
}

.ui.cards > .card [class*="center aligned"],
.ui.card [class*="center aligned"] {
  text-align: center;
}

.ui.cards > .card [class*="right aligned"],
.ui.card [class*="right aligned"] {
  text-align: right;
}

/*--------------
   Content Image
 ---------------*/

.ui.cards > .card .content img,
.ui.card .content img {
  display: inline-block;
  vertical-align: middle;
  width: "";
}

.ui.cards > .card img.avatar,
.ui.cards > .card .avatar img,
.ui.card img.avatar,
.ui.card .avatar img {
  width: 2em;
  height: 2em;
  border-radius: 500rem;
}

/*--------------
    Description
 ---------------*/

.ui.cards > .card > .content > .description,
.ui.card > .content > .description {
  clear: both;
  color: rgba(0, 0, 0, 0.68);
}

/*--------------
     Paragraph
 ---------------*/

.ui.cards > .card > .content p,
.ui.card > .content p {
  margin: 0em 0em 0.5em;
}

.ui.cards > .card > .content p:last-child,
.ui.card > .content p:last-child {
  margin-bottom: 0em;
}

/*--------------
       Meta
 ---------------*/

.ui.cards > .card .meta,
.ui.card .meta {
  font-size: 1em;
  color: rgba(0, 0, 0, 0.4);
}

.ui.cards > .card .meta *,
.ui.card .meta * {
  margin-right: 0.3em;
}

.ui.cards > .card .meta :last-child,
.ui.card .meta :last-child {
  margin-right: 0em;
}

.ui.cards > .card .meta [class*="right floated"],
.ui.card .meta [class*="right floated"] {
  margin-right: 0em;
  margin-left: 0.3em;
}

/*--------------
       Links
 ---------------*/

/* Generic */

.ui.cards > .card > .content a:not(.ui),
.ui.card > .content a:not(.ui) {
  color: "";
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
}

.ui.cards > .card > .content a:not(.ui):hover,
.ui.card > .content a:not(.ui):hover {
  color: "";
}

/* Header */

.ui.cards > .card > .content > a.header,
.ui.card > .content > a.header {
  color: rgba(0, 0, 0, 0.85);
}

.ui.cards > .card > .content > a.header:hover,
.ui.card > .content > a.header:hover {
  color: #1e70bf;
}

/* Meta */

.ui.cards > .card .meta > a:not(.ui),
.ui.card .meta > a:not(.ui) {
  color: rgba(0, 0, 0, 0.4);
}

.ui.cards > .card .meta > a:not(.ui):hover,
.ui.card .meta > a:not(.ui):hover {
  color: $textColor;
}

/*--------------
      Buttons
 ---------------*/

.ui.cards > .card > .buttons,
.ui.card > .buttons,
.ui.cards > .card > .button,
.ui.card > .button {
  margin: 0px -1px;
  width: calc(100% + 2px);
}

/*--------------
       Dimmer
 ---------------*/

.ui.cards > .card .dimmer,
.ui.card .dimmer {
  background-color: "";
  z-index: 10;
}

/*--------------
      Labels
 ---------------*/

/*-----Star----- */

/* Icon */

.ui.cards > .card > .content .star.icon,
.ui.card > .content .star.icon {
  cursor: pointer;
  opacity: 0.75;
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
}

.ui.cards > .card > .content .star.icon:hover,
.ui.card > .content .star.icon:hover {
  opacity: 1;
  color: #ffb70a;
}

.ui.cards > .card > .content .active.star.icon,
.ui.card > .content .active.star.icon {
  color: #ffe623;
}

/*-----Like----- */

/* Icon */

.ui.cards > .card > .content .like.icon,
.ui.card > .content .like.icon {
  cursor: pointer;
  opacity: 0.75;
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
}

.ui.cards > .card > .content .like.icon:hover,
.ui.card > .content .like.icon:hover {
  opacity: 1;
  color: #ff2733;
}

.ui.cards > .card > .content .active.like.icon,
.ui.card > .content .active.like.icon {
  color: #ff2733;
}

/*----------------
   Extra Content
 -----------------*/

.ui.cards > .card > .extra,
.ui.card > .extra {
  max-width: 100%;
  min-height: 0em !important;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
  position: static;
  background: none;
  width: auto;
  margin: 0em 0em;
  padding: 0.75em 1em;
  top: 0em;
  left: 0em;
  color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
}

.ui.cards > .card > .extra a:not(.ui),
.ui.card > .extra a:not(.ui) {
  color: rgba(0, 0, 0, 0.4);
}

.ui.cards > .card > .extra a:not(.ui):hover,
.ui.card > .extra a:not(.ui):hover {
  color: #1e70bf;
}

/*******************************
            Variations
 *******************************/

/*-------------------
        Raised
 --------------------*/

.ui.raised.cards > .card,
.ui.raised.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 4px 0px rgba(34, 36, 38, 0.12),
    0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
}

.ui.raised.cards a.card:hover,
.ui.link.cards .raised.card:hover,
a.ui.raised.card:hover,
.ui.link.raised.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 4px 0px rgba(34, 36, 38, 0.15),
    0px 2px 10px 0px rgba(34, 36, 38, 0.25);
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 4px 0px rgba(34, 36, 38, 0.15), 0px 2px 10px 0px rgba(34, 36, 38, 0.25);
}

.ui.raised.cards > .card,
.ui.raised.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 4px 0px rgba(34, 36, 38, 0.12),
    0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
}

/*-------------------
        Centered
 --------------------*/

.ui.centered.cards {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ui.centered.card {
  margin-left: auto;
  margin-right: auto;
}

/*-------------------
         Fluid
 --------------------*/

.ui.fluid.card {
  width: 100%;
  max-width: 9999px;
}

/*-------------------
         Link
 --------------------*/

.ui.cards a.card,
.ui.link.cards .card,
a.ui.card,
.ui.link.card {
  -webkit-transform: none;
  transform: none;
}

.ui.cards a.card:hover,
.ui.link.cards .card:hover,
a.ui.card:hover,
.ui.link.card:hover {
  cursor: pointer;
  z-index: 5;
  background: $white;
  border: none;
  -webkit-box-shadow: 0px 1px 3px 0px #bcbdbd, 0px 0px 0px 1px #d4d4d5;
  box-shadow: 0px 1px 3px 0px #bcbdbd, 0px 0px 0px 1px #d4d4d5;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

/*-------------------
        Colors
 --------------------*/

/* Red */

.ui.red.cards > .card,
.ui.cards > .red.card,
.ui.red.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #db2828, 0px 1px 3px 0px #d4d4d5;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #db2828, 0px 1px 3px 0px #d4d4d5;
}

.ui.red.cards > .card:hover,
.ui.cards > .red.card:hover,
.ui.red.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #d01919, 0px 1px 3px 0px #bcbdbd;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #d01919, 0px 1px 3px 0px #bcbdbd;
}

/* Orange */

.ui.orange.cards > .card,
.ui.cards > .orange.card,
.ui.orange.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #f2711c, 0px 1px 3px 0px #d4d4d5;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #f2711c, 0px 1px 3px 0px #d4d4d5;
}

.ui.orange.cards > .card:hover,
.ui.cards > .orange.card:hover,
.ui.orange.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #f26202, 0px 1px 3px 0px #bcbdbd;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #f26202, 0px 1px 3px 0px #bcbdbd;
}

/* Yellow */

.ui.yellow.cards > .card,
.ui.cards > .yellow.card,
.ui.yellow.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #fbbd08, 0px 1px 3px 0px #d4d4d5;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #fbbd08, 0px 1px 3px 0px #d4d4d5;
}

.ui.yellow.cards > .card:hover,
.ui.cards > .yellow.card:hover,
.ui.yellow.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #eaae00, 0px 1px 3px 0px #bcbdbd;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #eaae00, 0px 1px 3px 0px #bcbdbd;
}

/* Olive */

.ui.olive.cards > .card,
.ui.cards > .olive.card,
.ui.olive.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #b5cc18, 0px 1px 3px 0px #d4d4d5;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #b5cc18, 0px 1px 3px 0px #d4d4d5;
}

.ui.olive.cards > .card:hover,
.ui.cards > .olive.card:hover,
.ui.olive.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #a7bd0d, 0px 1px 3px 0px #bcbdbd;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #a7bd0d, 0px 1px 3px 0px #bcbdbd;
}

/* Green */

.ui.green.cards > .card,
.ui.cards > .green.card,
.ui.green.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px $green, 0px 1px 3px 0px #d4d4d5;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px $green, 0px 1px 3px 0px #d4d4d5;
}

.ui.green.cards > .card:hover,
.ui.cards > .green.card:hover,
.ui.green.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #16ab39, 0px 1px 3px 0px #bcbdbd;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #16ab39, 0px 1px 3px 0px #bcbdbd;
}

/* Teal */

.ui.teal.cards > .card,
.ui.cards > .teal.card,
.ui.teal.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #00b5ad, 0px 1px 3px 0px #d4d4d5;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #00b5ad, 0px 1px 3px 0px #d4d4d5;
}

.ui.teal.cards > .card:hover,
.ui.cards > .teal.card:hover,
.ui.teal.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #009c95, 0px 1px 3px 0px #bcbdbd;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #009c95, 0px 1px 3px 0px #bcbdbd;
}

/* Blue */

.ui.blue.cards > .card,
.ui.cards > .blue.card,
.ui.blue.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px $blue, 0px 1px 3px 0px #d4d4d5;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px $blue, 0px 1px 3px 0px #d4d4d5;
}

.ui.blue.cards > .card:hover,
.ui.cards > .blue.card:hover,
.ui.blue.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #1678c2, 0px 1px 3px 0px #bcbdbd;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #1678c2, 0px 1px 3px 0px #bcbdbd;
}

/* Violet */

.ui.violet.cards > .card,
.ui.cards > .violet.card,
.ui.violet.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #6435c9, 0px 1px 3px 0px #d4d4d5;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #6435c9, 0px 1px 3px 0px #d4d4d5;
}

.ui.violet.cards > .card:hover,
.ui.cards > .violet.card:hover,
.ui.violet.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #5829bb, 0px 1px 3px 0px #bcbdbd;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #5829bb, 0px 1px 3px 0px #bcbdbd;
}

/* Purple */

.ui.purple.cards > .card,
.ui.cards > .purple.card,
.ui.purple.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #a333c8, 0px 1px 3px 0px #d4d4d5;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #a333c8, 0px 1px 3px 0px #d4d4d5;
}

.ui.purple.cards > .card:hover,
.ui.cards > .purple.card:hover,
.ui.purple.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #9627ba, 0px 1px 3px 0px #bcbdbd;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #9627ba, 0px 1px 3px 0px #bcbdbd;
}

/* Pink */

.ui.pink.cards > .card,
.ui.cards > .pink.card,
.ui.pink.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #e03997, 0px 1px 3px 0px #d4d4d5;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #e03997, 0px 1px 3px 0px #d4d4d5;
}

.ui.pink.cards > .card:hover,
.ui.cards > .pink.card:hover,
.ui.pink.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #e61a8d, 0px 1px 3px 0px #bcbdbd;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #e61a8d, 0px 1px 3px 0px #bcbdbd;
}

/* Brown */

.ui.brown.cards > .card,
.ui.cards > .brown.card,
.ui.brown.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #a5673f, 0px 1px 3px 0px #d4d4d5;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #a5673f, 0px 1px 3px 0px #d4d4d5;
}

.ui.brown.cards > .card:hover,
.ui.cards > .brown.card:hover,
.ui.brown.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #975b33, 0px 1px 3px 0px #bcbdbd;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #975b33, 0px 1px 3px 0px #bcbdbd;
}

/* Grey */

.ui.grey.cards > .card,
.ui.cards > .grey.card,
.ui.grey.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px $grey, 0px 1px 3px 0px #d4d4d5;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px $grey, 0px 1px 3px 0px #d4d4d5;
}

.ui.grey.cards > .card:hover,
.ui.cards > .grey.card:hover,
.ui.grey.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #838383, 0px 1px 3px 0px #bcbdbd;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #838383, 0px 1px 3px 0px #bcbdbd;
}

/* Black */

.ui.black.cards > .card,
.ui.cards > .black.card,
.ui.black.card {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #1b1c1d, 0px 1px 3px 0px #d4d4d5;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #1b1c1d, 0px 1px 3px 0px #d4d4d5;
}

.ui.black.cards > .card:hover,
.ui.cards > .black.card:hover,
.ui.black.card:hover {
  -webkit-box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #27292a, 0px 1px 3px 0px #bcbdbd;
  box-shadow: 0px 0px 0px 1px #d4d4d5, 0px 2px 0px 0px #27292a, 0px 1px 3px 0px #bcbdbd;
}

/*--------------
    Card Count
 ---------------*/

.ui.one.cards {
  margin-left: 0em;
  margin-right: 0em;
}

.ui.one.cards > .card {
  width: 100%;
}

.ui.two.cards {
  margin-left: -1em;
  margin-right: -1em;
}

.ui.two.cards > .card {
  width: calc(50% - 2em);
  margin-left: 1em;
  margin-right: 1em;
}

.ui.three.cards {
  margin-left: -1em;
  margin-right: -1em;
}

.ui.three.cards > .card {
  width: calc(33.33333333% - 2em);
  margin-left: 1em;
  margin-right: 1em;
}

.ui.four.cards {
  margin-left: -0.75em;
  margin-right: -0.75em;
}

.ui.four.cards > .card {
  width: calc(25% - 1.5em);
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.ui.five.cards {
  margin-left: -0.75em;
  margin-right: -0.75em;
}

.ui.five.cards > .card {
  width: calc(20% - 1.5em);
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.ui.six.cards {
  margin-left: -0.75em;
  margin-right: -0.75em;
}

.ui.six.cards > .card {
  width: calc(16.66666667% - 1.5em);
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.ui.seven.cards {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.ui.seven.cards > .card {
  width: calc(14.28571429% - 1em);
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.ui.eight.cards {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.ui.eight.cards > .card {
  width: calc(12.5% - 1em);
  margin-left: 0.5em;
  margin-right: 0.5em;
  font-size: 11px;
}

.ui.nine.cards {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.ui.nine.cards > .card {
  width: calc(11.11111111% - 1em);
  margin-left: 0.5em;
  margin-right: 0.5em;
  font-size: 10px;
}

.ui.ten.cards {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.ui.ten.cards > .card {
  width: calc(10% - 1em);
  margin-left: 0.5em;
  margin-right: 0.5em;
}

/*-------------------
       Doubling
 --------------------*/

/* Mobile Only */

@media only screen and (max-width: 767px) {
  .ui.two.doubling.cards {
    margin-left: 0em;
    margin-right: 0em;
  }

  .ui.two.doubling.cards > .card {
    width: 100%;
    margin-left: 0em;
    margin-right: 0em;
  }

  .ui.three.doubling.cards {
    margin-left: -1em;
    margin-right: -1em;
  }

  .ui.three.doubling.cards > .card {
    width: calc(50% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }

  .ui.four.doubling.cards {
    margin-left: -1em;
    margin-right: -1em;
  }

  .ui.four.doubling.cards > .card {
    width: calc(50% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }

  .ui.five.doubling.cards {
    margin-left: -1em;
    margin-right: -1em;
  }

  .ui.five.doubling.cards > .card {
    width: calc(50% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }

  .ui.six.doubling.cards {
    margin-left: -1em;
    margin-right: -1em;
  }

  .ui.six.doubling.cards > .card {
    width: calc(50% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }

  .ui.seven.doubling.cards {
    margin-left: -1em;
    margin-right: -1em;
  }

  .ui.seven.doubling.cards > .card {
    width: calc(33.33333333% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }

  .ui.eight.doubling.cards {
    margin-left: -1em;
    margin-right: -1em;
  }

  .ui.eight.doubling.cards > .card {
    width: calc(33.33333333% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }

  .ui.nine.doubling.cards {
    margin-left: -1em;
    margin-right: -1em;
  }

  .ui.nine.doubling.cards > .card {
    width: calc(33.33333333% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }

  .ui.ten.doubling.cards {
    margin-left: -1em;
    margin-right: -1em;
  }

  .ui.ten.doubling.cards > .card {
    width: calc(33.33333333% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }
}

/* Tablet Only */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ui.two.doubling.cards {
    margin-left: 0em;
    margin-right: 0em;
  }

  .ui.two.doubling.cards > .card {
    width: 100%;
    margin-left: 0em;
    margin-right: 0em;
  }

  .ui.three.doubling.cards {
    margin-left: -1em;
    margin-right: -1em;
  }

  .ui.three.doubling.cards > .card {
    width: calc(50% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }

  .ui.four.doubling.cards {
    margin-left: -1em;
    margin-right: -1em;
  }

  .ui.four.doubling.cards > .card {
    width: calc(50% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }

  .ui.five.doubling.cards {
    margin-left: -1em;
    margin-right: -1em;
  }

  .ui.five.doubling.cards > .card {
    width: calc(33.33333333% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }

  .ui.six.doubling.cards {
    margin-left: -1em;
    margin-right: -1em;
  }

  .ui.six.doubling.cards > .card {
    width: calc(33.33333333% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }

  .ui.eight.doubling.cards {
    margin-left: -1em;
    margin-right: -1em;
  }

  .ui.eight.doubling.cards > .card {
    width: calc(33.33333333% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }

  .ui.eight.doubling.cards {
    margin-left: -0.75em;
    margin-right: -0.75em;
  }

  .ui.eight.doubling.cards > .card {
    width: calc(25% - 1.5em);
    margin-left: 0.75em;
    margin-right: 0.75em;
  }

  .ui.nine.doubling.cards {
    margin-left: -0.75em;
    margin-right: -0.75em;
  }

  .ui.nine.doubling.cards > .card {
    width: calc(25% - 1.5em);
    margin-left: 0.75em;
    margin-right: 0.75em;
  }

  .ui.ten.doubling.cards {
    margin-left: -0.75em;
    margin-right: -0.75em;
  }

  .ui.ten.doubling.cards > .card {
    width: calc(20% - 1.5em);
    margin-left: 0.75em;
    margin-right: 0.75em;
  }
}

/*-------------------
       Stackable
 --------------------*/

@media only screen and (max-width: 767px) {
  .ui.stackable.cards {
    display: block !important;
  }

  .ui.stackable.cards .card:first-child {
    margin-top: 0em !important;
  }

  .ui.stackable.cards > .card {
    display: block !important;
    height: auto !important;
    margin: 1em 1em;
    padding: 0 !important;
    width: calc(100% - 2em) !important;
  }
}

/*--------------
       Size
 ---------------*/

.ui.cards > .card {
  font-size: 1em;
}
