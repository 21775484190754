@import 'assets/styles/variables.scss';

.wrapper {
  width: 330px;
  font-family: 'Helvetica';
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 80px;
}
.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
  display: block
}
.StepProgress::before {
  display: -moz-inline-stack;
  display: inline-block;
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  width: 10px;
  height: 100%;
  border-left: solid 2px #d8d8d8;
}
.StepProgress-item {
  position: relative;
  counter-increment: list;
}
.StepProgress-item:not(:last-child) {
  padding-bottom: 40px;
}
.StepProgress-item::before {
  display: -moz-inline-stack;
  display: inline-block;
  content: '';
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
}

.StepProgress-item::after {
  content: '';
  display: -moz-inline-stack;
  display: inline-block;
  position: absolute;
  top: 0;
  left: -41px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #d8d8d8;
}
.StepProgress-item.is-done::before {
  border-left: solid 2px $green;
}
.StepProgress-item.is-done::after {
  content: '';
  background-size: 24px 24px;
  background-image: url(https://cdn.smartcovr.io/images/common/success-icon.png);
}
.StepProgress-item.current::before {
  /* border-left: solid 2px $green */
}
.StepProgress-item:last-child::before {
  border-left: solid 2px #ffffff;
}
.StepProgress-item.current::after {
  content: '';
  padding-top: 1px;
  width: 24px;
  height: 24px;
  top: -4px;
  left: -40px;
  font-size: 14px;
  text-align: center;
  background-size: 24px 24px;
  /* background-image: url(https://cdn.smartcovr.io/images/common/success-icon.png); */
}
strong {
  display: block;
}
