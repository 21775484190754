@import "assets/styles/variables.scss";

.filter-menu-container {
  display: flex;
  min-width: 45vw;
  min-height: 45vh;
  flex-direction: column;
  align-items: center;
  text-transform: capitalize;
  i {
    color: $red;
  }

  .filter-item-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem;
    margin: 0.5rem !important;
    gap: 1rem;
    &-item {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
  }

  .chip-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    margin: 0.5rem;
    font-size: 0.85rem;
    border: 1px solid $blueGrey;
    border-radius: 1rem;
    background-color: $backgroundGrey;
    text-transform: capitalize;

    .remove-icon {
      cursor: pointer;
      margin-left: 0.3rem;
    }
  }
}

.filter-button-left {
  margin: 0.75rem !important;
  align-items: flex-start;
  margin-top: 0.5rem;
}

#more-filters-modal {
  width: 50%;
  
  .ui.vertical.buttons .button {
    margin-top: 0.25rem;
    flex: 0 0 3rem;
    white-space: nowrap;
  }

  .ui.header:first-child {
    margin: 0.75rem;
  }
}

@media only screen and (max-width: 768px) {
  .ui.accordion .title:not(.ui) {
    color: $blueGrey;
    background-color: $backgroundGrey;

    i.dropdown.icon {
      color: $blue;
    }

  }

  .filter-menu-container .chip-container {
    font-size: 0.7rem;
  }

  #more-filters-modal {
    width: 70%;
  }
}
