@import "assets/styles/variables.scss";

.bg_annuity_onboard_form_card {
	background-color: white;
	padding: 0 !important;
	border-radius: 0.5rem;
	form {
		padding: 10px;
		.ui.stackable.grid {
			margin: 0 !important;
			justify-content: center;

			> .row {
				align-items: flex-end;
			}
		}
	}

	#annuity .row {
		padding: 0;
		justify-content: flex-start;
		.column {
			padding: 0.25rem !important;
		}
		&.form-group {
			padding: 0;
			margin: 0;
			justify-content: center;
		}

		.get_button {
			margin: 1rem 0 0 0 !important;
		}
	}

	@media only screen and (min-width: 320px) and (max-width: 767px) {
		.get_button {
			text-align: center;
			width: 100%;
			margin: 2rem auto 0 0 !important;
		}
	}
	@media screen and (min-width: 767px) and (max-width: 768px) {
		max-width: 750px;
	}

	@media only screen and (min-width: 768px) and (max-width: 992px) {
		max-width: 750px;
		form {
			padding: 20px 15px;

			.row {
				.column {
					padding: 0.15rem !important;
				}
			}
		}
	}
}
