@import 'assets/styles/variables.scss';
.productConfig {
  margin-bottom: 2.5rem;
}
.productConfig .chosenProductCard {
  margin: 2em 2em 0em 0em;
  width: 15rem;
}
.productConfig .chosenProductCard .desc {
  text-align: justify;
}
.productConfig .chosenProductCard span strong {
  display: unset;
}
.productConfig .ui.grid {
  margin-left: 0em;
  margin-right: 0em;
  display: inline;
}
.productConfig .leftgrid {
    padding: 40px 0px 40px 0px;
}
.productConfig .riderCells {
    padding: 12px;
    display: flex;
}
.productConfig .riderCells .desc {
    display: flex;
    align-items: flex-start;
}
.productConfig .riderCells .content {
    flex: 0.7;
    padding-right: 16px;
    // min-width: 400px;
}
.productConfig .riderCells .riderEdit {
    padding-right: 0;
    // min-width: 400px;
}

.productConfig .riderCells .content .flexContainer {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    .header {
        margin-bottom: 0;
        margin-right: 0.5rem;
    }

    .tooltipIcon {
        flex-shrink: 0;
    }
}
.productConfig .riderCells.editDisplay .content {
    flex: unset;
}
.productConfig .tabDisplayContent .riderCells .content {
    flex: 1.6;
    min-width: unset;
    padding-right: unset;
}
.productConfig .riderCells .ui.statistic:first-child {
    margin: 0px;
}
.productConfig .riderCells .clickableLinks {
    margin: 8px 0px 16px 0px;
    margin-top: 16px;
}
.productConfig .riderCells .clickableLinks a {
    color: unset;
    cursor: pointer;
}
.productConfig .riderCells #editRider .ui.form .field label {
    color: $blueGrey;
}
.productConfig .tabContent .listItemsMobile {
    margin: 16px 0px;
}
.productConfig .tabContent .tabButtons {
    padding-bottom: 2rem;
}
.productConfig .tabDisplayContent {
    padding-bottom: 2rem;
}
.productConfig .ui.grid > .row > .column.leftColumn {
    padding: 32px 12px 0px 0px;
}
.productConfig .ui.grid > .row > .column.rightColumn {
    height: 100%;
    right: 0px;
    background-color: #f7f7f7;
    padding: 2em 2em 0px 2em;
    position: fixed;
}
.productConfig .productConfigDescription {
    width: 80%;
}
.productConfig .outer.web {
    border-bottom: 1px solid $greySeperator;
    padding-bottom: 40px;
    margin-top: 40px;
    padding-bottom: 24px;
    margin-right: 80px;
}
.productConfig .outer.mobile {
    margin-top: 40px;
}
.productConfig .outer.last {
    border-bottom: unset;
}
.productConfig .insuredDropDowm {
    margin-left: 32px;
}
.productConfig .riderCells img {
    cursor: pointer;
}
.productConfig #editRider {
    padding-top: 16px;
    // width: 300px;
}
.productConfig .fieldformat {
    color: $green
}
.productConfig .changeLabel {
    cursor: pointer;
}
.productConfig .changeLabel:hover {
    box-shadow: $buttonBoxShadow
}
.productConfig .editDisplay {
    background-color: #f7f7f7;
    padding-bottom: 24px;
}
.productConfig .proceedButtons {
    margin-top: 32px;
}
.productConfig .jsonSchemaForm.ui.form .field.primary label {
    font-family: RobotoMedium;
    font-size: 1.25em;
    letter-spacing: 0.0075em;
    font-weight: normal;
}
.productConfig .ui.form.jsonSchemaForm .field.secondary > label {
    margin-bottom: 16px;
}
.productConfig .ui.form.jsonSchemaForm .field.secondary label {
    font-size: 1em;
}
.productConfig .amountDisplay {
    margin-left: auto;
}
.productConfig .amountDisplay .header {
    margin-top: 0px;
    margin-left: auto;
}
// .productConfig .jsonSchemaForm.ui.form {
    // max-width: 400px;
// }
.productConfig .configButtons {
    margin-top: 2rem;
}
.mobile .productConfig .rightColumn {
    -moz-text-align: right;
    text-align: right;
    -webkit-flex-direction: column;
       -moz-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-left: auto;
  }
  .productConfig .primaryDetails {
    padding: 0.75rem 1rem;
    background-color: $backgroundGrey;
  }
  .productConfig .insuredMobile {
    margin: 1em;
    .buttonGroup {
        margin-top: 2em;
    }
  }