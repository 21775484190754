* {
  outline: none !important;
}
#distributorDashboardContainer {
  padding: 1rem;
  // *:not(select):focus {
  //   border: none !important;
  // }
  .dashboardContentContainer {
    // .ui.dropdown > .dropdown.icon {
    //   line-height: unset !important;
    //   width: unset !important;
    // }
    /** Header **/
    .dashboardHeader {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
      .headingtext {
        // font-weight: 600;
        border: 1px solid black;
        font-size: 1.5rem;
        padding: 0.5rem 1.5rem;
      }
    }

    /** Dashboard Content **/
    .dashboardContent {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      /** Filter Form -> Left Side **/
      .dashboardForm {
        // &.jsonSchemaForm .form-group.field.root {
        //   padding: 0;
        // }
        label {
          text-transform: capitalize !important;
        }
        .datefilter {
          display: flex;
          gap: 1rem;
          // .react-datepicker-popper {
          //   z-index: 11 !important;
          // }
          // .calendar-input-wrapper {
          //   border: 1px solid black;
          //   input {
          //     border: none;
          //   }
          // }
        }
        .property-wrapper {
          margin-bottom: 0.5rem;
          flex: 1;
          // div[id^="root_"] {
          //   border: 1px solid black;
          //   input {
          //     border: none;
          //   }
          // }
          div[id$="_error"] {
            border: none;
            margin: 0.25rem 0 0.75rem 0;
          }
          // div[class*="root_"] {
          //   .control-label {
          //     color: black;
          //   }
          // }
        }
        .filterbtnAndCountLabel {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 1rem;
          // .filterSubmitBtn {
          // border: 1px solid black;
          //   text-transform: capitalize;
          // }
        }
      }

      /** Dashboard Options -> Right Hand Side **/
      .dashboardOptions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        /** Download Option **/
        .downloadRangeContainer {
          display: flex;
          flex-direction: column;
          .dataRange {
            width: 100%;
            margin: auto;
            display: flex;
            flex-direction: column;
            margin-bottom: 0.5rem;
          }
          .downloadOptions {
            display: flex;
            justify-content: space-between;
            gap: 0.8rem;
            .ui.button {
              border: 1px solid black;
              margin: 0;
            }
          }
        }

        /** Sorted Filter **/
        .sortFilterContainer {
          display: flex;
          flex-direction: column;
          font-family: "RobotoLight";
          .sortFilterLabel {
            font-size: 1rem;
            font-weight: bold;
            text-transform: none;
          }
          .sortFilterDropdown {
            padding: 0.6rem 0.5rem;
            height: auto;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

/** Media Queries **/

@media only screen and (max-width: 768px) {
  #distributorDashboardContainer {
    .dashboardContentContainer {
      .dashboardHeader {
        .headingtext {
          font-size: 0.9rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 720px) {
  #distributorDashboardContainer {
    .dashboardContentContainer {
      .dashboardContent {
        .dashboardForm {
          flex: 0.8;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  #distributorDashboardContainer {
    .dashboardContentContainer {
      .dashboardContent {
        flex-direction: column;

        .dashboardOptions {
          flex-direction: row;
          .sortFilterContainer {
            justify-content: flex-end;
            .sortFilterLabel {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  #distributorDashboardContainer {
    .dashboardContentContainer {
      .dashboardContent {
        .dashboardForm {
          .datefilter {
            flex-direction: column;
            gap: unset;
          }
        }
        .dashboardOptions {
          flex-direction: column;
          .downloadRangeContainer {
            padding-bottom: 1rem;
            border-bottom: 1px solid #ccc;
            .downloadOptions {
              justify-content: flex-start;
            }
          }
          .sortFilterContainer {
            padding-top: 1rem;
          }
        }
      }
    }
  }
}
