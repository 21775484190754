/*******************************
             Popup
 *******************************/

.ui.popup {
  display: none;
  position: absolute;
  top: 0px;
  right: 0px;
  /* Fixes content being squished when inline (moz only) */
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  z-index: 1900;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  max-width: 250px;
  background: $white;
  padding: 0.833em 1em;
  font-weight: normal;
  font-style: normal;
  color: $textColor;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
}

.ui.popup > .header {
  padding: 0em;
  font-family: $font-family, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1.14285714em;
  line-height: 1.2;
  font-weight: bold;
}

.ui.popup > .header + .content {
  padding-top: 0.5em;
}

.ui.popup:before {
  position: absolute;
  content: "";
  width: 0.71428571em;
  height: 0.71428571em;
  background: $white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 2;
  -webkit-box-shadow: 1px 1px 0px 0px #bababc;
  box-shadow: 1px 1px 0px 0px #bababc;
}

/*******************************
             Types
 *******************************/

/*--------------
     Tooltip
 ---------------*/

/* Content */

[data-tooltip] {
  position: relative;
}

/* Arrow */

[data-tooltip]:before {
  pointer-events: none;
  position: absolute;
  content: "";
  font-size: 1rem;
  width: 0.71428571em;
  height: 0.71428571em;
  background: $white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 2;
  -webkit-box-shadow: 1px 1px 0px 0px #bababc;
  box-shadow: 1px 1px 0px 0px #bababc;
}

/* Popup */

[data-tooltip]:after {
  pointer-events: none;
  content: attr(data-tooltip);
  position: absolute;
  text-transform: none;
  text-align: left;
  white-space: nowrap;
  font-size: 1rem;
  border: 1px solid #d4d4d5;
  line-height: 1.4285em;
  max-width: none;
  background: $white;
  padding: 0.833em 1em;
  font-weight: normal;
  font-style: normal;
  color: $textColor;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  z-index: 1;
}

/* Default Position (Top Center) */

[data-tooltip]:not([data-position]):before {
  top: auto;
  right: auto;
  bottom: 100%;
  left: 50%;
  background: $white;
  margin-left: -0.07142857rem;
  margin-bottom: 0.14285714rem;
}

[data-tooltip]:not([data-position]):after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 100%;
  margin-bottom: 0.5em;
}

/* Animation */

[data-tooltip]:before,
[data-tooltip]:after {
  pointer-events: none;
  visibility: hidden;
}

[data-tooltip]:before {
  opacity: 0;
  -webkit-transform: rotate(45deg) scale(0) !important;
  transform: rotate(45deg) scale(0) !important;
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

[data-tooltip]:after {
  opacity: 1;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  pointer-events: auto;
}

[data-tooltip]:hover:before {
  -webkit-transform: rotate(45deg) scale(1) !important;
  transform: rotate(45deg) scale(1) !important;
  opacity: 1;
}

/* Animation Position */

[data-tooltip]:after,
[data-tooltip][data-position="top center"]:after,
[data-tooltip][data-position="bottom center"]:after {
  -webkit-transform: translateX(-50%) scale(0) !important;
  transform: translateX(-50%) scale(0) !important;
}

[data-tooltip]:hover:after,
[data-tooltip][data-position="bottom center"]:hover:after {
  -webkit-transform: translateX(-50%) scale(1) !important;
  transform: translateX(-50%) scale(1) !important;
}

[data-tooltip][data-position="left center"]:after,
[data-tooltip][data-position="right center"]:after {
  -webkit-transform: translateY(-50%) scale(0) !important;
  transform: translateY(-50%) scale(0) !important;
}

[data-tooltip][data-position="left center"]:hover:after,
[data-tooltip][data-position="right center"]:hover:after {
  -webkit-transform: translateY(-50%) scale(1) !important;
  transform: translateY(-50%) scale(1) !important;
}

[data-tooltip][data-position="top left"]:after,
[data-tooltip][data-position="top right"]:after,
[data-tooltip][data-position="bottom left"]:after,
[data-tooltip][data-position="bottom right"]:after {
  -webkit-transform: scale(0) !important;
  transform: scale(0) !important;
}

[data-tooltip][data-position="top left"]:hover:after,
[data-tooltip][data-position="top right"]:hover:after,
[data-tooltip][data-position="bottom left"]:hover:after,
[data-tooltip][data-position="bottom right"]:hover:after {
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
}

/*--------------
     Inverted
 ---------------*/

/* Arrow */

[data-tooltip][data-inverted]:before {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* Arrow Position */

[data-tooltip][data-inverted]:before {
  background: #1b1c1d;
}

/* Popup  */

[data-tooltip][data-inverted]:after {
  background: #1b1c1d;
  color: $white;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

[data-tooltip][data-inverted]:after .header {
  background-color: none;
  color: $white;
}

/*--------------
     Position
 ---------------*/

/* Top Center */

[data-position="top center"][data-tooltip]:after {
  top: auto;
  right: auto;
  left: 50%;
  bottom: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-bottom: 0.5em;
}

[data-position="top center"][data-tooltip]:before {
  top: auto;
  right: auto;
  bottom: 100%;
  left: 50%;
  background: $white;
  margin-left: -0.07142857rem;
  margin-bottom: 0.14285714rem;
}

/* Top Left */

[data-position="top left"][data-tooltip]:after {
  top: auto;
  right: auto;
  left: 0;
  bottom: 100%;
  margin-bottom: 0.5em;
}

[data-position="top left"][data-tooltip]:before {
  top: auto;
  right: auto;
  bottom: 100%;
  left: 1em;
  margin-left: -0.07142857rem;
  margin-bottom: 0.14285714rem;
}

/* Top Right */

[data-position="top right"][data-tooltip]:after {
  top: auto;
  left: auto;
  right: 0;
  bottom: 100%;
  margin-bottom: 0.5em;
}

[data-position="top right"][data-tooltip]:before {
  top: auto;
  left: auto;
  bottom: 100%;
  right: 1em;
  margin-left: -0.07142857rem;
  margin-bottom: 0.14285714rem;
}

/* Bottom Center */

[data-position="bottom center"][data-tooltip]:after {
  bottom: auto;
  right: auto;
  left: 50%;
  top: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 0.5em;
}

[data-position="bottom center"][data-tooltip]:before {
  bottom: auto;
  right: auto;
  top: 100%;
  left: 50%;
  margin-left: -0.07142857rem;
  margin-top: 0.14285714rem;
}

/* Bottom Left */

[data-position="bottom left"][data-tooltip]:after {
  left: 0;
  top: 100%;
  margin-top: 0.5em;
}

[data-position="bottom left"][data-tooltip]:before {
  bottom: auto;
  right: auto;
  top: 100%;
  left: 1em;
  margin-left: -0.07142857rem;
  margin-top: 0.14285714rem;
}

/* Bottom Right */

[data-position="bottom right"][data-tooltip]:after {
  right: 0;
  top: 100%;
  margin-top: 0.5em;
}

[data-position="bottom right"][data-tooltip]:before {
  bottom: auto;
  left: auto;
  top: 100%;
  right: 1em;
  margin-left: -0.14285714rem;
  margin-top: 0.07142857rem;
}

/* Left Center */

[data-position="left center"][data-tooltip]:after {
  right: 100%;
  top: 50%;
  margin-right: 0.5em;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

[data-position="left center"][data-tooltip]:before {
  right: 100%;
  top: 50%;
  margin-top: -0.14285714rem;
  margin-right: -0.07142857rem;
}

/* Right Center */

[data-position="right center"][data-tooltip]:after {
  left: 100%;
  top: 50%;
  margin-left: 0.5em;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

[data-position="right center"][data-tooltip]:before {
  left: 100%;
  top: 50%;
  margin-top: -0.07142857rem;
  margin-left: 0.14285714rem;
}

/* Arrow */

[data-position~="bottom"][data-tooltip]:before {
  background: $white;
  -webkit-box-shadow: -1px -1px 0px 0px #bababc;
  box-shadow: -1px -1px 0px 0px #bababc;
}

[data-position="left center"][data-tooltip]:before {
  background: $white;
  -webkit-box-shadow: 1px -1px 0px 0px #bababc;
  box-shadow: 1px -1px 0px 0px #bababc;
}

[data-position="right center"][data-tooltip]:before {
  background: $white;
  -webkit-box-shadow: -1px 1px 0px 0px #bababc;
  box-shadow: -1px 1px 0px 0px #bababc;
}

[data-position~="top"][data-tooltip]:before {
  background: $white;
}

/* Inverted Arrow Color */

[data-inverted][data-position~="bottom"][data-tooltip]:before {
  background: #1b1c1d;
  -webkit-box-shadow: -1px -1px 0px 0px #bababc;
  box-shadow: -1px -1px 0px 0px #bababc;
}

[data-inverted][data-position="left center"][data-tooltip]:before {
  background: #1b1c1d;
  -webkit-box-shadow: 1px -1px 0px 0px #bababc;
  box-shadow: 1px -1px 0px 0px #bababc;
}

[data-inverted][data-position="right center"][data-tooltip]:before {
  background: #1b1c1d;
  -webkit-box-shadow: -1px 1px 0px 0px #bababc;
  box-shadow: -1px 1px 0px 0px #bababc;
}

[data-inverted][data-position~="top"][data-tooltip]:before {
  background: #1b1c1d;
}

[data-position~="bottom"][data-tooltip]:before {
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

[data-position~="bottom"][data-tooltip]:after {
  -webkit-transform-origin: center top;
  transform-origin: center top;
}

[data-position="left center"][data-tooltip]:before {
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

[data-position="left center"][data-tooltip]:after {
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

[data-position="right center"][data-tooltip]:before {
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

[data-position="right center"][data-tooltip]:after {
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

/*--------------
      Spacing
 ---------------*/

.ui.popup {
  margin: 0em;
}

/* Extending from Top */

.ui.top.popup {
  margin: 0em 0em 0.71428571em;
}

.ui.top.left.popup {
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}

.ui.top.center.popup {
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

.ui.top.right.popup {
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
}

/* Extending from Vertical Center */

.ui.left.center.popup {
  margin: 0em 0.71428571em 0em 0em;
  -webkit-transform-origin: right 50%;
  transform-origin: right 50%;
}

.ui.right.center.popup {
  margin: 0em 0em 0em 0.71428571em;
  -webkit-transform-origin: left 50%;
  transform-origin: left 50%;
}

/* Extending from Bottom */

.ui.bottom.popup {
  margin: 0.71428571em 0em 0em;
}

.ui.bottom.left.popup {
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.ui.bottom.center.popup {
  -webkit-transform-origin: center top;
  transform-origin: center top;
}

.ui.bottom.right.popup {
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

/*--------------
      Pointer
 ---------------*/

/*--- Below ---*/

.ui.bottom.center.popup:before {
  margin-left: -0.30714286em;
  top: -0.30714286em;
  left: 50%;
  right: auto;
  bottom: auto;
  -webkit-box-shadow: -1px -1px 0px 0px #bababc;
  box-shadow: -1px -1px 0px 0px #bababc;
}

.ui.bottom.left.popup {
  margin-left: 0em;
}

/*rtl:rename*/

.ui.bottom.left.popup:before {
  top: -0.30714286em;
  left: 1em;
  right: auto;
  bottom: auto;
  margin-left: 0em;
  -webkit-box-shadow: -1px -1px 0px 0px #bababc;
  box-shadow: -1px -1px 0px 0px #bababc;
}

.ui.bottom.right.popup {
  margin-right: 0em;
}

/*rtl:rename*/

.ui.bottom.right.popup:before {
  top: -0.30714286em;
  right: 1em;
  bottom: auto;
  left: auto;
  margin-left: 0em;
  -webkit-box-shadow: -1px -1px 0px 0px #bababc;
  box-shadow: -1px -1px 0px 0px #bababc;
}

/*--- Above ---*/

.ui.top.center.popup:before {
  top: auto;
  right: auto;
  bottom: -0.30714286em;
  left: 50%;
  margin-left: -0.30714286em;
}

.ui.top.left.popup {
  margin-left: 0em;
}

/*rtl:rename*/

.ui.top.left.popup:before {
  bottom: -0.30714286em;
  left: 1em;
  top: auto;
  right: auto;
  margin-left: 0em;
}

.ui.top.right.popup {
  margin-right: 0em;
}

/*rtl:rename*/

.ui.top.right.popup:before {
  bottom: -0.30714286em;
  right: 1em;
  top: auto;
  left: auto;
  margin-left: 0em;
}

/*--- Left Center ---*/

/*rtl:rename*/

.ui.left.center.popup:before {
  top: 50%;
  right: -0.30714286em;
  bottom: auto;
  left: auto;
  margin-top: -0.30714286em;
  -webkit-box-shadow: 1px -1px 0px 0px #bababc;
  box-shadow: 1px -1px 0px 0px #bababc;
}

/*--- Right Center  ---*/

/*rtl:rename*/

.ui.right.center.popup:before {
  top: 50%;
  left: -0.30714286em;
  bottom: auto;
  right: auto;
  margin-top: -0.30714286em;
  -webkit-box-shadow: -1px 1px 0px 0px #bababc;
  box-shadow: -1px 1px 0px 0px #bababc;
}

/* Arrow Color By Location */

.ui.bottom.popup:before {
  background: $white;
}

.ui.right.center.popup:before,
.ui.left.center.popup:before {
  background: $white;
}

.ui.top.popup:before {
  background: $white;
}

/* Inverted Arrow Color */

.ui.inverted.bottom.popup:before {
  background: #1b1c1d;
}

.ui.inverted.right.center.popup:before,
.ui.inverted.left.center.popup:before {
  background: #1b1c1d;
}

.ui.inverted.top.popup:before {
  background: #1b1c1d;
}

/*******************************
             Coupling
 *******************************/

/* Immediate Nested Grid */

.ui.popup > .ui.grid:not(.padded) {
  width: calc(100% + 1.75rem);
  margin: -0.7rem -0.875rem;
}

/*******************************
             States
 *******************************/

.ui.loading.popup {
  display: block;
  visibility: hidden;
  z-index: -1;
}

.ui.animating.popup,
.ui.visible.popup {
  display: block;
}

.ui.visible.popup {
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/*******************************
             Variations
 *******************************/

/*--------------
      Basic
 ---------------*/

.ui.basic.popup:before {
  display: none;
}

/*--------------
      Wide
 ---------------*/

.ui.wide.popup {
  max-width: 350px;
}

.ui[class*="very wide"].popup {
  max-width: 550px;
}

@media only screen and (max-width: 767px) {
  .ui.wide.popup,
  .ui[class*="very wide"].popup {
    max-width: 250px;
  }
}

/*--------------
      Fluid
 ---------------*/

.ui.fluid.popup {
  width: 100%;
  max-width: none;
}

/*--------------
      Colors
 ---------------*/

/* Inverted colors  */

.ui.inverted.popup {
  background: #1b1c1d;
  color: $white;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui.inverted.popup .header {
  background-color: none;
  color: $white;
}

.ui.inverted.popup:before {
  background-color: #1b1c1d;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/*--------------
      Flowing
 ---------------*/

.ui.flowing.popup {
  max-width: none;
}

/*--------------
      Sizes
 ---------------*/

.ui.mini.popup {
  font-size: 0.78571429rem;
}

.ui.tiny.popup {
  font-size: 0.85714286rem;
}

.ui.small.popup {
  font-size: 0.92857143rem;
}

.ui.popup {
  font-size: 1rem;
}

.ui.large.popup {
  font-size: 1.14285714rem;
}

.ui.huge.popup {
  font-size: 1.42857143rem;
}
