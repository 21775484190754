@import "assets/styles/variables.scss";



@mixin desktop-beforeDot {
  height: 14px;
  width: 14px;
  border-radius: 100%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: -7px;
}

.mobileContentHolder {
  position: relative;
  top: -6.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

// iPad Mini and iPad Screen Sizes
@media only screen and (width: 768px) {
  .customerScreenContainer {
    // padding-left: 6em !important;
    // .test{
    //   max-width: 75% !important;
    //   div.subtitleAndBtn {
    //     // margin-right: 6em !important;
    //     align-items: center;
    //     // background-color: lightcoral;
    //   }
    // }
    .titleAndBtn {
      // border: 1px solid crimson;
      max-width: 75% !important;
    }
    div.subtitleText {
      max-width: 75% !important;
    }
  }

  .contentHolder {
    position: relative;
    top: -9.75em;
    max-width: 75% !important;
    // margin-left: 6em !important;
    // margin-right: 6em !important;

    .backButton {
      // background-color: red !important;
      display: flex !important;
      justify-content: flex-end;
      // padding-top: -15rem;
    }
  }
  .allUpcomingPremiums .ui.grid > .row > .column > .flexContainer {
    .premiumsDataContent {
      // border-bottom: 1px solid red;
      margin-top: 0.625rem;
      margin-left: 0.625rem;
      margin-right: 0.6rem;
      margin-bottom: 0.625rem;
      max-width: 18rem;
      word-break: keep-all;
      // background-color: yellow;
      min-width: 18rem;
    }
    .payButton {
      display: flex;
      flex: 1;
      // background-color: cadetblue;
      justify-content: flex-end;
      align-items: center;
      .uiBtn {
        flex: 0.8 !important;
      }
      .amount {
        font-size: 14px;
      }
    }
  }
}

// For Desktop View
.customerScreenContainer {
  // border: 1px solid crimson;
  background-color: rgb($secondaryLight);
  // padding-left: 8.5em;
  // width: 100%;
  height: 23.3125em;
  padding-top: 5.0625em;
  .titleAndBtn {
    // background-color: khaki;
    // border: 1px solid crimson;
    display: flex;
    justify-content: space-between;
    max-width: 65%;
    margin-left: auto !important;
    margin-right: auto !important;
    // .text {
    //   // margin-right: 8.5em;
    //   // border: 1px solid crimson;
    //   // background-color: lightcoral;
    //   text-transform: capitalize;
    // }

    // div.subtitleAndBtn {
    //   border: 1px solid crimson;
    //   display: flex;
    //   align-items: flex-start;
    //   display: -moz-flex;
    //   justify-content: space-between;
    //   margin-top: -0.8rem;
    //   // margin-right: 8.5em;
    //   // background-color: lightcoral;
    // }
  }
  .mobileTitleAndBtn {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    align-items: center;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  div.subtitleText {
    // border: 1px solid crimson;
    max-width: 65%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  div.mobilesubtitleText {
    // border: 1px solid crimson;
    max-width: 100%;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
}

.contentHolder {
  position: relative;
  top: -9.75em;
  max-width: 65%;
  margin-left: auto;
  margin-right: auto;

  // .backButton {
  //   //background-color: red;
  //   display: -moz-flex;
  //   display: flex;
  //   justify-content: flex-end;
  //   padding-top: -15rem;
  // }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .allUpcomingPremiums {
    .h6 {
      margin-left: -0.3rem !important;
    }
    .ui.grid > .row > .column > .flexContainer {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  .customerScreenContainer {
    padding-top: 4.55rem !important;
    .mobileBackButtonContainerDiv {
      margin-bottom: 1.5rem;
    }
  }
}

/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  // Styles
  .allUpcomingPremiums {
    min-height: 20rem !important;
    .ui.grid > .row > .column > .flexContainer {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: -moz-flex;
      display: flex;
      margin-left: 1.4rem;
      margin-right: 1.4rem;
      // background-color: yellow;
      padding-bottom: 0.52rem;
      // border: 1px solid yellow;
      border-left: 1px solid rgba(151, 151, 151, 0.2);
      border-bottom: 1px solid rgba(151, 151, 151, 0.2);
      .premiumsDataContent {
        // border: 1px solid red;
        margin-top: 0.625rem;
        margin-left: 0.625rem;
        margin-bottom: 0.625rem;
        // background-color: antiquewhite;
        // flex-basis: 13rem;
        // max-width: 8.5rem !important;
        word-break: keep-all;
      }
      .payButton {
        display: flex;
        flex: 1;
        // background-color: cadetblue;
        justify-content: flex-end;
        align-items: center;
      }
      .body2 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0 !important;
      }
      .body_icon {
        font-size: 0.75rem;
        margin-right: -0.1rem;
        margin-left: 0.15rem;
        font-weight: bold;
        // padding: 0 !important;
      }
    }
    .h6 {
      margin-top: 1.4rem !important;
      margin-left: 1.4rem;
      margin-right: 1.4rem;
      margin-bottom: 2.25rem;
    }
    .subtitleStyle {
      margin-top: 0;
      margin-bottom: -0.3rem;
    }
    .blue-beforeDot {
      background-color: rgb($secondaryLight);
      @include desktop-beforeDot();
    }
    .yellow-beforeDot {
      background-color: $yellow;
      @include desktop-beforeDot();
    }
    .blue-premiumStatusMessage {
      color: rgb($secondaryLight);
      // background-color: burlywood;
      margin-top: -0.15rem !important;
    }
    .yellow-premiumStatusMessage {
      color: $yellow;
      margin-top: -0.15rem !important;
    }
  }
}

/* Desktops and laptops ----------- */
.allUpcomingPremiums {
  min-height: 20rem !important;
  .ui.grid > .row > .column > .flexContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-flex;
    display: flex;
    margin-left: 1.4rem;
    margin-right: 1.4rem;
    // background-color: antiquewhite;
    padding-bottom: 0.52rem;
    // border: 1px solid yellow;
    border-left: 1px solid rgba(151, 151, 151, 0.2);
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    .premiumsDataContent {
      // border: 1px solid red;
      margin-top: 0.625rem;
      margin-left: 0.625rem;
      margin-bottom: 0.625rem;
      margin-right: 0.25rem;
      // background-color: antiquewhite;
      // flex-basis: 13rem;
      // max-width: 13rem;
      flex: 1;
      word-break: keep-all;
    }
    .payButton {
      display: flex;
      flex: 0.6;
      // background-color: cadetblue;
      justify-content: flex-end;
      align-items: center;
      .uiBtn{
        min-width: 5.1rem;
      }
    }
    .body2 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0 !important;
    }
    .body_icon {
      font-size: 0.75rem;
      margin-right: -0.1rem;
      margin-left: 0.15rem;
      font-weight: bold;
      // padding: 0 !important;
    }
  }
  .h6 {
    margin-top: 1.4rem !important;
    margin-left: 1.4rem !important;
    margin-right: 1.4rem !important;
    margin-bottom: 2.25rem !important;
  }
  .subtitleStyle {
    margin-top: 0 !important;
    margin-bottom: -0.3rem !important;
  }
  .blue-beforeDot {
    background-color: $blue;
    @include desktop-beforeDot();
  }
  .yellow-beforeDot {
    background-color: $yellow;
    @include desktop-beforeDot();
  }
  .blue-premiumStatusMessage {
    color: $blue !important;
    // background-color: burlywood;
    margin-top: -0.15rem !important;
  }
  .yellow-premiumStatusMessage {
    color: $yellow !important;
    margin-top: -0.15rem !important;
  }
}
.allUpcomingPremiums .row {
  // background-color: aqua;
  margin-bottom: 1.4rem;
}
.noUpcomingPremium {
  // background-color: cadetblue;
  display: flex;
  margin-top: 6rem;
  font-size: 2rem;
  justify-content: center;
  align-self: center;
}
.loaderParent {
  display: flex;
  flex-direction: column;
}
