
 /*******************************
          Activity Feed
 *******************************/
 
 .ui.feed {
  margin: 1em 0em;
}

.ui.feed:first-child {
  margin-top: 0em;
}

.ui.feed:last-child {
  margin-bottom: 0em;
}

/*******************************
            Content
*******************************/

/* Event */

.ui.feed > .event {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
  padding: 0.21428571rem 0em;
  margin: 0em;
  background: none;
  border-top: none;
}

.ui.feed > .event:first-child {
  border-top: 0px;
  padding-top: 0em;
}

.ui.feed > .event:last-child {
  padding-bottom: 0em;
}

/* Event Label */

.ui.feed > .event > .label {
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 2.5em;
  height: auto;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  text-align: left;
}

.ui.feed > .event > .label .icon {
  opacity: 1;
  font-size: 1.5em;
  width: 100%;
  padding: 0.25em;
  background: none;
  border: none;
  border-radius: none;
  color: rgba(0, 0, 0, 0.6);
}

.ui.feed > .event > .label img {
  width: 100%;
  height: auto;
  border-radius: 500rem;
}

.ui.feed > .event > .label + .content {
  margin: 0.5em 0em 0.35714286em 1.14285714em;
}

/*--------------
     Content
---------------*/

/* Content */

.ui.feed > .event > .content {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  text-align: left;
  word-wrap: break-word;
}

.ui.feed > .event:last-child > .content {
  padding-bottom: 0em;
}

/* Link */

.ui.feed > .event > .content a {
  cursor: pointer;
}

/*--------------
      Date
---------------*/

.ui.feed > .event > .content .date {
  margin: -0.5rem 0em 0em;
  padding: 0em;
  font-weight: normal;
  font-size: 1em;
  font-style: normal;
  color: rgba(0, 0, 0, 0.4);
}

/*--------------
     Summary
---------------*/

.ui.feed > .event > .content .summary {
  margin: 0em;
  font-size: 1em;
  font-weight: bold;
  color: $textColor;
}

/* Summary Image */

.ui.feed > .event > .content .summary img {
  display: inline-block;
  width: auto;
  height: 10em;
  margin: -0.25em 0.25em 0em 0em;
  border-radius: 0.25em;
  vertical-align: middle;
}

/*--------------
      User
---------------*/

.ui.feed > .event > .content .user {
  display: inline-block;
  font-weight: bold;
  margin-right: 0em;
  vertical-align: baseline;
}

.ui.feed > .event > .content .user img {
  margin: -0.25em 0.25em 0em 0em;
  width: auto;
  height: 10em;
  vertical-align: middle;
}

/*--------------
   Inline Date
---------------*/

/* Date inside Summary */

.ui.feed > .event > .content .summary > .date {
  display: inline-block;
  float: none;
  font-weight: normal;
  font-size: 0.85714286em;
  font-style: normal;
  margin: 0em 0em 0em 0.5em;
  padding: 0em;
  color: rgba(0, 0, 0, 0.4);
}

/*--------------
  Extra Summary
---------------*/

.ui.feed > .event > .content .extra {
  margin: 0.5em 0em 0em;
  background: none;
  padding: 0em;
  color: $textColor;
}

/* Images */

.ui.feed > .event > .content .extra.images img {
  display: inline-block;
  margin: 0em 0.25em 0em 0em;
  width: 6em;
}

/* Text */

.ui.feed > .event > .content .extra.text {
  padding: 0em;
  border-left: none;
  font-size: 1em;
  max-width: 500px;
  line-height: 1.4285em;
}

/*--------------
      Meta
---------------*/

.ui.feed > .event > .content .meta {
  display: inline-block;
  font-size: 0.85714286em;
  margin: 0.5em 0em 0em;
  background: none;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0em;
  color: rgba(0, 0, 0, 0.6);
}

.ui.feed > .event > .content .meta > * {
  position: relative;
  margin-left: 0.75em;
}

.ui.feed > .event > .content .meta > *:after {
  content: '';
  color: rgba(0, 0, 0, 0.2);
  top: 0em;
  left: -1em;
  opacity: 1;
  position: absolute;
  vertical-align: top;
}

.ui.feed > .event > .content .meta .like {
  color: '';
  -webkit-transition: 0.2s color ease;
  transition: 0.2s color ease;
}

.ui.feed > .event > .content .meta .like:hover .icon {
  color: #FF2733;
}

.ui.feed > .event > .content .meta .active.like .icon {
  color: #EF404A;
}

/* First element */

.ui.feed > .event > .content .meta > :first-child {
  margin-left: 0em;
}

.ui.feed > .event > .content .meta > :first-child::after {
  display: none;
}

/* Action */

.ui.feed > .event > .content .meta a,
.ui.feed > .event > .content .meta > .icon {
  cursor: pointer;
  opacity: 1;
  color: rgba(0, 0, 0, 0.5);
  -webkit-transition: color 0.1s ease;
  transition: color 0.1s ease;
}

.ui.feed > .event > .content .meta a:hover,
.ui.feed > .event > .content .meta a:hover .icon,
.ui.feed > .event > .content .meta > .icon:hover {
  color: rgba(0, 0, 0, 0.95);
}

/*******************************
            Variations
*******************************/

.ui.small.feed {
  font-size: 0.92857143rem;
}

.ui.feed {
  font-size: 1rem;
}

.ui.large.feed {
  font-size: 1.14285714rem;
}