/*******************************
             Container
 *******************************/

/* All Sizes */

.ui.container {
  display: block;
  max-width: 100% !important;
}

/* Mobile */

@media only screen and (max-width: 767px) {
  .ui.container {
    width: auto !important;
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  .ui.grid.container {
    width: auto !important;
  }

  .ui.relaxed.grid.container {
    width: auto !important;
  }

  .ui.very.relaxed.grid.container {
    width: auto !important;
  }
}

/* Tablet */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ui.container {
    width: 723px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .ui.grid.container {
    width: calc(723px + 2rem) !important;
  }

  .ui.relaxed.grid.container {
    width: calc(723px + 3rem) !important;
  }

  .ui.very.relaxed.grid.container {
    width: calc(723px + 5rem) !important;
  }
}

/* Small Monitor */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ui.container {
    width: 933px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .ui.grid.container {
    width: calc(933px + 2rem) !important;
  }

  .ui.relaxed.grid.container {
    width: calc(933px + 3rem) !important;
  }

  .ui.very.relaxed.grid.container {
    width: calc(933px + 5rem) !important;
  }
}

/* Large Monitor */

@media only screen and (min-width: 1200px) {
  .ui.container {
    width: 1127px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .ui.grid.container {
    width: calc(1127px + 2rem) !important;
  }

  .ui.relaxed.grid.container {
    width: calc(1127px + 3rem) !important;
  }

  .ui.very.relaxed.grid.container {
    width: calc(1127px + 5rem) !important;
  }
}

/*******************************
             Types
*******************************/

/* Text Container */

.ui.text.container {
  font-family: $font-family, "Helvetica Neue", Arial, Helvetica, sans-serif;
  max-width: 700px !important;
  line-height: 1.5;
}

.ui.text.container {
  font-size: 1.14285714rem;
}

/* Fluid */

.ui.fluid.container {
  width: 100%;
}

/*******************************
           Variations
*******************************/

.ui[class*="left aligned"].container {
  text-align: left;
}

.ui[class*="center aligned"].container {
  text-align: center;
}

.ui[class*="right aligned"].container {
  text-align: right;
}

.ui.justified.container {
  text-align: justify;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
