@import "assets/styles/variables.scss";
.editUserDetailsModal {
  width: unset !important;
  #editPersonalDetailsBackBtn {
    box-shadow: none;
  }
}
.singleStepOuter {
  background-color: rgb($secondary);
  height: 100vh;
  /* background-image: linear-gradient(to bottom right, #0370f6 , #05a7fa) */
}
.singleStepForm .headerBlock {
  display: flex;
  background-color: rgb($secondary);
  padding: 1.25rem !important;
  margin-bottom: 0rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.singleStepForm {
  max-width: 50rem;
  margin: auto;
  justify-content: center;
  -webkit-box-align: center;
  -moz-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.healthSingleStepForm .headerBlock {
  background-color: rgb($secondary);
  color: white !important;
  padding: 1.25rem !important;
  margin-bottom: 0rem;
}
.healthSingleStepForm {
  margin: auto;
  justify-content: center;
  -webkit-box-align: center;
  -moz-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.singleStepForm .ui.horizontal.segments {
  height: 33.75;
  margin-top: 6.5rem;
}
.singleStepForm .ui.horizontal.segments > .segment.leftSegment {
  background-color: rgb($secondaryLight);
  width: 25rem;
}
.singleStepForm .ui.horizontal.segments > .segment.rightSegment {
  width: 25rem;
}
.singleStepForm .formHeader {
  margin: 1.25rem 0rem 0rem 2.625rem;
}
.singleStepForm .outer {
  width: 100%;
  height: 28.75rem;
}
.singleStepForm .inner {
  padding-left: 3rem;
  padding-right: 3.5rem;
  margin: auto;
}
.singleStepForm .fieldDiv {
  width: 100%;
}
.singleStepForm .rightSegment .ui.form .fields {
  margin-bottom: 0.25rem;
}
.singleStepForm.mobile.rightSegment {
  padding: 2rem;
}
.singleStepForm.mobile.rightSegment.listing {
  padding: 0px;
  display: block;
}
.singleStepForm.mobile.rightSegment .formHeader {
  margin: 0.5rem 0rem 1.25rem 0rem;
}
.singleStepForm.mobile.rightSegment .innerDiv {
  padding: 2rem;
}
.healthSingleStepForm .formHeader {
  margin: 1.25rem 0rem 0rem 2.625rem;
}
.healthSingleStepForm .rightSegment .ui.form .fields {
  margin-bottom: 0.25rem;
}
.healthSingleStepForm.mobile.rightSegment {
  padding: 2rem;
}
.healthSingleStepForm.mobile.rightSegment.listing {
  padding: 0px;
  display: block;
}
.healthSingleStepForm.mobile.rightSegment .formHeader {
  margin: 0.5rem 0rem 1.25rem 0rem;
}
.healthSingleStepForm.mobile.rightSegment .innerDiv {
  padding: 3rem;
}
.singleStepForm .fieldDiv .errorMessage {
  margin-top: 0.75rem;
}
.singleStepForm .fieldDiv .noError {
  height: 1.875rem;
}
.submitButtons {
  margin-top: 2rem;
}
.singleStepForm .fieldDivAnnuity {
  width: 70%;
}
