/*******************************
             Button
 *******************************/

.ui.button {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: none;
  border: none;
  vertical-align: baseline;
  background: $backgroundGrey none;
  color: $textColor;
  font-family: $font-family-m, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0em 0.25em 0em 0em;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: uppercase;
  text-shadow: none;
  font-weight: 400;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, background 0.1s ease,
    -webkit-box-shadow 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, background 0.1s ease,
    -webkit-box-shadow 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease,
    -webkit-box-shadow 0.1s ease;
  will-change: "";
  -webkit-tap-highlight-color: transparent;
}

/*******************************
            States
*******************************/

/*--------------
      Hover
---------------*/

.ui.button:hover {
  background-color: #cacbcd;
  background-image: none;
  -webkit-box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  color: rgba(0, 0, 0, 0.8);
}

.ui.button:hover .icon {
  opacity: 0.85;
}

/*--------------
      Focus
---------------*/

.ui.button:focus {
  background-color: #cacbcd;
  color: rgba(0, 0, 0, 0.8);
  background-image: "" !important;
  -webkit-box-shadow: "" !important;
  box-shadow: "" !important;
}

.ui.button:focus .icon {
  opacity: 0.85;
}

/*--------------
      Down
---------------*/

.ui.button:active,
.ui.active.button:active {
  background-color: #babbbc;
  background-image: "";
  color: rgba(0, 0, 0, 0.9);
  -webkit-box-shadow: 0px 0px 0px 1px transparent inset, none;
  box-shadow: 0px 0px 0px 1px transparent inset, none;
}

/*--------------
     Active
---------------*/

.ui.active.button {
  background-color: #c0c1c2;
  background-image: none;
  -webkit-box-shadow: 0px 0px 0px 1px transparent inset;
  box-shadow: 0px 0px 0px 1px transparent inset;
  color: rgba(0, 0, 0, 0.95);
}

.ui.active.button:hover {
  background-color: #c0c1c2;
  background-image: none;
  color: rgba(0, 0, 0, 0.95);
}

.ui.active.button:active {
  background-color: #c0c1c2;
  background-image: none;
}

/*--------------
    Loading
---------------*/

/* Specificity hack */

.ui.loading.loading.loading.loading.loading.loading.button {
  position: relative;
  cursor: default;
  text-shadow: none !important;
  color: transparent !important;
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0s linear, opacity 0.1s ease;
  transition: all 0s linear, opacity 0.1s ease;
}

.ui.loading.button:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0em 0em -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.15);
}

.ui.loading.button:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0em 0em -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  -webkit-animation: button-spin 0.6s linear;
  animation: button-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: $white transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  -webkit-box-shadow: 0px 0px 0px 1px transparent;
  box-shadow: 0px 0px 0px 1px transparent;
}

.ui.labeled.icon.loading.button .icon {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@-webkit-keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ui.basic.loading.button:not(.inverted):before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.basic.loading.button:not(.inverted):after {
  border-top-color: #767676;
}

/*-------------------
      Disabled
--------------------*/

.ui.buttons .disabled.button,
.ui.disabled.button,
.ui.button:disabled,
.ui.disabled.button:hover,
.ui.disabled.active.button {
  cursor: default;
  opacity: 0.45 !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  pointer-events: none !important;
}

/* Basic Group With Disabled */

.ui.basic.buttons .ui.disabled.button {
  border-color: rgba(34, 36, 38, 0.5);
}

/*******************************
             Types
*******************************/

/*-------------------
       Animated
--------------------*/

.ui.animated.button {
  position: relative;
  overflow: hidden;
  padding-right: 0em !important;
  vertical-align: middle;
  z-index: 1;
}

.ui.animated.button .content {
  will-change: transform, opacity;
}

.ui.animated.button .visible.content {
  position: relative;
  margin-right: 1.5em;
}

.ui.animated.button .hidden.content {
  position: absolute;
  width: 100%;
}

/* Horizontal */

.ui.animated.button .visible.content,
.ui.animated.button .hidden.content {
  -webkit-transition: right 0.3s ease 0s;
  transition: right 0.3s ease 0s;
}

.ui.animated.button .visible.content {
  left: auto;
  right: 0%;
}

.ui.animated.button .hidden.content {
  top: 50%;
  left: auto;
  right: -100%;
  margin-top: -0.5em;
}

.ui.animated.button:focus .visible.content,
.ui.animated.button:hover .visible.content {
  left: auto;
  right: 200%;
}

.ui.animated.button:focus .hidden.content,
.ui.animated.button:hover .hidden.content {
  left: auto;
  right: 0%;
}

/* Vertical */

.ui.vertical.animated.button .visible.content,
.ui.vertical.animated.button .hidden.content {
  -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease;
  transition: top 0.3s ease, -webkit-transform 0.3s ease;
  transition: top 0.3s ease, transform 0.3s ease;
  transition: top 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
}

.ui.vertical.animated.button .visible.content {
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  right: auto;
}

.ui.vertical.animated.button .hidden.content {
  top: -50%;
  left: 0%;
  right: auto;
}

.ui.vertical.animated.button:focus .visible.content,
.ui.vertical.animated.button:hover .visible.content {
  -webkit-transform: translateY(200%);
  transform: translateY(200%);
  right: auto;
}

.ui.vertical.animated.button:focus .hidden.content,
.ui.vertical.animated.button:hover .hidden.content {
  top: 50%;
  right: auto;
}

/* Fade */

.ui.fade.animated.button .visible.content,
.ui.fade.animated.button .hidden.content {
  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
}

.ui.fade.animated.button .visible.content {
  left: auto;
  right: auto;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.ui.fade.animated.button .hidden.content {
  opacity: 0;
  left: 0%;
  right: auto;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.ui.fade.animated.button:focus .visible.content,
.ui.fade.animated.button:hover .visible.content {
  left: auto;
  right: auto;
  opacity: 0;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
}

.ui.fade.animated.button:focus .hidden.content,
.ui.fade.animated.button:hover .hidden.content {
  left: 0%;
  right: auto;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*-------------------
       Inverted
--------------------*/

.ui.inverted.button {
  -webkit-box-shadow: 0px 0px 0px 2px $white inset !important;
  box-shadow: 0px 0px 0px 2px $white inset !important;
  background: transparent none;
  color: $white;
  text-shadow: none !important;
}

/* Group */

.ui.inverted.buttons .button {
  margin: 0px 0px 0px -2px;
}

.ui.inverted.buttons .button:first-child {
  margin-left: 0em;
}

.ui.inverted.vertical.buttons .button {
  margin: 0px 0px -2px 0px;
}

.ui.inverted.vertical.buttons .button:first-child {
  margin-top: 0em;
}

/* States */

/* Hover */

.ui.inverted.button:hover {
  background: $white;
  -webkit-box-shadow: 0px 0px 0px 2px $white inset !important;
  box-shadow: 0px 0px 0px 2px $white inset !important;
  color: rgba(0, 0, 0, 0.8);
}

/* Active / Focus */

.ui.inverted.button:focus,
.ui.inverted.button.active {
  background: $white;
  -webkit-box-shadow: 0px 0px 0px 2px $white inset !important;
  box-shadow: 0px 0px 0px 2px $white inset !important;
  color: rgba(0, 0, 0, 0.8);
}

/* Active Focus */

.ui.inverted.button.active:focus {
  background: #dcddde;
  -webkit-box-shadow: 0px 0px 0px 2px #dcddde inset !important;
  box-shadow: 0px 0px 0px 2px #dcddde inset !important;
  color: rgba(0, 0, 0, 0.8);
}

/*-------------------
    Labeled Button
--------------------*/

.ui.labeled.button:not(.icon) {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background: none !important;
  padding: 0px !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ui.labeled.button > .button {
  margin: 0px;
}

.ui.labeled.button > .label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0px 0px 0px -1px !important;
  padding: "";
  font-size: 1em;
  border-color: rgba(34, 36, 38, 0.15);
}

/* Tag */

.ui.labeled.button > .tag.label:before {
  width: 1.85em;
  height: 1.85em;
}

/* Right */

.ui.labeled.button:not([class*="left labeled"]) > .button {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.ui.labeled.button:not([class*="left labeled"]) > .label {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

/* Left Side */

.ui[class*="left labeled"].button > .button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.ui[class*="left labeled"].button > .label {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

/*-------------------
       Social
--------------------*/

/* Facebook */

.ui.facebook.button {
  background-color: #3b5998;
  color: $white;
  text-shadow: none;
  background-image: none;
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.facebook.button:hover {
  background-color: #304d8a;
  color: $white;
  text-shadow: none;
}

.ui.facebook.button:active {
  background-color: #2d4373;
  color: $white;
  text-shadow: none;
}

/* Twitter */

.ui.twitter.button {
  background-color: #55acee;
  color: $white;
  text-shadow: none;
  background-image: none;
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.twitter.button:hover {
  background-color: #35a2f4;
  color: $white;
  text-shadow: none;
}

.ui.twitter.button:active {
  background-color: #2795e9;
  color: $white;
  text-shadow: none;
}

/* Google Plus */

.ui.google.plus.button {
  background-color: #dd4b39;
  color: $white;
  text-shadow: none;
  background-image: none;
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.google.plus.button:hover {
  background-color: #e0321c;
  color: $white;
  text-shadow: none;
}

.ui.google.plus.button:active {
  background-color: #c23321;
  color: $white;
  text-shadow: none;
}

/* Linked In */

.ui.linkedin.button {
  background-color: #1f88be;
  color: $white;
  text-shadow: none;
}

.ui.linkedin.button:hover {
  background-color: #147baf;
  color: $white;
  text-shadow: none;
}

.ui.linkedin.button:active {
  background-color: #186992;
  color: $white;
  text-shadow: none;
}

/* YouTube */

.ui.youtube.button {
  background-color: #ff0000;
  color: $white;
  text-shadow: none;
  background-image: none;
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.youtube.button:hover {
  background-color: #e60000;
  color: $white;
  text-shadow: none;
}

.ui.youtube.button:active {
  background-color: #cc0000;
  color: $white;
  text-shadow: none;
}

/* Instagram */

.ui.instagram.button {
  background-color: #49769c;
  color: $white;
  text-shadow: none;
  background-image: none;
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.instagram.button:hover {
  background-color: #3d698e;
  color: $white;
  text-shadow: none;
}

.ui.instagram.button:active {
  background-color: #395c79;
  color: $white;
  text-shadow: none;
}

/* Pinterest */

.ui.pinterest.button {
  background-color: #bd081c;
  color: $white;
  text-shadow: none;
  background-image: none;
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.pinterest.button:hover {
  background-color: #ac0013;
  color: $white;
  text-shadow: none;
}

.ui.pinterest.button:active {
  background-color: #8c0615;
  color: $white;
  text-shadow: none;
}

/* VK */

.ui.vk.button {
  background-color: #4d7198;
  color: $white;
  background-image: none;
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.vk.button:hover {
  background-color: #41648a;
  color: $white;
}

.ui.vk.button:active {
  background-color: #3c5876;
  color: $white;
}

/*--------------
     Icon
---------------*/

.ui.button > .icon:not(.button) {
  height: 0.85714286em;
  opacity: 0.8;
  margin: 0em 0.42857143em 0em -0.21428571em;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
  vertical-align: "";
  color: "";
}

.ui.button:not(.icon) > .icon:not(.button):not(.dropdown) {
  margin: 0em 0.42857143em 0em -0.21428571em;
}

.ui.button:not(.icon) > .right.icon:not(.button):not(.dropdown) {
  margin: 0em -0.21428571em 0em 0.42857143em;
}

/*******************************
           Variations
*******************************/

/*-------------------
       Floated
--------------------*/

.ui[class*="left floated"].buttons,
.ui[class*="left floated"].button {
  float: left;
  margin-left: 0em;
  margin-right: 0.25em;
}

.ui[class*="right floated"].buttons,
.ui[class*="right floated"].button {
  float: right;
  margin-right: 0em;
  margin-left: 0.25em;
}

/*-------------------
       Compact
--------------------*/

.ui.compact.buttons .button,
.ui.compact.button {
  padding: 0.58928571em 1.125em 0.58928571em;
}

.ui.compact.icon.buttons .button,
.ui.compact.icon.button {
  padding: 0.58928571em 0.58928571em 0.58928571em;
}

.ui.compact.labeled.icon.buttons .button,
.ui.compact.labeled.icon.button {
  padding: 0.58928571em 3.69642857em 0.58928571em;
}

/*-------------------
        Sizes
--------------------*/

.ui.mini.buttons .button,
.ui.mini.buttons .or,
.ui.mini.button {
  font-size: 0.625rem;
  padding: 0.25rem 0.5rem;
}

.ui.tiny.buttons .button,
.ui.tiny.buttons .or,
.ui.tiny.button {
  font-size: 0.75rem;
}

.ui.small.buttons .button,
.ui.small.buttons .or,
.ui.small.button {
  font-size: 0.92857143rem;
}

.ui.buttons .button,
.ui.buttons .or,
.ui.button {
  font-size: $font-size-m;
}

.ui.large.buttons .button,
.ui.large.buttons .or,
.ui.large.button {
  font-size: 1.14285714rem;
}

.ui.big.buttons .button,
.ui.big.buttons .or,
.ui.big.button {
  font-size: 1.28571429rem;
}

.ui.huge.buttons .button,
.ui.huge.buttons .or,
.ui.huge.button {
  font-size: 1.42857143rem;
}

.ui.massive.buttons .button,
.ui.massive.buttons .or,
.ui.massive.button {
  font-size: 1.71428571rem;
}

/*--------------
    Icon Only
---------------*/

.ui.icon.buttons .button,
.ui.icon.button {
  padding: 0.78571429em 0.78571429em 0.78571429em;
}

.ui.icon.buttons .button > .icon,
.ui.icon.button > .icon {
  opacity: 0.9;
  margin: 0em !important;
  vertical-align: top;
}

/*-------------------
        Basic
--------------------*/

.ui.basic.buttons .button,
.ui.basic.button {
  background: transparent none !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: normal;
  border-radius: 0.28571429rem;
  text-shadow: none !important;
  box-shadow: none !important;
}

.ui.basic.buttons {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
}

.ui.basic.buttons .button {
  border-radius: 0em;
}

.ui.basic.buttons .button:hover,
.ui.basic.button:hover {
  background: $white !important;
  color: rgba(0, 0, 0, 0.8) !important;
  box-shadow: none;
}

.ui.basic.buttons .button:focus,
.ui.basic.button:focus {
  background: $white !important;
  color: rgba(0, 0, 0, 0.8) !important;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.35) inset, 0px 0px 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.35) inset, 0px 0px 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.basic.buttons .button:active,
.ui.basic.button:active {
  background: #f8f8f8 !important;
  color: rgba(0, 0, 0, 0.9) !important;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15) inset, 0px 1px 4px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15) inset, 0px 1px 4px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.basic.buttons .active.button,
.ui.basic.active.button {
  background: rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: "" !important;
  box-shadow: "" !important;
  color: rgba(0, 0, 0, 0.95) !important;
}

.ui.basic.buttons .active.button:hover,
.ui.basic.active.button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Vertical */

.ui.basic.buttons .button:hover {
  -webkit-box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.35) inset, 0px 0px 0px 0px rgba(34, 36, 38, 0.15) inset inset;
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.35) inset, 0px 0px 0px 0px rgba(34, 36, 38, 0.15) inset inset;
}

.ui.basic.buttons .button:active {
  -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15) inset, 0px 1px 4px 0px rgba(34, 36, 38, 0.15) inset inset;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15) inset, 0px 1px 4px 0px rgba(34, 36, 38, 0.15) inset inset;
}

.ui.basic.buttons .active.button {
  -webkit-box-shadow: "" !important;
  box-shadow: "" !important;
}

/* Standard Basic Inverted */

.ui.basic.inverted.buttons .button,
.ui.basic.inverted.button {
  background-color: transparent !important;
  color: #f9fafb !important;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
}

.ui.basic.inverted.buttons .button:hover,
.ui.basic.inverted.button:hover {
  color: $white !important;
  -webkit-box-shadow: 0px 0px 0px 2px $white inset !important;
  box-shadow: 0px 0px 0px 2px $white inset !important;
}

.ui.basic.inverted.buttons .button:focus,
.ui.basic.inverted.button:focus {
  color: $white !important;
  -webkit-box-shadow: 0px 0px 0px 2px $white inset !important;
  box-shadow: 0px 0px 0px 2px $white inset !important;
}

.ui.basic.inverted.buttons .button:active,
.ui.basic.inverted.button:active {
  background-color: rgba(255, 255, 255, 0.08) !important;
  color: $white !important;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.9) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.9) inset !important;
}

.ui.basic.inverted.buttons .active.button,
.ui.basic.inverted.active.button {
  background-color: rgba(255, 255, 255, 0.08);
  color: $white;
  text-shadow: none;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.7) inset;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.7) inset;
}

.ui.basic.inverted.buttons .active.button:hover,
.ui.basic.inverted.active.button:hover {
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-box-shadow: 0px 0px 0px 2px $white inset !important;
  box-shadow: 0px 0px 0px 2px $white inset !important;
}

/* Basic Group */

.ui.basic.buttons .button {
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui.basic.vertical.buttons .button {
  border-left: none;
}

.ui.basic.vertical.buttons .button {
  border-left-width: 0px;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.basic.vertical.buttons .button:first-child {
  border-top-width: 0px;
}

/*--------------
  Labeled Icon
---------------*/

.ui.labeled.icon.buttons .button,
.ui.labeled.icon.button {
  position: relative;
  padding-left: 4.07142857em !important;
  padding-right: 1.5em !important;
}

/* Left Labeled */

.ui.labeled.icon.buttons > .button > .icon,
.ui.labeled.icon.button > .icon {
  position: absolute;
  height: 100%;
  line-height: 1;
  border-radius: 0px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  text-align: center;
  margin: 0em;
  width: 2.57142857em;
  background-color: rgba(0, 0, 0, 0.05);
  color: "";
  -webkit-box-shadow: -1px 0px 0px 0px transparent inset;
  box-shadow: -1px 0px 0px 0px transparent inset;
}

/* Left Labeled */

.ui.labeled.icon.buttons > .button > .icon,
.ui.labeled.icon.button > .icon {
  top: 0em;
  left: 0em;
}

/* Right Labeled */

.ui[class*="right labeled"].icon.button {
  padding-right: 4.07142857em !important;
  padding-left: 1.5em !important;
}

.ui[class*="right labeled"].icon.button > .icon {
  left: auto;
  right: 0em;
  border-radius: 0px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  -webkit-box-shadow: 1px 0px 0px 0px transparent inset;
  box-shadow: 1px 0px 0px 0px transparent inset;
}

.ui.labeled.icon.buttons > .button > .icon:before,
.ui.labeled.icon.button > .icon:before,
.ui.labeled.icon.buttons > .button > .icon:after,
.ui.labeled.icon.button > .icon:after {
  display: block;
  position: absolute;
  width: 100%;
  top: 50%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ui.labeled.icon.buttons .button > .icon {
  border-radius: 0em;
}

.ui.labeled.icon.buttons .button:first-child > .icon {
  border-top-left-radius: 0.28571429rem;
  border-bottom-left-radius: 0.28571429rem;
}

.ui.labeled.icon.buttons .button:last-child > .icon {
  border-top-right-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
}

.ui.vertical.labeled.icon.buttons .button:first-child > .icon {
  border-radius: 0em;
  border-top-left-radius: 0.28571429rem;
}

.ui.vertical.labeled.icon.buttons .button:last-child > .icon {
  border-radius: 0em;
  border-bottom-left-radius: 0.28571429rem;
}

/* Fluid Labeled */

.ui.fluid[class*="left labeled"].icon.button,
.ui.fluid[class*="right labeled"].icon.button {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

/*--------------
     Toggle
---------------*/

/* Toggle (Modifies active state to give affordances) */

.ui.toggle.buttons .active.button,
.ui.buttons .button.toggle.active,
.ui.button.toggle.active {
  background-color: #21ba45 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-shadow: none;
  color: $white !important;
}

.ui.button.toggle.active:hover {
  background-color: #16ab39 !important;
  text-shadow: none;
  color: $white !important;
}

/*--------------
    Circular
---------------*/

.ui.circular.button {
  border-radius: 10em;
}

.ui.circular.button > .icon {
  width: 1em;
  vertical-align: baseline;
}

/*-------------------
      Or Buttons
--------------------*/

.ui.buttons .or {
  position: relative;
  width: 0.3em;
  height: 2.57142857em;
  z-index: 3;
}

.ui.buttons .or:before {
  position: absolute;
  text-align: center;
  border-radius: 500rem;
  content: "or";
  top: 50%;
  left: 50%;
  background-color: $white;
  text-shadow: none;
  margin-top: -0.89285714em;
  margin-left: -0.89285714em;
  width: 1.78571429em;
  height: 1.78571429em;
  line-height: 1.78571429em;
  color: rgba(0, 0, 0, 0.4);
  font-style: normal;
  font-weight: bold;
  -webkit-box-shadow: 0px 0px 0px 1px transparent inset;
  box-shadow: 0px 0px 0px 1px transparent inset;
}

.ui.buttons .or[data-text]:before {
  content: attr(data-text);
}

/* Fluid Or */

.ui.fluid.buttons .or {
  width: 0em !important;
}

.ui.fluid.buttons .or:after {
  display: none;
}

/*-------------------
       Attached
--------------------*/

/* Singular */

.ui.attached.button {
  position: relative;
  display: block;
  margin: 0em;
  border-radius: 0em;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) !important;
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) !important;
}

/* Top / Bottom */

.ui.attached.top.button {
  border-radius: 0.28571429rem 0.28571429rem 0em 0em;
}

.ui.attached.bottom.button {
  border-radius: 0em 0em 0.28571429rem 0.28571429rem;
}

/* Left / Right */

.ui.left.attached.button {
  display: inline-block;
  border-left: none;
  text-align: right;
  padding-right: 0.75em;
  border-radius: 0.28571429rem 0em 0em 0.28571429rem;
}

.ui.right.attached.button {
  display: inline-block;
  text-align: left;
  padding-left: 0.75em;
  border-radius: 0em 0.28571429rem 0.28571429rem 0em;
}

/* Plural */

.ui.attached.buttons {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 0em;
  width: auto !important;
  z-index: 2;
  margin-left: -1px;
  margin-right: -1px;
}

.ui.attached.buttons .button {
  margin: 0em;
}

.ui.attached.buttons .button:first-child {
  border-radius: 0em;
}

.ui.attached.buttons .button:last-child {
  border-radius: 0em;
}

/* Top / Bottom */

.ui[class*="top attached"].buttons {
  margin-bottom: -1px;
  border-radius: 0.28571429rem 0.28571429rem 0em 0em;
}

.ui[class*="top attached"].buttons .button:first-child {
  border-radius: 0.28571429rem 0em 0em 0em;
}

.ui[class*="top attached"].buttons .button:last-child {
  border-radius: 0em 0.28571429rem 0em 0em;
}

.ui[class*="bottom attached"].buttons {
  margin-top: -1px;
  border-radius: 0em 0em 0.28571429rem 0.28571429rem;
}

.ui[class*="bottom attached"].buttons .button:first-child {
  border-radius: 0em 0em 0em 0.28571429rem;
}

.ui[class*="bottom attached"].buttons .button:last-child {
  border-radius: 0em 0em 0.28571429rem 0em;
}

/* Left / Right */

.ui[class*="left attached"].buttons {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 0em;
  margin-left: -1px;
  border-radius: 0em 0.28571429rem 0.28571429rem 0em;
}

.ui[class*="left attached"].buttons .button:first-child {
  margin-left: -1px;
  border-radius: 0em 0.28571429rem 0em 0em;
}

.ui[class*="left attached"].buttons .button:last-child {
  margin-left: -1px;
  border-radius: 0em 0em 0.28571429rem 0em;
}

.ui[class*="right attached"].buttons {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 0em;
  margin-right: -1px;
  border-radius: 0.28571429rem 0em 0em 0.28571429rem;
}

.ui[class*="right attached"].buttons .button:first-child {
  margin-left: -1px;
  border-radius: 0.28571429rem 0em 0em 0em;
}

.ui[class*="right attached"].buttons .button:last-child {
  margin-left: -1px;
  border-radius: 0em 0em 0em 0.28571429rem;
}

/*-------------------
        Fluid
--------------------*/

.ui.fluid.buttons,
.ui.fluid.button {
  width: 100%;
}

.ui.fluid.button {
  display: block;
}

.ui.two.buttons {
  width: 100%;
}

.ui.two.buttons > .button {
  width: 50%;
}

.ui.three.buttons {
  width: 100%;
}

.ui.three.buttons > .button {
  width: 33.333%;
}

.ui.four.buttons {
  width: 100%;
}

.ui.four.buttons > .button {
  width: 25%;
}

.ui.five.buttons {
  width: 100%;
}

.ui.five.buttons > .button {
  width: 20%;
}

.ui.six.buttons {
  width: 100%;
}

.ui.six.buttons > .button {
  width: 16.666%;
}

.ui.seven.buttons {
  width: 100%;
}

.ui.seven.buttons > .button {
  width: 14.285%;
}

.ui.eight.buttons {
  width: 100%;
}

.ui.eight.buttons > .button {
  width: 12.5%;
}

.ui.nine.buttons {
  width: 100%;
}

.ui.nine.buttons > .button {
  width: 11.11%;
}

.ui.ten.buttons {
  width: 100%;
}

.ui.ten.buttons > .button {
  width: 10%;
}

.ui.eleven.buttons {
  width: 100%;
}

.ui.eleven.buttons > .button {
  width: 9.09%;
}

.ui.twelve.buttons {
  width: 100%;
}

.ui.twelve.buttons > .button {
  width: 8.3333%;
}

/* Fluid Vertical Buttons */

.ui.fluid.vertical.buttons,
.ui.fluid.vertical.buttons > .button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

.ui.two.vertical.buttons > .button {
  height: 50%;
}

.ui.three.vertical.buttons > .button {
  height: 33.333%;
}

.ui.four.vertical.buttons > .button {
  height: 25%;
}

.ui.five.vertical.buttons > .button {
  height: 20%;
}

.ui.six.vertical.buttons > .button {
  height: 16.666%;
}

.ui.seven.vertical.buttons > .button {
  height: 14.285%;
}

.ui.eight.vertical.buttons > .button {
  height: 12.5%;
}

.ui.nine.vertical.buttons > .button {
  height: 11.11%;
}

.ui.ten.vertical.buttons > .button {
  height: 10%;
}

.ui.eleven.vertical.buttons > .button {
  height: 9.09%;
}

.ui.twelve.vertical.buttons > .button {
  height: 8.3333%;
}

/*-------------------
       Colors
--------------------*/

/*--- Black ---*/

.ui.black.buttons .button,
.ui.black.button {
  background-color: #1b1c1d;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.black.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.black.buttons .button:hover,
.ui.black.button:hover {
  background-color: #27292a;
  color: $white;
  text-shadow: none;
}

.ui.black.buttons .button:focus,
.ui.black.button:focus {
  background-color: #2f3032;
  color: $white;
  text-shadow: none;
}

.ui.black.buttons .button:active,
.ui.black.button:active {
  background-color: #343637;
  color: $white;
  text-shadow: none;
}

.ui.black.buttons .active.button,
.ui.black.buttons .active.button:active,
.ui.black.active.button,
.ui.black.button .active.button:active {
  background-color: #0f0f10;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.black.buttons .button,
.ui.basic.black.button {
  -webkit-box-shadow: 0px 0px 0px 1px #1b1c1d inset !important;
  box-shadow: 0px 0px 0px 1px #1b1c1d inset !important;
  color: #1b1c1d !important;
}

.ui.basic.black.buttons .button:hover,
.ui.basic.black.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #27292a inset !important;
  box-shadow: 0px 0px 0px 1px #27292a inset !important;
  color: #27292a !important;
}

.ui.basic.black.buttons .button:focus,
.ui.basic.black.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #2f3032 inset !important;
  box-shadow: 0px 0px 0px 1px #2f3032 inset !important;
  color: #27292a !important;
}

.ui.basic.black.buttons .active.button,
.ui.basic.black.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #0f0f10 inset !important;
  box-shadow: 0px 0px 0px 1px #0f0f10 inset !important;
  color: #343637 !important;
}

.ui.basic.black.buttons .button:active,
.ui.basic.black.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #343637 inset !important;
  box-shadow: 0px 0px 0px 1px #343637 inset !important;
  color: #343637 !important;
}

.ui.buttons:not(.vertical) > .basic.black.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.black.buttons .button,
.ui.inverted.black.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #d4d4d5 inset !important;
  box-shadow: 0px 0px 0px 2px #d4d4d5 inset !important;
  color: $white;
}

.ui.inverted.black.buttons .button:hover,
.ui.inverted.black.button:hover,
.ui.inverted.black.buttons .button:focus,
.ui.inverted.black.button:focus,
.ui.inverted.black.buttons .button.active,
.ui.inverted.black.button.active,
.ui.inverted.black.buttons .button:active,
.ui.inverted.black.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: $white;
}

.ui.inverted.black.buttons .button:hover,
.ui.inverted.black.button:hover {
  background-color: #000000;
}

.ui.inverted.black.buttons .button:focus,
.ui.inverted.black.button:focus {
  background-color: #000000;
}

.ui.inverted.black.buttons .active.button,
.ui.inverted.black.active.button {
  background-color: #000000;
}

.ui.inverted.black.buttons .button:active,
.ui.inverted.black.button:active {
  background-color: #000000;
}

/* Inverted Basic */

.ui.inverted.black.basic.buttons .button,
.ui.inverted.black.buttons .basic.button,
.ui.inverted.black.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.black.basic.buttons .button:hover,
.ui.inverted.black.buttons .basic.button:hover,
.ui.inverted.black.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #000000 inset !important;
  box-shadow: 0px 0px 0px 2px #000000 inset !important;
  color: $white !important;
}

.ui.inverted.black.basic.buttons .button:focus,
.ui.inverted.black.basic.buttons .button:focus,
.ui.inverted.black.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #000000 inset !important;
  box-shadow: 0px 0px 0px 2px #000000 inset !important;
  color: #545454 !important;
}

.ui.inverted.black.basic.buttons .active.button,
.ui.inverted.black.buttons .basic.active.button,
.ui.inverted.black.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #000000 inset !important;
  box-shadow: 0px 0px 0px 2px #000000 inset !important;
  color: $white !important;
}

.ui.inverted.black.basic.buttons .button:active,
.ui.inverted.black.buttons .basic.button:active,
.ui.inverted.black.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #000000 inset !important;
  box-shadow: 0px 0px 0px 2px #000000 inset !important;
  color: $white !important;
}

/*--- Grey ---*/

.ui.grey.buttons .button,
.ui.grey.button {
  background-color: #767676;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.grey.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.grey.buttons .button:hover,
.ui.grey.button:hover {
  background-color: #838383;
  color: $white;
  text-shadow: none;
}

.ui.grey.buttons .button:focus,
.ui.grey.button:focus {
  background-color: #8a8a8a;
  color: $white;
  text-shadow: none;
}

.ui.grey.buttons .button:active,
.ui.grey.button:active {
  background-color: #909090;
  color: $white;
  text-shadow: none;
}

.ui.grey.buttons .active.button,
.ui.grey.buttons .active.button:active,
.ui.grey.active.button,
.ui.grey.button .active.button:active {
  background-color: #696969;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.grey.buttons .button,
.ui.basic.grey.button {
  -webkit-box-shadow: 0px 0px 0px 1px #767676 inset !important;
  box-shadow: 0px 0px 0px 1px #767676 inset !important;
  color: #767676 !important;
}

.ui.basic.grey.buttons .button:hover,
.ui.basic.grey.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #838383 inset !important;
  box-shadow: 0px 0px 0px 1px #838383 inset !important;
  color: #838383 !important;
}

.ui.basic.grey.buttons .button:focus,
.ui.basic.grey.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #8a8a8a inset !important;
  box-shadow: 0px 0px 0px 1px #8a8a8a inset !important;
  color: #838383 !important;
}

.ui.basic.grey.buttons .active.button,
.ui.basic.grey.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #696969 inset !important;
  box-shadow: 0px 0px 0px 1px #696969 inset !important;
  color: #909090 !important;
}

.ui.basic.grey.buttons .button:active,
.ui.basic.grey.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #909090 inset !important;
  box-shadow: 0px 0px 0px 1px #909090 inset !important;
  color: #909090 !important;
}

.ui.buttons:not(.vertical) > .basic.grey.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.grey.buttons .button,
.ui.inverted.grey.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #d4d4d5 inset !important;
  box-shadow: 0px 0px 0px 2px #d4d4d5 inset !important;
  color: $white;
}

.ui.inverted.grey.buttons .button:hover,
.ui.inverted.grey.button:hover,
.ui.inverted.grey.buttons .button:focus,
.ui.inverted.grey.button:focus,
.ui.inverted.grey.buttons .button.active,
.ui.inverted.grey.button.active,
.ui.inverted.grey.buttons .button:active,
.ui.inverted.grey.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.6);
}

.ui.inverted.grey.buttons .button:hover,
.ui.inverted.grey.button:hover {
  background-color: #cfd0d2;
}

.ui.inverted.grey.buttons .button:focus,
.ui.inverted.grey.button:focus {
  background-color: #c7c9cb;
}

.ui.inverted.grey.buttons .active.button,
.ui.inverted.grey.active.button {
  background-color: #cfd0d2;
}

.ui.inverted.grey.buttons .button:active,
.ui.inverted.grey.button:active {
  background-color: #c2c4c5;
}

/* Inverted Basic */

.ui.inverted.grey.basic.buttons .button,
.ui.inverted.grey.buttons .basic.button,
.ui.inverted.grey.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.grey.basic.buttons .button:hover,
.ui.inverted.grey.buttons .basic.button:hover,
.ui.inverted.grey.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #cfd0d2 inset !important;
  box-shadow: 0px 0px 0px 2px #cfd0d2 inset !important;
  color: $white !important;
}

.ui.inverted.grey.basic.buttons .button:focus,
.ui.inverted.grey.basic.buttons .button:focus,
.ui.inverted.grey.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #c7c9cb inset !important;
  box-shadow: 0px 0px 0px 2px #c7c9cb inset !important;
  color: #dcddde !important;
}

.ui.inverted.grey.basic.buttons .active.button,
.ui.inverted.grey.buttons .basic.active.button,
.ui.inverted.grey.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #cfd0d2 inset !important;
  box-shadow: 0px 0px 0px 2px #cfd0d2 inset !important;
  color: $white !important;
}

.ui.inverted.grey.basic.buttons .button:active,
.ui.inverted.grey.buttons .basic.button:active,
.ui.inverted.grey.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #c2c4c5 inset !important;
  box-shadow: 0px 0px 0px 2px #c2c4c5 inset !important;
  color: $white !important;
}

/*--- Brown ---*/

.ui.brown.buttons .button,
.ui.brown.button {
  background-color: #a5673f;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.brown.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.brown.buttons .button:hover,
.ui.brown.button:hover {
  background-color: #975b33;
  color: $white;
  text-shadow: none;
}

.ui.brown.buttons .button:focus,
.ui.brown.button:focus {
  background-color: #90532b;
  color: $white;
  text-shadow: none;
}

.ui.brown.buttons .button:active,
.ui.brown.button:active {
  background-color: #805031;
  color: $white;
  text-shadow: none;
}

.ui.brown.buttons .active.button,
.ui.brown.buttons .active.button:active,
.ui.brown.active.button,
.ui.brown.button .active.button:active {
  background-color: #995a31;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.brown.buttons .button,
.ui.basic.brown.button {
  -webkit-box-shadow: 0px 0px 0px 1px #a5673f inset !important;
  box-shadow: 0px 0px 0px 1px #a5673f inset !important;
  color: #a5673f !important;
}

.ui.basic.brown.buttons .button:hover,
.ui.basic.brown.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #975b33 inset !important;
  box-shadow: 0px 0px 0px 1px #975b33 inset !important;
  color: #975b33 !important;
}

.ui.basic.brown.buttons .button:focus,
.ui.basic.brown.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #90532b inset !important;
  box-shadow: 0px 0px 0px 1px #90532b inset !important;
  color: #975b33 !important;
}

.ui.basic.brown.buttons .active.button,
.ui.basic.brown.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #995a31 inset !important;
  box-shadow: 0px 0px 0px 1px #995a31 inset !important;
  color: #805031 !important;
}

.ui.basic.brown.buttons .button:active,
.ui.basic.brown.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #805031 inset !important;
  box-shadow: 0px 0px 0px 1px #805031 inset !important;
  color: #805031 !important;
}

.ui.buttons:not(.vertical) > .basic.brown.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.brown.buttons .button,
.ui.inverted.brown.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #d67c1c inset !important;
  box-shadow: 0px 0px 0px 2px #d67c1c inset !important;
  color: #d67c1c;
}

.ui.inverted.brown.buttons .button:hover,
.ui.inverted.brown.button:hover,
.ui.inverted.brown.buttons .button:focus,
.ui.inverted.brown.button:focus,
.ui.inverted.brown.buttons .button.active,
.ui.inverted.brown.button.active,
.ui.inverted.brown.buttons .button:active,
.ui.inverted.brown.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: $white;
}

.ui.inverted.brown.buttons .button:hover,
.ui.inverted.brown.button:hover {
  background-color: #c86f11;
}

.ui.inverted.brown.buttons .button:focus,
.ui.inverted.brown.button:focus {
  background-color: #c16808;
}

.ui.inverted.brown.buttons .active.button,
.ui.inverted.brown.active.button {
  background-color: #cc6f0d;
}

.ui.inverted.brown.buttons .button:active,
.ui.inverted.brown.button:active {
  background-color: #a96216;
}

/* Inverted Basic */

.ui.inverted.brown.basic.buttons .button,
.ui.inverted.brown.buttons .basic.button,
.ui.inverted.brown.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.brown.basic.buttons .button:hover,
.ui.inverted.brown.buttons .basic.button:hover,
.ui.inverted.brown.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #c86f11 inset !important;
  box-shadow: 0px 0px 0px 2px #c86f11 inset !important;
  color: #d67c1c !important;
}

.ui.inverted.brown.basic.buttons .button:focus,
.ui.inverted.brown.basic.buttons .button:focus,
.ui.inverted.brown.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #c16808 inset !important;
  box-shadow: 0px 0px 0px 2px #c16808 inset !important;
  color: #d67c1c !important;
}

.ui.inverted.brown.basic.buttons .active.button,
.ui.inverted.brown.buttons .basic.active.button,
.ui.inverted.brown.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #cc6f0d inset !important;
  box-shadow: 0px 0px 0px 2px #cc6f0d inset !important;
  color: #d67c1c !important;
}

.ui.inverted.brown.basic.buttons .button:active,
.ui.inverted.brown.buttons .basic.button:active,
.ui.inverted.brown.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #a96216 inset !important;
  box-shadow: 0px 0px 0px 2px #a96216 inset !important;
  color: #d67c1c !important;
}

/*--- Blue ---*/

.ui.blue.buttons .button,
.ui.blue.button {
  background-color: $blue;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.blue.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.blue.buttons .button:hover,
.ui.blue.button:hover {
  background-color: #1678c2;
  color: $white;
  text-shadow: none;
}

.ui.blue.buttons .button:focus,
.ui.blue.button:focus {
  background-color: #0d71bb;
  color: $white;
  text-shadow: none;
}

.ui.blue.buttons .button:active,
.ui.blue.button:active {
  background-color: #1a69a4;
  color: $white;
  text-shadow: none;
}

.ui.blue.buttons .active.button,
.ui.blue.buttons .active.button:active,
.ui.blue.active.button,
.ui.blue.button .active.button:active {
  background-color: #1279c6;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.blue.buttons .button,
.ui.basic.blue.button {
  -webkit-box-shadow: 0px 0px 0px 1px $blue inset !important;
  box-shadow: 0px 0px 0px 1px $blue inset !important;
  color: $blue !important;
}

.ui.basic.blue.buttons .button:hover,
.ui.basic.blue.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #1678c2 inset !important;
  box-shadow: 0px 0px 0px 1px #1678c2 inset !important;
  color: #1678c2 !important;
}

.ui.basic.blue.buttons .button:focus,
.ui.basic.blue.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #0d71bb inset !important;
  box-shadow: 0px 0px 0px 1px #0d71bb inset !important;
  color: #1678c2 !important;
}

.ui.basic.blue.buttons .active.button,
.ui.basic.blue.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #1279c6 inset !important;
  box-shadow: 0px 0px 0px 1px #1279c6 inset !important;
  color: #1a69a4 !important;
}

.ui.basic.blue.buttons .button:active,
.ui.basic.blue.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #1a69a4 inset !important;
  box-shadow: 0px 0px 0px 1px #1a69a4 inset !important;
  color: #1a69a4 !important;
}

.ui.buttons:not(.vertical) > .basic.blue.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.blue.buttons .button,
.ui.inverted.blue.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #54c8ff inset !important;
  box-shadow: 0px 0px 0px 2px #54c8ff inset !important;
  color: #54c8ff;
}

.ui.inverted.blue.buttons .button:hover,
.ui.inverted.blue.button:hover,
.ui.inverted.blue.buttons .button:focus,
.ui.inverted.blue.button:focus,
.ui.inverted.blue.buttons .button.active,
.ui.inverted.blue.button.active,
.ui.inverted.blue.buttons .button:active,
.ui.inverted.blue.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: $white;
}

.ui.inverted.blue.buttons .button:hover,
.ui.inverted.blue.button:hover {
  background-color: #3ac0ff;
}

.ui.inverted.blue.buttons .button:focus,
.ui.inverted.blue.button:focus {
  background-color: #2bbbff;
}

.ui.inverted.blue.buttons .active.button,
.ui.inverted.blue.active.button {
  background-color: #3ac0ff;
}

.ui.inverted.blue.buttons .button:active,
.ui.inverted.blue.button:active {
  background-color: #21b8ff;
}

/* Inverted Basic */

.ui.inverted.blue.basic.buttons .button,
.ui.inverted.blue.buttons .basic.button,
.ui.inverted.blue.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.blue.basic.buttons .button:hover,
.ui.inverted.blue.buttons .basic.button:hover,
.ui.inverted.blue.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #3ac0ff inset !important;
  box-shadow: 0px 0px 0px 2px #3ac0ff inset !important;
  color: #54c8ff !important;
}

.ui.inverted.blue.basic.buttons .button:focus,
.ui.inverted.blue.basic.buttons .button:focus,
.ui.inverted.blue.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #2bbbff inset !important;
  box-shadow: 0px 0px 0px 2px #2bbbff inset !important;
  color: #54c8ff !important;
}

.ui.inverted.blue.basic.buttons .active.button,
.ui.inverted.blue.buttons .basic.active.button,
.ui.inverted.blue.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #3ac0ff inset !important;
  box-shadow: 0px 0px 0px 2px #3ac0ff inset !important;
  color: #54c8ff !important;
}

.ui.inverted.blue.basic.buttons .button:active,
.ui.inverted.blue.buttons .basic.button:active,
.ui.inverted.blue.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #21b8ff inset !important;
  box-shadow: 0px 0px 0px 2px #21b8ff inset !important;
  color: #54c8ff !important;
}

/*--- Green ---*/

.ui.green.buttons .button,
.ui.green.button {
  background-color: $green;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.green.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.green.buttons .button:hover,
.ui.green.button:hover {
  background-color: #16ab39;
  color: $white;
  text-shadow: none;
}

.ui.green.buttons .button:focus,
.ui.green.button:focus {
  background-color: #0ea432;
  color: $white;
  text-shadow: none;
}

.ui.green.buttons .button:active,
.ui.green.button:active {
  background-color: #198f35;
  color: $white;
  text-shadow: none;
}

.ui.green.buttons .active.button,
.ui.green.buttons .active.button:active,
.ui.green.active.button,
.ui.green.button .active.button:active {
  background-color: #13ae38;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.green.buttons .button,
.ui.basic.green.button {
  -webkit-box-shadow: 0px 0px 0px 1px $green inset !important;
  box-shadow: 0px 0px 0px 1px $green inset !important;
  color: $green !important;
}

.ui.basic.green.buttons .button:hover,
.ui.basic.green.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #16ab39 inset !important;
  box-shadow: 0px 0px 0px 1px #16ab39 inset !important;
  color: #16ab39 !important;
}

.ui.basic.green.buttons .button:focus,
.ui.basic.green.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #0ea432 inset !important;
  box-shadow: 0px 0px 0px 1px #0ea432 inset !important;
  color: #16ab39 !important;
}

.ui.basic.green.buttons .active.button,
.ui.basic.green.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #13ae38 inset !important;
  box-shadow: 0px 0px 0px 1px #13ae38 inset !important;
  color: #198f35 !important;
}

.ui.basic.green.buttons .button:active,
.ui.basic.green.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #198f35 inset !important;
  box-shadow: 0px 0px 0px 1px #198f35 inset !important;
  color: #198f35 !important;
}

.ui.buttons:not(.vertical) > .basic.green.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.green.buttons .button,
.ui.inverted.green.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #2ecc40 inset !important;
  box-shadow: 0px 0px 0px 2px #2ecc40 inset !important;
  color: #2ecc40;
}

.ui.inverted.green.buttons .button:hover,
.ui.inverted.green.button:hover,
.ui.inverted.green.buttons .button:focus,
.ui.inverted.green.button:focus,
.ui.inverted.green.buttons .button.active,
.ui.inverted.green.button.active,
.ui.inverted.green.buttons .button:active,
.ui.inverted.green.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: $white;
}

.ui.inverted.green.buttons .button:hover,
.ui.inverted.green.button:hover {
  background-color: #22be34;
}

.ui.inverted.green.buttons .button:focus,
.ui.inverted.green.button:focus {
  background-color: #19b82b;
}

.ui.inverted.green.buttons .active.button,
.ui.inverted.green.active.button {
  background-color: #1fc231;
}

.ui.inverted.green.buttons .button:active,
.ui.inverted.green.button:active {
  background-color: #25a233;
}

/* Inverted Basic */

.ui.inverted.green.basic.buttons .button,
.ui.inverted.green.buttons .basic.button,
.ui.inverted.green.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.green.basic.buttons .button:hover,
.ui.inverted.green.buttons .basic.button:hover,
.ui.inverted.green.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #22be34 inset !important;
  box-shadow: 0px 0px 0px 2px #22be34 inset !important;
  color: #2ecc40 !important;
}

.ui.inverted.green.basic.buttons .button:focus,
.ui.inverted.green.basic.buttons .button:focus,
.ui.inverted.green.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #19b82b inset !important;
  box-shadow: 0px 0px 0px 2px #19b82b inset !important;
  color: #2ecc40 !important;
}

.ui.inverted.green.basic.buttons .active.button,
.ui.inverted.green.buttons .basic.active.button,
.ui.inverted.green.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #1fc231 inset !important;
  box-shadow: 0px 0px 0px 2px #1fc231 inset !important;
  color: #2ecc40 !important;
}

.ui.inverted.green.basic.buttons .button:active,
.ui.inverted.green.buttons .basic.button:active,
.ui.inverted.green.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #25a233 inset !important;
  box-shadow: 0px 0px 0px 2px #25a233 inset !important;
  color: #2ecc40 !important;
}

/*--- Orange ---*/

.ui.orange.buttons .button,
.ui.orange.button {
  background-color: #f2711c;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.orange.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.orange.buttons .button:hover,
.ui.orange.button:hover {
  background-color: #f26202;
  color: $white;
  text-shadow: none;
}

.ui.orange.buttons .button:focus,
.ui.orange.button:focus {
  background-color: #e55b00;
  color: $white;
  text-shadow: none;
}

.ui.orange.buttons .button:active,
.ui.orange.button:active {
  background-color: #cf590c;
  color: $white;
  text-shadow: none;
}

.ui.orange.buttons .active.button,
.ui.orange.buttons .active.button:active,
.ui.orange.active.button,
.ui.orange.button .active.button:active {
  background-color: #f56100;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.orange.buttons .button,
.ui.basic.orange.button {
  -webkit-box-shadow: 0px 0px 0px 1px #f2711c inset !important;
  box-shadow: 0px 0px 0px 1px #f2711c inset !important;
  color: #f2711c !important;
}

.ui.basic.orange.buttons .button:hover,
.ui.basic.orange.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #f26202 inset !important;
  box-shadow: 0px 0px 0px 1px #f26202 inset !important;
  color: #f26202 !important;
}

.ui.basic.orange.buttons .button:focus,
.ui.basic.orange.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #e55b00 inset !important;
  box-shadow: 0px 0px 0px 1px #e55b00 inset !important;
  color: #f26202 !important;
}

.ui.basic.orange.buttons .active.button,
.ui.basic.orange.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #f56100 inset !important;
  box-shadow: 0px 0px 0px 1px #f56100 inset !important;
  color: #cf590c !important;
}

.ui.basic.orange.buttons .button:active,
.ui.basic.orange.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #cf590c inset !important;
  box-shadow: 0px 0px 0px 1px #cf590c inset !important;
  color: #cf590c !important;
}

.ui.buttons:not(.vertical) > .basic.orange.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.orange.buttons .button,
.ui.inverted.orange.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #ff851b inset !important;
  box-shadow: 0px 0px 0px 2px #ff851b inset !important;
  color: #ff851b;
}

.ui.inverted.orange.buttons .button:hover,
.ui.inverted.orange.button:hover,
.ui.inverted.orange.buttons .button:focus,
.ui.inverted.orange.button:focus,
.ui.inverted.orange.buttons .button.active,
.ui.inverted.orange.button.active,
.ui.inverted.orange.buttons .button:active,
.ui.inverted.orange.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: $white;
}

.ui.inverted.orange.buttons .button:hover,
.ui.inverted.orange.button:hover {
  background-color: #ff7701;
}

.ui.inverted.orange.buttons .button:focus,
.ui.inverted.orange.button:focus {
  background-color: #f17000;
}

.ui.inverted.orange.buttons .active.button,
.ui.inverted.orange.active.button {
  background-color: #ff7701;
}

.ui.inverted.orange.buttons .button:active,
.ui.inverted.orange.button:active {
  background-color: #e76b00;
}

/* Inverted Basic */

.ui.inverted.orange.basic.buttons .button,
.ui.inverted.orange.buttons .basic.button,
.ui.inverted.orange.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.orange.basic.buttons .button:hover,
.ui.inverted.orange.buttons .basic.button:hover,
.ui.inverted.orange.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #ff7701 inset !important;
  box-shadow: 0px 0px 0px 2px #ff7701 inset !important;
  color: #ff851b !important;
}

.ui.inverted.orange.basic.buttons .button:focus,
.ui.inverted.orange.basic.buttons .button:focus,
.ui.inverted.orange.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #f17000 inset !important;
  box-shadow: 0px 0px 0px 2px #f17000 inset !important;
  color: #ff851b !important;
}

.ui.inverted.orange.basic.buttons .active.button,
.ui.inverted.orange.buttons .basic.active.button,
.ui.inverted.orange.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #ff7701 inset !important;
  box-shadow: 0px 0px 0px 2px #ff7701 inset !important;
  color: #ff851b !important;
}

.ui.inverted.orange.basic.buttons .button:active,
.ui.inverted.orange.buttons .basic.button:active,
.ui.inverted.orange.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #e76b00 inset !important;
  box-shadow: 0px 0px 0px 2px #e76b00 inset !important;
  color: #ff851b !important;
}

/*--- Pink ---*/

.ui.pink.buttons .button,
.ui.pink.button {
  background-color: #e03997;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.pink.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.pink.buttons .button:hover,
.ui.pink.button:hover {
  background-color: #e61a8d;
  color: $white;
  text-shadow: none;
}

.ui.pink.buttons .button:focus,
.ui.pink.button:focus {
  background-color: #e10f85;
  color: $white;
  text-shadow: none;
}

.ui.pink.buttons .button:active,
.ui.pink.button:active {
  background-color: #c71f7e;
  color: $white;
  text-shadow: none;
}

.ui.pink.buttons .active.button,
.ui.pink.buttons .active.button:active,
.ui.pink.active.button,
.ui.pink.button .active.button:active {
  background-color: #ea158d;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.pink.buttons .button,
.ui.basic.pink.button {
  -webkit-box-shadow: 0px 0px 0px 1px #e03997 inset !important;
  box-shadow: 0px 0px 0px 1px #e03997 inset !important;
  color: #e03997 !important;
}

.ui.basic.pink.buttons .button:hover,
.ui.basic.pink.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #e61a8d inset !important;
  box-shadow: 0px 0px 0px 1px #e61a8d inset !important;
  color: #e61a8d !important;
}

.ui.basic.pink.buttons .button:focus,
.ui.basic.pink.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #e10f85 inset !important;
  box-shadow: 0px 0px 0px 1px #e10f85 inset !important;
  color: #e61a8d !important;
}

.ui.basic.pink.buttons .active.button,
.ui.basic.pink.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #ea158d inset !important;
  box-shadow: 0px 0px 0px 1px #ea158d inset !important;
  color: #c71f7e !important;
}

.ui.basic.pink.buttons .button:active,
.ui.basic.pink.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #c71f7e inset !important;
  box-shadow: 0px 0px 0px 1px #c71f7e inset !important;
  color: #c71f7e !important;
}

.ui.buttons:not(.vertical) > .basic.pink.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.pink.buttons .button,
.ui.inverted.pink.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #ff8edf inset !important;
  box-shadow: 0px 0px 0px 2px #ff8edf inset !important;
  color: #ff8edf;
}

.ui.inverted.pink.buttons .button:hover,
.ui.inverted.pink.button:hover,
.ui.inverted.pink.buttons .button:focus,
.ui.inverted.pink.button:focus,
.ui.inverted.pink.buttons .button.active,
.ui.inverted.pink.button.active,
.ui.inverted.pink.buttons .button:active,
.ui.inverted.pink.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: $white;
}

.ui.inverted.pink.buttons .button:hover,
.ui.inverted.pink.button:hover {
  background-color: #ff74d8;
}

.ui.inverted.pink.buttons .button:focus,
.ui.inverted.pink.button:focus {
  background-color: #ff65d3;
}

.ui.inverted.pink.buttons .active.button,
.ui.inverted.pink.active.button {
  background-color: #ff74d8;
}

.ui.inverted.pink.buttons .button:active,
.ui.inverted.pink.button:active {
  background-color: #ff5bd1;
}

/* Inverted Basic */

.ui.inverted.pink.basic.buttons .button,
.ui.inverted.pink.buttons .basic.button,
.ui.inverted.pink.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.pink.basic.buttons .button:hover,
.ui.inverted.pink.buttons .basic.button:hover,
.ui.inverted.pink.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #ff74d8 inset !important;
  box-shadow: 0px 0px 0px 2px #ff74d8 inset !important;
  color: #ff8edf !important;
}

.ui.inverted.pink.basic.buttons .button:focus,
.ui.inverted.pink.basic.buttons .button:focus,
.ui.inverted.pink.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #ff65d3 inset !important;
  box-shadow: 0px 0px 0px 2px #ff65d3 inset !important;
  color: #ff8edf !important;
}

.ui.inverted.pink.basic.buttons .active.button,
.ui.inverted.pink.buttons .basic.active.button,
.ui.inverted.pink.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #ff74d8 inset !important;
  box-shadow: 0px 0px 0px 2px #ff74d8 inset !important;
  color: #ff8edf !important;
}

.ui.inverted.pink.basic.buttons .button:active,
.ui.inverted.pink.buttons .basic.button:active,
.ui.inverted.pink.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #ff5bd1 inset !important;
  box-shadow: 0px 0px 0px 2px #ff5bd1 inset !important;
  color: #ff8edf !important;
}

/*--- Violet ---*/

.ui.violet.buttons .button,
.ui.violet.button {
  background-color: #6435c9;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.violet.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.violet.buttons .button:hover,
.ui.violet.button:hover {
  background-color: #5829bb;
  color: $white;
  text-shadow: none;
}

.ui.violet.buttons .button:focus,
.ui.violet.button:focus {
  background-color: #4f20b5;
  color: $white;
  text-shadow: none;
}

.ui.violet.buttons .button:active,
.ui.violet.button:active {
  background-color: #502aa1;
  color: $white;
  text-shadow: none;
}

.ui.violet.buttons .active.button,
.ui.violet.buttons .active.button:active,
.ui.violet.active.button,
.ui.violet.button .active.button:active {
  background-color: #5626bf;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.violet.buttons .button,
.ui.basic.violet.button {
  -webkit-box-shadow: 0px 0px 0px 1px #6435c9 inset !important;
  box-shadow: 0px 0px 0px 1px #6435c9 inset !important;
  color: #6435c9 !important;
}

.ui.basic.violet.buttons .button:hover,
.ui.basic.violet.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #5829bb inset !important;
  box-shadow: 0px 0px 0px 1px #5829bb inset !important;
  color: #5829bb !important;
}

.ui.basic.violet.buttons .button:focus,
.ui.basic.violet.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #4f20b5 inset !important;
  box-shadow: 0px 0px 0px 1px #4f20b5 inset !important;
  color: #5829bb !important;
}

.ui.basic.violet.buttons .active.button,
.ui.basic.violet.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #5626bf inset !important;
  box-shadow: 0px 0px 0px 1px #5626bf inset !important;
  color: #502aa1 !important;
}

.ui.basic.violet.buttons .button:active,
.ui.basic.violet.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #502aa1 inset !important;
  box-shadow: 0px 0px 0px 1px #502aa1 inset !important;
  color: #502aa1 !important;
}

.ui.buttons:not(.vertical) > .basic.violet.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.violet.buttons .button,
.ui.inverted.violet.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #a291fb inset !important;
  box-shadow: 0px 0px 0px 2px #a291fb inset !important;
  color: #a291fb;
}

.ui.inverted.violet.buttons .button:hover,
.ui.inverted.violet.button:hover,
.ui.inverted.violet.buttons .button:focus,
.ui.inverted.violet.button:focus,
.ui.inverted.violet.buttons .button.active,
.ui.inverted.violet.button.active,
.ui.inverted.violet.buttons .button:active,
.ui.inverted.violet.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: $white;
}

.ui.inverted.violet.buttons .button:hover,
.ui.inverted.violet.button:hover {
  background-color: #8a73ff;
}

.ui.inverted.violet.buttons .button:focus,
.ui.inverted.violet.button:focus {
  background-color: #7d64ff;
}

.ui.inverted.violet.buttons .active.button,
.ui.inverted.violet.active.button {
  background-color: #8a73ff;
}

.ui.inverted.violet.buttons .button:active,
.ui.inverted.violet.button:active {
  background-color: #7860f9;
}

/* Inverted Basic */

.ui.inverted.violet.basic.buttons .button,
.ui.inverted.violet.buttons .basic.button,
.ui.inverted.violet.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.violet.basic.buttons .button:hover,
.ui.inverted.violet.buttons .basic.button:hover,
.ui.inverted.violet.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #8a73ff inset !important;
  box-shadow: 0px 0px 0px 2px #8a73ff inset !important;
  color: #a291fb !important;
}

.ui.inverted.violet.basic.buttons .button:focus,
.ui.inverted.violet.basic.buttons .button:focus,
.ui.inverted.violet.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #7d64ff inset !important;
  box-shadow: 0px 0px 0px 2px #7d64ff inset !important;
  color: #a291fb !important;
}

.ui.inverted.violet.basic.buttons .active.button,
.ui.inverted.violet.buttons .basic.active.button,
.ui.inverted.violet.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #8a73ff inset !important;
  box-shadow: 0px 0px 0px 2px #8a73ff inset !important;
  color: #a291fb !important;
}

.ui.inverted.violet.basic.buttons .button:active,
.ui.inverted.violet.buttons .basic.button:active,
.ui.inverted.violet.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #7860f9 inset !important;
  box-shadow: 0px 0px 0px 2px #7860f9 inset !important;
  color: #a291fb !important;
}

/*--- Purple ---*/

.ui.purple.buttons .button,
.ui.purple.button {
  background-color: #a333c8;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.purple.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.purple.buttons .button:hover,
.ui.purple.button:hover {
  background-color: #9627ba;
  color: $white;
  text-shadow: none;
}

.ui.purple.buttons .button:focus,
.ui.purple.button:focus {
  background-color: #8f1eb4;
  color: $white;
  text-shadow: none;
}

.ui.purple.buttons .button:active,
.ui.purple.button:active {
  background-color: #82299f;
  color: $white;
  text-shadow: none;
}

.ui.purple.buttons .active.button,
.ui.purple.buttons .active.button:active,
.ui.purple.active.button,
.ui.purple.button .active.button:active {
  background-color: #9724be;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.purple.buttons .button,
.ui.basic.purple.button {
  -webkit-box-shadow: 0px 0px 0px 1px #a333c8 inset !important;
  box-shadow: 0px 0px 0px 1px #a333c8 inset !important;
  color: #a333c8 !important;
}

.ui.basic.purple.buttons .button:hover,
.ui.basic.purple.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #9627ba inset !important;
  box-shadow: 0px 0px 0px 1px #9627ba inset !important;
  color: #9627ba !important;
}

.ui.basic.purple.buttons .button:focus,
.ui.basic.purple.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #8f1eb4 inset !important;
  box-shadow: 0px 0px 0px 1px #8f1eb4 inset !important;
  color: #9627ba !important;
}

.ui.basic.purple.buttons .active.button,
.ui.basic.purple.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #9724be inset !important;
  box-shadow: 0px 0px 0px 1px #9724be inset !important;
  color: #82299f !important;
}

.ui.basic.purple.buttons .button:active,
.ui.basic.purple.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #82299f inset !important;
  box-shadow: 0px 0px 0px 1px #82299f inset !important;
  color: #82299f !important;
}

.ui.buttons:not(.vertical) > .basic.purple.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.purple.buttons .button,
.ui.inverted.purple.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #dc73ff inset !important;
  box-shadow: 0px 0px 0px 2px #dc73ff inset !important;
  color: #dc73ff;
}

.ui.inverted.purple.buttons .button:hover,
.ui.inverted.purple.button:hover,
.ui.inverted.purple.buttons .button:focus,
.ui.inverted.purple.button:focus,
.ui.inverted.purple.buttons .button.active,
.ui.inverted.purple.button.active,
.ui.inverted.purple.buttons .button:active,
.ui.inverted.purple.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: $white;
}

.ui.inverted.purple.buttons .button:hover,
.ui.inverted.purple.button:hover {
  background-color: #d65aff;
}

.ui.inverted.purple.buttons .button:focus,
.ui.inverted.purple.button:focus {
  background-color: #d24aff;
}

.ui.inverted.purple.buttons .active.button,
.ui.inverted.purple.active.button {
  background-color: #d65aff;
}

.ui.inverted.purple.buttons .button:active,
.ui.inverted.purple.button:active {
  background-color: #cf40ff;
}

/* Inverted Basic */

.ui.inverted.purple.basic.buttons .button,
.ui.inverted.purple.buttons .basic.button,
.ui.inverted.purple.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.purple.basic.buttons .button:hover,
.ui.inverted.purple.buttons .basic.button:hover,
.ui.inverted.purple.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #d65aff inset !important;
  box-shadow: 0px 0px 0px 2px #d65aff inset !important;
  color: #dc73ff !important;
}

.ui.inverted.purple.basic.buttons .button:focus,
.ui.inverted.purple.basic.buttons .button:focus,
.ui.inverted.purple.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #d24aff inset !important;
  box-shadow: 0px 0px 0px 2px #d24aff inset !important;
  color: #dc73ff !important;
}

.ui.inverted.purple.basic.buttons .active.button,
.ui.inverted.purple.buttons .basic.active.button,
.ui.inverted.purple.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #d65aff inset !important;
  box-shadow: 0px 0px 0px 2px #d65aff inset !important;
  color: #dc73ff !important;
}

.ui.inverted.purple.basic.buttons .button:active,
.ui.inverted.purple.buttons .basic.button:active,
.ui.inverted.purple.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #cf40ff inset !important;
  box-shadow: 0px 0px 0px 2px #cf40ff inset !important;
  color: #dc73ff !important;
}

/*--- Red ---*/

.ui.red.buttons .button,
.ui.red.button {
  background-color: #db2828;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.red.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.red.buttons .button:hover,
.ui.red.button:hover {
  background-color: #d01919;
  color: $white;
  text-shadow: none;
}

.ui.red.buttons .button:focus,
.ui.red.button:focus {
  background-color: #ca1010;
  color: $white;
  text-shadow: none;
}

.ui.red.buttons .button:active,
.ui.red.button:active {
  background-color: #b21e1e;
  color: $white;
  text-shadow: none;
}

.ui.red.buttons .active.button,
.ui.red.buttons .active.button:active,
.ui.red.active.button,
.ui.red.button .active.button:active {
  background-color: #d41515;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.red.buttons .button,
.ui.basic.red.button {
  -webkit-box-shadow: 0px 0px 0px 1px #db2828 inset !important;
  box-shadow: 0px 0px 0px 1px #db2828 inset !important;
  color: #db2828 !important;
}

.ui.basic.red.buttons .button:hover,
.ui.basic.red.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #d01919 inset !important;
  box-shadow: 0px 0px 0px 1px #d01919 inset !important;
  color: #d01919 !important;
}

.ui.basic.red.buttons .button:focus,
.ui.basic.red.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #ca1010 inset !important;
  box-shadow: 0px 0px 0px 1px #ca1010 inset !important;
  color: #d01919 !important;
}

.ui.basic.red.buttons .active.button,
.ui.basic.red.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #d41515 inset !important;
  box-shadow: 0px 0px 0px 1px #d41515 inset !important;
  color: #b21e1e !important;
}

.ui.basic.red.buttons .button:active,
.ui.basic.red.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #b21e1e inset !important;
  box-shadow: 0px 0px 0px 1px #b21e1e inset !important;
  color: #b21e1e !important;
}

.ui.buttons:not(.vertical) > .basic.red.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.red.buttons .button,
.ui.inverted.red.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #ff695e inset !important;
  box-shadow: 0px 0px 0px 2px #ff695e inset !important;
  color: #ff695e;
}

.ui.inverted.red.buttons .button:hover,
.ui.inverted.red.button:hover,
.ui.inverted.red.buttons .button:focus,
.ui.inverted.red.button:focus,
.ui.inverted.red.buttons .button.active,
.ui.inverted.red.button.active,
.ui.inverted.red.buttons .button:active,
.ui.inverted.red.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: $white;
}

.ui.inverted.red.buttons .button:hover,
.ui.inverted.red.button:hover {
  background-color: #ff5144;
}

.ui.inverted.red.buttons .button:focus,
.ui.inverted.red.button:focus {
  background-color: #ff4335;
}

.ui.inverted.red.buttons .active.button,
.ui.inverted.red.active.button {
  background-color: #ff5144;
}

.ui.inverted.red.buttons .button:active,
.ui.inverted.red.button:active {
  background-color: #ff392b;
}

/* Inverted Basic */

.ui.inverted.red.basic.buttons .button,
.ui.inverted.red.buttons .basic.button,
.ui.inverted.red.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.red.basic.buttons .button:hover,
.ui.inverted.red.buttons .basic.button:hover,
.ui.inverted.red.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #ff5144 inset !important;
  box-shadow: 0px 0px 0px 2px #ff5144 inset !important;
  color: #ff695e !important;
}

.ui.inverted.red.basic.buttons .button:focus,
.ui.inverted.red.basic.buttons .button:focus,
.ui.inverted.red.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #ff4335 inset !important;
  box-shadow: 0px 0px 0px 2px #ff4335 inset !important;
  color: #ff695e !important;
}

.ui.inverted.red.basic.buttons .active.button,
.ui.inverted.red.buttons .basic.active.button,
.ui.inverted.red.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #ff5144 inset !important;
  box-shadow: 0px 0px 0px 2px #ff5144 inset !important;
  color: #ff695e !important;
}

.ui.inverted.red.basic.buttons .button:active,
.ui.inverted.red.buttons .basic.button:active,
.ui.inverted.red.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #ff392b inset !important;
  box-shadow: 0px 0px 0px 2px #ff392b inset !important;
  color: #ff695e !important;
}

/*--- Teal ---*/

.ui.teal.buttons .button,
.ui.teal.button {
  background-color: #00b5ad;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.teal.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.teal.buttons .button:hover,
.ui.teal.button:hover {
  background-color: #009c95;
  color: $white;
  text-shadow: none;
}

.ui.teal.buttons .button:focus,
.ui.teal.button:focus {
  background-color: #008c86;
  color: $white;
  text-shadow: none;
}

.ui.teal.buttons .button:active,
.ui.teal.button:active {
  background-color: #00827c;
  color: $white;
  text-shadow: none;
}

.ui.teal.buttons .active.button,
.ui.teal.buttons .active.button:active,
.ui.teal.active.button,
.ui.teal.button .active.button:active {
  background-color: #009c95;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.teal.buttons .button,
.ui.basic.teal.button {
  -webkit-box-shadow: 0px 0px 0px 1px #00b5ad inset !important;
  box-shadow: 0px 0px 0px 1px #00b5ad inset !important;
  color: #00b5ad !important;
}

.ui.basic.teal.buttons .button:hover,
.ui.basic.teal.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #009c95 inset !important;
  box-shadow: 0px 0px 0px 1px #009c95 inset !important;
  color: #009c95 !important;
}

.ui.basic.teal.buttons .button:focus,
.ui.basic.teal.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #008c86 inset !important;
  box-shadow: 0px 0px 0px 1px #008c86 inset !important;
  color: #009c95 !important;
}

.ui.basic.teal.buttons .active.button,
.ui.basic.teal.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #009c95 inset !important;
  box-shadow: 0px 0px 0px 1px #009c95 inset !important;
  color: #00827c !important;
}

.ui.basic.teal.buttons .button:active,
.ui.basic.teal.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #00827c inset !important;
  box-shadow: 0px 0px 0px 1px #00827c inset !important;
  color: #00827c !important;
}

.ui.buttons:not(.vertical) > .basic.teal.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.teal.buttons .button,
.ui.inverted.teal.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #6dffff inset !important;
  box-shadow: 0px 0px 0px 2px #6dffff inset !important;
  color: #6dffff;
}

.ui.inverted.teal.buttons .button:hover,
.ui.inverted.teal.button:hover,
.ui.inverted.teal.buttons .button:focus,
.ui.inverted.teal.button:focus,
.ui.inverted.teal.buttons .button.active,
.ui.inverted.teal.button.active,
.ui.inverted.teal.buttons .button:active,
.ui.inverted.teal.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.6);
}

.ui.inverted.teal.buttons .button:hover,
.ui.inverted.teal.button:hover {
  background-color: #54ffff;
}

.ui.inverted.teal.buttons .button:focus,
.ui.inverted.teal.button:focus {
  background-color: #44ffff;
}

.ui.inverted.teal.buttons .active.button,
.ui.inverted.teal.active.button {
  background-color: #54ffff;
}

.ui.inverted.teal.buttons .button:active,
.ui.inverted.teal.button:active {
  background-color: #3affff;
}

/* Inverted Basic */

.ui.inverted.teal.basic.buttons .button,
.ui.inverted.teal.buttons .basic.button,
.ui.inverted.teal.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.teal.basic.buttons .button:hover,
.ui.inverted.teal.buttons .basic.button:hover,
.ui.inverted.teal.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #54ffff inset !important;
  box-shadow: 0px 0px 0px 2px #54ffff inset !important;
  color: #6dffff !important;
}

.ui.inverted.teal.basic.buttons .button:focus,
.ui.inverted.teal.basic.buttons .button:focus,
.ui.inverted.teal.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #44ffff inset !important;
  box-shadow: 0px 0px 0px 2px #44ffff inset !important;
  color: #6dffff !important;
}

.ui.inverted.teal.basic.buttons .active.button,
.ui.inverted.teal.buttons .basic.active.button,
.ui.inverted.teal.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #54ffff inset !important;
  box-shadow: 0px 0px 0px 2px #54ffff inset !important;
  color: #6dffff !important;
}

.ui.inverted.teal.basic.buttons .button:active,
.ui.inverted.teal.buttons .basic.button:active,
.ui.inverted.teal.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #3affff inset !important;
  box-shadow: 0px 0px 0px 2px #3affff inset !important;
  color: #6dffff !important;
}

/*--- Olive ---*/

.ui.olive.buttons .button,
.ui.olive.button {
  background-color: #b5cc18;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.olive.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.olive.buttons .button:hover,
.ui.olive.button:hover {
  background-color: #a7bd0d;
  color: $white;
  text-shadow: none;
}

.ui.olive.buttons .button:focus,
.ui.olive.button:focus {
  background-color: #a0b605;
  color: $white;
  text-shadow: none;
}

.ui.olive.buttons .button:active,
.ui.olive.button:active {
  background-color: #8d9e13;
  color: $white;
  text-shadow: none;
}

.ui.olive.buttons .active.button,
.ui.olive.buttons .active.button:active,
.ui.olive.active.button,
.ui.olive.button .active.button:active {
  background-color: #aac109;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.olive.buttons .button,
.ui.basic.olive.button {
  -webkit-box-shadow: 0px 0px 0px 1px #b5cc18 inset !important;
  box-shadow: 0px 0px 0px 1px #b5cc18 inset !important;
  color: #b5cc18 !important;
}

.ui.basic.olive.buttons .button:hover,
.ui.basic.olive.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #a7bd0d inset !important;
  box-shadow: 0px 0px 0px 1px #a7bd0d inset !important;
  color: #a7bd0d !important;
}

.ui.basic.olive.buttons .button:focus,
.ui.basic.olive.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #a0b605 inset !important;
  box-shadow: 0px 0px 0px 1px #a0b605 inset !important;
  color: #a7bd0d !important;
}

.ui.basic.olive.buttons .active.button,
.ui.basic.olive.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #aac109 inset !important;
  box-shadow: 0px 0px 0px 1px #aac109 inset !important;
  color: #8d9e13 !important;
}

.ui.basic.olive.buttons .button:active,
.ui.basic.olive.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #8d9e13 inset !important;
  box-shadow: 0px 0px 0px 1px #8d9e13 inset !important;
  color: #8d9e13 !important;
}

.ui.buttons:not(.vertical) > .basic.olive.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.olive.buttons .button,
.ui.inverted.olive.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #d9e778 inset !important;
  box-shadow: 0px 0px 0px 2px #d9e778 inset !important;
  color: #d9e778;
}

.ui.inverted.olive.buttons .button:hover,
.ui.inverted.olive.button:hover,
.ui.inverted.olive.buttons .button:focus,
.ui.inverted.olive.button:focus,
.ui.inverted.olive.buttons .button.active,
.ui.inverted.olive.button.active,
.ui.inverted.olive.buttons .button:active,
.ui.inverted.olive.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.6);
}

.ui.inverted.olive.buttons .button:hover,
.ui.inverted.olive.button:hover {
  background-color: #d8ea5c;
}

.ui.inverted.olive.buttons .button:focus,
.ui.inverted.olive.button:focus {
  background-color: #daef47;
}

.ui.inverted.olive.buttons .active.button,
.ui.inverted.olive.active.button {
  background-color: #daed59;
}

.ui.inverted.olive.buttons .button:active,
.ui.inverted.olive.button:active {
  background-color: #cddf4d;
}

/* Inverted Basic */

.ui.inverted.olive.basic.buttons .button,
.ui.inverted.olive.buttons .basic.button,
.ui.inverted.olive.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.olive.basic.buttons .button:hover,
.ui.inverted.olive.buttons .basic.button:hover,
.ui.inverted.olive.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #d8ea5c inset !important;
  box-shadow: 0px 0px 0px 2px #d8ea5c inset !important;
  color: #d9e778 !important;
}

.ui.inverted.olive.basic.buttons .button:focus,
.ui.inverted.olive.basic.buttons .button:focus,
.ui.inverted.olive.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #daef47 inset !important;
  box-shadow: 0px 0px 0px 2px #daef47 inset !important;
  color: #d9e778 !important;
}

.ui.inverted.olive.basic.buttons .active.button,
.ui.inverted.olive.buttons .basic.active.button,
.ui.inverted.olive.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #daed59 inset !important;
  box-shadow: 0px 0px 0px 2px #daed59 inset !important;
  color: #d9e778 !important;
}

.ui.inverted.olive.basic.buttons .button:active,
.ui.inverted.olive.buttons .basic.button:active,
.ui.inverted.olive.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #cddf4d inset !important;
  box-shadow: 0px 0px 0px 2px #cddf4d inset !important;
  color: #d9e778 !important;
}

/*--- Yellow ---*/

.ui.yellow.buttons .button,
.ui.yellow.button {
  background-color: #fbbd08;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.yellow.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.yellow.buttons .button:hover,
.ui.yellow.button:hover {
  background-color: #eaae00;
  color: $white;
  text-shadow: none;
}

.ui.yellow.buttons .button:focus,
.ui.yellow.button:focus {
  background-color: #daa300;
  color: $white;
  text-shadow: none;
}

.ui.yellow.buttons .button:active,
.ui.yellow.button:active {
  background-color: #cd9903;
  color: $white;
  text-shadow: none;
}

.ui.yellow.buttons .active.button,
.ui.yellow.buttons .active.button:active,
.ui.yellow.active.button,
.ui.yellow.button .active.button:active {
  background-color: #eaae00;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.yellow.buttons .button,
.ui.basic.yellow.button {
  -webkit-box-shadow: 0px 0px 0px 1px #fbbd08 inset !important;
  box-shadow: 0px 0px 0px 1px #fbbd08 inset !important;
  color: #fbbd08 !important;
}

.ui.basic.yellow.buttons .button:hover,
.ui.basic.yellow.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #eaae00 inset !important;
  box-shadow: 0px 0px 0px 1px #eaae00 inset !important;
  color: #eaae00 !important;
}

.ui.basic.yellow.buttons .button:focus,
.ui.basic.yellow.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #daa300 inset !important;
  box-shadow: 0px 0px 0px 1px #daa300 inset !important;
  color: #eaae00 !important;
}

.ui.basic.yellow.buttons .active.button,
.ui.basic.yellow.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #eaae00 inset !important;
  box-shadow: 0px 0px 0px 1px #eaae00 inset !important;
  color: #cd9903 !important;
}

.ui.basic.yellow.buttons .button:active,
.ui.basic.yellow.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #cd9903 inset !important;
  box-shadow: 0px 0px 0px 1px #cd9903 inset !important;
  color: #cd9903 !important;
}

.ui.buttons:not(.vertical) > .basic.yellow.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.yellow.buttons .button,
.ui.inverted.yellow.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #ffe21f inset !important;
  box-shadow: 0px 0px 0px 2px #ffe21f inset !important;
  color: #ffe21f;
}

.ui.inverted.yellow.buttons .button:hover,
.ui.inverted.yellow.button:hover,
.ui.inverted.yellow.buttons .button:focus,
.ui.inverted.yellow.button:focus,
.ui.inverted.yellow.buttons .button.active,
.ui.inverted.yellow.button.active,
.ui.inverted.yellow.buttons .button:active,
.ui.inverted.yellow.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.6);
}

.ui.inverted.yellow.buttons .button:hover,
.ui.inverted.yellow.button:hover {
  background-color: #ffdf05;
}

.ui.inverted.yellow.buttons .button:focus,
.ui.inverted.yellow.button:focus {
  background-color: #f5d500;
}

.ui.inverted.yellow.buttons .active.button,
.ui.inverted.yellow.active.button {
  background-color: #ffdf05;
}

.ui.inverted.yellow.buttons .button:active,
.ui.inverted.yellow.button:active {
  background-color: #ebcd00;
}

/* Inverted Basic */

.ui.inverted.yellow.basic.buttons .button,
.ui.inverted.yellow.buttons .basic.button,
.ui.inverted.yellow.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.yellow.basic.buttons .button:hover,
.ui.inverted.yellow.buttons .basic.button:hover,
.ui.inverted.yellow.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #ffdf05 inset !important;
  box-shadow: 0px 0px 0px 2px #ffdf05 inset !important;
  color: #ffe21f !important;
}

.ui.inverted.yellow.basic.buttons .button:focus,
.ui.inverted.yellow.basic.buttons .button:focus,
.ui.inverted.yellow.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #f5d500 inset !important;
  box-shadow: 0px 0px 0px 2px #f5d500 inset !important;
  color: #ffe21f !important;
}

.ui.inverted.yellow.basic.buttons .active.button,
.ui.inverted.yellow.buttons .basic.active.button,
.ui.inverted.yellow.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #ffdf05 inset !important;
  box-shadow: 0px 0px 0px 2px #ffdf05 inset !important;
  color: #ffe21f !important;
}

.ui.inverted.yellow.basic.buttons .button:active,
.ui.inverted.yellow.buttons .basic.button:active,
.ui.inverted.yellow.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #ebcd00 inset !important;
  box-shadow: 0px 0px 0px 2px #ebcd00 inset !important;
  color: #ffe21f !important;
}

/*-------------------
       Primary
--------------------*/

/*--- Standard ---*/

.ui.primary.buttons .button,
.ui.primary.button {
  background-color: rgb($primary);
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.primary.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.primary.buttons .button:hover,
.ui.primary.button:hover {
  background-color: rgb($primaryLight);
  color: $white;
  text-shadow: none;
}

.ui.primary.buttons .button:focus,
.ui.primary.button:focus {
  background-color: #0d71bb;
  color: $white;
  text-shadow: none;
}

.ui.primary.buttons .button:active,
.ui.primary.button:active {
  background-color: rgb($primaryLight);
  color: $white;
  text-shadow: none;
}

.ui.primary.buttons .active.button,
.ui.primary.buttons .active.button:active,
.ui.primary.active.button,
.ui.primary.button .active.button:active {
  background-color: rgb($primaryLight);
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.primary.buttons .button,
.ui.basic.primary.button {
  -webkit-box-shadow: 0px 0px 0px 1px rgb($primary) inset !important;
  box-shadow: 0px 0px 0px 1px rgb($primary) inset !important;
  color: rgb($primary) !important;
}

.ui.basic.primary.buttons .button:hover,
.ui.basic.primary.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px rgb($primaryLight) inset !important;
  box-shadow: 0px 0px 0px 1px rgb($primaryLight) inset !important;
  color: rgb($primaryLight) !important;
}

.ui.basic.primary.buttons .button:focus,
.ui.basic.primary.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #0d71bb inset !important;
  box-shadow: 0px 0px 0px 1px #0d71bb inset !important;
  color: rgb($primaryLight) !important;
}

.ui.basic.primary.buttons .active.button,
.ui.basic.primary.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px rgb($primaryLight) inset !important;
  box-shadow: 0px 0px 0px 1px rgb($primaryLight) inset !important;
  color: rgb($primaryLight) !important;
}

.ui.basic.primary.buttons .button:active,
.ui.basic.primary.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px rgb($primaryLight) inset !important;
  box-shadow: 0px 0px 0px 1px rgb($primaryLight) inset !important;
  color: rgb($primaryLight) !important;
}

.ui.buttons:not(.vertical) > .basic.primary.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.primary.buttons .button,
.ui.inverted.primary.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #54c8ff inset !important;
  box-shadow: 0px 0px 0px 2px #54c8ff inset !important;
  color: #54c8ff;
}

.ui.inverted.primary.buttons .button:hover,
.ui.inverted.primary.button:hover,
.ui.inverted.primary.buttons .button:focus,
.ui.inverted.primary.button:focus,
.ui.inverted.primary.buttons .button.active,
.ui.inverted.primary.button.active,
.ui.inverted.primary.buttons .button:active,
.ui.inverted.primary.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: $white;
}

.ui.inverted.primary.buttons .button:hover,
.ui.inverted.primary.button:hover {
  background-color: #3ac0ff;
}

.ui.inverted.primary.buttons .button:focus,
.ui.inverted.primary.button:focus {
  background-color: #2bbbff;
}

.ui.inverted.primary.buttons .active.button,
.ui.inverted.primary.active.button {
  background-color: #3ac0ff;
}

.ui.inverted.primary.buttons .button:active,
.ui.inverted.primary.button:active {
  background-color: #21b8ff;
}

/* Inverted Basic */

.ui.inverted.primary.basic.buttons .button,
.ui.inverted.primary.buttons .basic.button,
.ui.inverted.primary.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.primary.basic.buttons .button:hover,
.ui.inverted.primary.buttons .basic.button:hover,
.ui.inverted.primary.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #3ac0ff inset !important;
  box-shadow: 0px 0px 0px 2px #3ac0ff inset !important;
  color: #54c8ff !important;
}

.ui.inverted.primary.basic.buttons .button:focus,
.ui.inverted.primary.basic.buttons .button:focus,
.ui.inverted.primary.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #2bbbff inset !important;
  box-shadow: 0px 0px 0px 2px #2bbbff inset !important;
  color: #54c8ff !important;
}

.ui.inverted.primary.basic.buttons .active.button,
.ui.inverted.primary.buttons .basic.active.button,
.ui.inverted.primary.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #3ac0ff inset !important;
  box-shadow: 0px 0px 0px 2px #3ac0ff inset !important;
  color: #54c8ff !important;
}

.ui.inverted.primary.basic.buttons .button:active,
.ui.inverted.primary.buttons .basic.button:active,
.ui.inverted.primary.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #21b8ff inset !important;
  box-shadow: 0px 0px 0px 2px #21b8ff inset !important;
  color: #54c8ff !important;
}

/*-------------------
      Secondary
--------------------*/

/* Standard */

.ui.secondary.buttons .button,
.ui.secondary.button {
  background-color: rgb($secondary);
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.secondary.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.secondary.buttons .button:hover,
.ui.secondary.button:hover {
  background-color: rgb($secondaryLight);
  color: $white;
  text-shadow: none;
}

.ui.secondary.buttons .button:focus,
.ui.secondary.button:focus {
  background-color: rgb($secondaryLight);
  color: $white;
  text-shadow: none;
}

.ui.secondary.buttons .button:active,
.ui.secondary.button:active {
  background-color: rgb($secondaryLight);
  color: $white;
  text-shadow: none;
}

.ui.secondary.buttons .active.button,
.ui.secondary.buttons .active.button:active,
.ui.secondary.active.button,
.ui.secondary.button .active.button:active {
  background-color: rgb($secondaryLight);
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.secondary.buttons .button,
.ui.basic.secondary.button {
  -webkit-box-shadow: 0px 0px 0px 1px rgb($secondary) inset !important;
  box-shadow: 0px 0px 0px 1px rgb($secondary) inset !important;
  color: rgb($secondary) !important;
}

.ui.basic.secondary.buttons .button:hover,
.ui.basic.secondary.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px rgb($secondaryLight) inset !important;
  box-shadow: 0px 0px 0px 1px rgb($secondaryLight) inset !important;
  color: rgb($secondaryLight) !important;
}

.ui.basic.secondary.buttons .button:focus,
.ui.basic.secondary.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px rgb($secondaryLight) inset !important;
  box-shadow: 0px 0px 0px 1px rgb($secondaryLight) inset !important;
  color: rgb($secondaryLight) !important;
}

.ui.basic.secondary.buttons .active.button,
.ui.basic.secondary.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px rgb($secondaryLight) inset !important;
  box-shadow: 0px 0px 0px 1px rgb($secondaryLight) inset !important;
  color: rgb($secondaryLight) !important;
}

.ui.basic.secondary.buttons .button:active,
.ui.basic.secondary.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px rgb($secondaryLight) inset !important;
  box-shadow: 0px 0px 0px 1px rgb($secondaryLight) inset !important;
  color: rgb($secondaryLight) !important;
}

.ui.buttons:not(.vertical) > .basic.primary.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.secondary.buttons .button,
.ui.inverted.secondary.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px #545454 inset !important;
  box-shadow: 0px 0px 0px 2px #545454 inset !important;
  color: #545454;
}

.ui.inverted.secondary.buttons .button:hover,
.ui.inverted.secondary.button:hover,
.ui.inverted.secondary.buttons .button:focus,
.ui.inverted.secondary.button:focus,
.ui.inverted.secondary.buttons .button.active,
.ui.inverted.secondary.button.active,
.ui.inverted.secondary.buttons .button:active,
.ui.inverted.secondary.button:active {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: $white;
}

.ui.inverted.secondary.buttons .button:hover,
.ui.inverted.secondary.button:hover {
  background-color: #616161;
}

.ui.inverted.secondary.buttons .button:focus,
.ui.inverted.secondary.button:focus {
  background-color: #686868;
}

.ui.inverted.secondary.buttons .active.button,
.ui.inverted.secondary.active.button {
  background-color: #616161;
}

.ui.inverted.secondary.buttons .button:active,
.ui.inverted.secondary.button:active {
  background-color: #6e6e6e;
}

/* Inverted Basic */

.ui.inverted.secondary.basic.buttons .button,
.ui.inverted.secondary.buttons .basic.button,
.ui.inverted.secondary.basic.button {
  background-color: transparent;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: $white !important;
}

.ui.inverted.secondary.basic.buttons .button:hover,
.ui.inverted.secondary.buttons .basic.button:hover,
.ui.inverted.secondary.basic.button:hover {
  -webkit-box-shadow: 0px 0px 0px 2px #616161 inset !important;
  box-shadow: 0px 0px 0px 2px #616161 inset !important;
  color: #545454 !important;
}

.ui.inverted.secondary.basic.buttons .button:focus,
.ui.inverted.secondary.basic.buttons .button:focus,
.ui.inverted.secondary.basic.button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #686868 inset !important;
  box-shadow: 0px 0px 0px 2px #686868 inset !important;
  color: #545454 !important;
}

.ui.inverted.secondary.basic.buttons .active.button,
.ui.inverted.secondary.buttons .basic.active.button,
.ui.inverted.secondary.basic.active.button {
  -webkit-box-shadow: 0px 0px 0px 2px #616161 inset !important;
  box-shadow: 0px 0px 0px 2px #616161 inset !important;
  color: #545454 !important;
}

.ui.inverted.secondary.basic.buttons .button:active,
.ui.inverted.secondary.buttons .basic.button:active,
.ui.inverted.secondary.basic.button:active {
  -webkit-box-shadow: 0px 0px 0px 2px #6e6e6e inset !important;
  box-shadow: 0px 0px 0px 2px #6e6e6e inset !important;
  color: #545454 !important;
}

/*---------------
    Positive
----------------*/

/* Standard */

.ui.positive.buttons .button,
.ui.positive.button {
  background-color: $green;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.positive.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.positive.buttons .button:hover,
.ui.positive.button:hover {
  background-color: #16ab39;
  color: $white;
  text-shadow: none;
}

.ui.positive.buttons .button:focus,
.ui.positive.button:focus {
  background-color: #0ea432;
  color: $white;
  text-shadow: none;
}

.ui.positive.buttons .button:active,
.ui.positive.button:active {
  background-color: #198f35;
  color: $white;
  text-shadow: none;
}

.ui.positive.buttons .active.button,
.ui.positive.buttons .active.button:active,
.ui.positive.active.button,
.ui.positive.button .active.button:active {
  background-color: #13ae38;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.positive.buttons .button,
.ui.basic.positive.button {
  -webkit-box-shadow: 0px 0px 0px 1px $green inset !important;
  box-shadow: 0px 0px 0px 1px $green inset !important;
  color: $green !important;
}

.ui.basic.positive.buttons .button:hover,
.ui.basic.positive.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #16ab39 inset !important;
  box-shadow: 0px 0px 0px 1px #16ab39 inset !important;
  color: #16ab39 !important;
}

.ui.basic.positive.buttons .button:focus,
.ui.basic.positive.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #0ea432 inset !important;
  box-shadow: 0px 0px 0px 1px #0ea432 inset !important;
  color: #16ab39 !important;
}

.ui.basic.positive.buttons .active.button,
.ui.basic.positive.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #13ae38 inset !important;
  box-shadow: 0px 0px 0px 1px #13ae38 inset !important;
  color: #198f35 !important;
}

.ui.basic.positive.buttons .button:active,
.ui.basic.positive.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #198f35 inset !important;
  box-shadow: 0px 0px 0px 1px #198f35 inset !important;
  color: #198f35 !important;
}

.ui.buttons:not(.vertical) > .basic.primary.button:not(:first-child) {
  margin-left: -1px;
}

/*---------------
     Negative
----------------*/

/* Standard */

.ui.negative.buttons .button,
.ui.negative.button {
  background-color: #db2828;
  color: $white;
  text-shadow: none;
  background-image: none;
}

.ui.negative.button {
  -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.negative.buttons .button:hover,
.ui.negative.button:hover {
  background-color: #d01919;
  color: $white;
  text-shadow: none;
}

.ui.negative.buttons .button:focus,
.ui.negative.button:focus {
  background-color: #ca1010;
  color: $white;
  text-shadow: none;
}

.ui.negative.buttons .button:active,
.ui.negative.button:active {
  background-color: #b21e1e;
  color: $white;
  text-shadow: none;
}

.ui.negative.buttons .active.button,
.ui.negative.buttons .active.button:active,
.ui.negative.active.button,
.ui.negative.button .active.button:active {
  background-color: #d41515;
  color: $white;
  text-shadow: none;
}

/* Basic */

.ui.basic.negative.buttons .button,
.ui.basic.negative.button {
  -webkit-box-shadow: 0px 0px 0px 1px #db2828 inset !important;
  box-shadow: 0px 0px 0px 1px #db2828 inset !important;
  color: #db2828 !important;
}

.ui.basic.negative.buttons .button:hover,
.ui.basic.negative.button:hover {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #d01919 inset !important;
  box-shadow: 0px 0px 0px 1px #d01919 inset !important;
  color: #d01919 !important;
}

.ui.basic.negative.buttons .button:focus,
.ui.basic.negative.button:focus {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #ca1010 inset !important;
  box-shadow: 0px 0px 0px 1px #ca1010 inset !important;
  color: #d01919 !important;
}

.ui.basic.negative.buttons .active.button,
.ui.basic.negative.active.button {
  background: transparent !important;
  -webkit-box-shadow: 0px 0px 0px 1px #d41515 inset !important;
  box-shadow: 0px 0px 0px 1px #d41515 inset !important;
  color: #b21e1e !important;
}

.ui.basic.negative.buttons .button:active,
.ui.basic.negative.button:active {
  -webkit-box-shadow: 0px 0px 0px 1px #b21e1e inset !important;
  box-shadow: 0px 0px 0px 1px #b21e1e inset !important;
  color: #b21e1e !important;
}

.ui.buttons:not(.vertical) > .basic.primary.button:not(:first-child) {
  margin-left: -1px;
}

/*******************************
            Groups
*******************************/

.ui.buttons {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 0em;
  vertical-align: baseline;
  margin: 0em 0.25em 0em 0em;
}

.ui.buttons:not(.basic):not(.inverted) {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Clearfix */

.ui.buttons:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/* Standard Group */

.ui.buttons .button {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin: 0em;
  border-radius: 0em;
  margin: 0px 0px 0px 0px;
}

.ui.buttons > .ui.button:not(.basic):not(.inverted),
.ui.buttons:not(.basic):not(.inverted) > .button {
  -webkit-box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.buttons .button:first-child {
  border-left: none;
  margin-left: 0em;
  border-top-left-radius: 0.28571429rem;
  border-bottom-left-radius: 0.28571429rem;
}

.ui.buttons .button:last-child {
  border-top-right-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
}

/* Vertical  Style */

.ui.vertical.buttons {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ui.vertical.buttons .button {
  display: block;
  float: none;
  width: 100%;
  margin: 0px 0px 0px 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0em;
}

.ui.vertical.buttons .button:first-child {
  border-top-left-radius: 0.28571429rem;
  border-top-right-radius: 0.28571429rem;
}

.ui.vertical.buttons .button:last-child {
  margin-bottom: 0px;
  border-bottom-left-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
}

.ui.vertical.buttons .button:only-child {
  border-radius: 0.28571429rem;
}
