/*******************************
              Search
 *******************************/

.ui.search {
  position: relative;
}

.ui.search > .prompt {
  margin: 0em;
  outline: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-shadow: none;
  font-style: normal;
  font-weight: normal;
  line-height: 1.21428571em;
  padding: 0.67857143em 1em;
  font-size: 1em;
  background: $white;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: $textColor;
  -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
  box-shadow: 0em 0em 0em 0em transparent inset;
  -webkit-transition: background-color 0.1s ease, color 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: background-color 0.1s ease, color 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, border-color 0.1s ease;
  transition: background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, border-color 0.1s ease,
    -webkit-box-shadow 0.1s ease;
}

.ui.search .prompt {
  border-radius: 500rem;
}

/*--------------
      Icon
 ---------------*/

.ui.search .prompt ~ .search.icon {
  cursor: pointer;
}

/*--------------
     Results
 ---------------*/

.ui.search > .results {
  display: none;
  position: absolute;
  top: 100%;
  left: 0%;
  -webkit-transform-origin: center top;
  transform-origin: center top;
  white-space: normal;
  text-align: left;
  text-transform: none;
  background: $white;
  margin-top: 0.5em;
  width: 18em;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
  border: 1px solid #d4d4d5;
  z-index: 998;
}

.ui.search > .results > :first-child {
  border-radius: 0.28571429rem 0.28571429rem 0em 0em;
}

.ui.search > .results > :last-child {
  border-radius: 0em 0em 0.28571429rem 0.28571429rem;
}

/*--------------
     Result
 ---------------*/

.ui.search > .results .result {
  cursor: pointer;
  display: block;
  overflow: hidden;
  font-size: 1em;
  padding: 0.85714286em 1.14285714em;
  color: $textColor;
  line-height: 1.33;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.ui.search > .results .result:last-child {
  border-bottom: none !important;
}

/* Image */

.ui.search > .results .result .image {
  float: right;
  overflow: hidden;
  background: none;
  width: 5em;
  height: 3em;
  border-radius: 0.25em;
}

.ui.search > .results .result .image img {
  display: block;
  width: auto;
  height: 100%;
}

/*--------------
       Info
 ---------------*/

.ui.search > .results .result .image + .content {
  margin: 0em 6em 0em 0em;
}

.ui.search > .results .result .title {
  margin: -0.14285714em 0em 0em;
  font-family: $font-family-l, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 1em;
  color: $grey;
}

.ui.search > .results .result .description {
  margin-top: 0;
  font-size: 0.92857143em;
  color: rgba(0, 0, 0, 0.4);
}

.ui.search > .results .result .price {
  float: right;
  color: $green;
}

/*--------------
     Message
 ---------------*/

.ui.search > .results > .message {
  padding: 1em 1em;
}

.ui.search > .results > .message .header {
  font-family: $font-family, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  color: $textColor;
}

.ui.search > .results > .message .description {
  margin-top: 0.25rem;
  font-size: 1em;
  color: $textColor;
}

/* View All Results */

.ui.search > .results > .action {
  display: block;
  border-top: none;
  background: #f3f4f5;
  padding: 0.92857143em 1em;
  color: $textColor;
  font-weight: bold;
  text-align: center;
}

/*******************************
             States
 *******************************/

/*--------------------
        Focus
 ---------------------*/

.ui.search > .prompt:focus {
  border-color: rgba(34, 36, 38, 0.35);
  background: $white;
  color: rgba(0, 0, 0, 0.95);
}

/*--------------------
        Loading
 ---------------------*/

.ui.loading.search .input > i.icon:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0em 0em -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
}

.ui.loading.search .input > i.icon:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0em 0em -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  -webkit-animation: button-spin 0.6s linear;
  animation: button-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: $grey transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  -webkit-box-shadow: 0px 0px 0px 1px transparent;
  box-shadow: 0px 0px 0px 1px transparent;
}

/*--------------
       Hover
 ---------------*/

.ui.search > .results .result:hover,
.ui.category.search > .results .category .result:hover {
  background: #f9fafb;
}

.ui.search .action:hover {
  background: #e0e0e0;
}

/*--------------
       Active
 ---------------*/

.ui.category.search > .results .category.active {
  background: #f3f4f5;
}

.ui.category.search > .results .category.active > .name {
  color: $textColor;
}

.ui.search > .results .result.active,
.ui.category.search > .results .category .result.active {
  position: relative;
  border-left-color: rgba(34, 36, 38, 0.1);
  background: #f3f4f5;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui.search > .results .result.active .title {
  color: rgba(0, 0, 0, 0.85);
}

.ui.search > .results .result.active .description {
  color: rgba(0, 0, 0, 0.85);
}

/*--------------------
         Disabled
 ----------------------*/

/* Disabled */

.ui.disabled.search {
  cursor: default;
  pointer-events: none;
  opacity: 0.45;
}

/*******************************
            Types
 *******************************/

/*--------------
     Selection
 ---------------*/

.ui.search.selection .prompt {
  border-radius: 0.28571429rem;
}

/* Remove input */

.ui.search.selection > .icon.input > .remove.icon {
  pointer-events: none;
  position: absolute;
  left: auto;
  opacity: 0;
  color: "";
  top: 0em;
  right: 0em;
  -webkit-transition: color 0.1s ease, opacity 0.1s ease;
  transition: color 0.1s ease, opacity 0.1s ease;
}

.ui.search.selection > .icon.input > .active.remove.icon {
  cursor: pointer;
  opacity: 0.8;
  pointer-events: auto;
}

.ui.search.selection > .icon.input:not([class*="left icon"]) > .icon ~ .remove.icon {
  right: 1.85714em;
}

.ui.search.selection > .icon.input > .remove.icon:hover {
  opacity: 1;
  color: #db2828;
}

/*--------------
     Category
 ---------------*/

.ui.category.search .results {
  width: 28em;
}

.ui.category.search .results.animating,
.ui.category.search .results.visible {
  display: table;
}

/* Category */

.ui.category.search > .results .category {
  display: table-row;
  background: #f3f4f5;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: background 0.1s ease, border-color 0.1s ease;
  transition: background 0.1s ease, border-color 0.1s ease;
}

/* Last Category */

.ui.category.search > .results .category:last-child {
  border-bottom: none;
}

/* First / Last */

.ui.category.search > .results .category:first-child .name + .result {
  border-radius: 0em 0.28571429rem 0em 0em;
}

.ui.category.search > .results .category:last-child .result:last-child {
  border-radius: 0em 0em 0.28571429rem 0em;
}

/* Category Result Name */

.ui.category.search > .results .category > .name {
  display: table-cell;
  text-overflow: ellipsis;
  width: 100px;
  white-space: nowrap;
  background: transparent;
  font-family: $font-family, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1em;
  padding: 0.4em 1em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

/* Category Result */

.ui.category.search > .results .category .results {
  display: table-cell;
  background: $white;
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
}

.ui.category.search > .results .category .result {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  -webkit-transition: background 0.1s ease, border-color 0.1s ease;
  transition: background 0.1s ease, border-color 0.1s ease;
  padding: 0.85714286em 1.14285714em;
}

/*******************************
            Variations
 *******************************/

/*-------------------
      Left / Right
 --------------------*/

.ui[class*="left aligned"].search > .results {
  right: auto;
  left: 0%;
}

.ui[class*="right aligned"].search > .results {
  right: 0%;
  left: auto;
}

/*--------------
     Fluid
 ---------------*/

.ui.fluid.search .results {
  width: 100%;
}

/*--------------
       Sizes
 ---------------*/

.ui.mini.search {
  font-size: 0.78571429em;
}

.ui.small.search {
  font-size: 0.92857143em;
}

.ui.search {
  font-size: 1em;
}

.ui.large.search {
  font-size: 1.14285714em;
}

.ui.big.search {
  font-size: 1.28571429em;
}

.ui.huge.search {
  font-size: 1.42857143em;
}

.ui.massive.search {
  font-size: 1.71428571em;
}

/*--------------
       Mobile
 ---------------*/

@media only screen and (max-width: 767px) {
  .ui.search .results {
    max-width: calc(100vw - 2rem);
  }
}
