.radioButtonsDiv {
  display: flex;

  .field:first-of-type {
    margin-right: 2em;
  }
}
.radioButtonsFiltermenu {
	display: grid;
	grid-template-columns: repeat(2,1fr);
	font-size: 13px;
	margin-bottom: 30px;
  .field {
    margin-right: 3em;
  }
}

@media only screen and (max-width: 1400px) {
	.radioButtonsFiltermenu {
		display: grid;
		grid-template-columns: repeat(1,1fr);
		font-size: 13px;
		margin-bottom: 30px;
		.field {
			margin-right: 3em;
		}
	}
}