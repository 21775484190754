.parent-property {
  /* if any other css is required */
}

.child-property {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.child-property .childTemp {
  flex: 1;
  max-width: 250px;
  min-width: 150px;
}