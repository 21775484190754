.selectButtonWidget {
  .menu.visible{
    width: 100%;
    min-width: unset !important;
    .item {
      white-space: normal !important;
      line-height: 1.3;
      padding: 0.5rem !important;
    }
    @media screen and (min-width: 320px) and (max-width: 512px) {
      min-width: auto !important;
      width: 100%;
    }
  }
}