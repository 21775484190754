$yellowmagic: #f7b241;
$iPadFontSize: 0.8rem;
@media only screen and (width: 1112px) {
  .userApplication,
  .userPolicies {
    min-height: 20rem !important;
    .h6 {
      margin-left: 0.6rem !important;
      margin-top: 0.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    // background-color: $yellowmagic !important;
    div.noOngoingApplications,
    .noPoliciesIssued {
      // background-color: cadetblue !important;
      display: flex;
      margin-top: 6rem;
      font-size: 2rem;
      justify-content: center;
      align-self: center;
    }

    .ui.list .listingItems {
      // background-color: pink;
      // border: 1px solid crimson;
      display: flex;
      margin-left: 0.6rem !important;
      margin-right: 0.6rem !important;

      div.productImageAndNameWithProductOptionName {
        // border: 1px solid crimson;
        // background-color: rgb(204, 160, 66);
        display: flex;
        flex: 0.75 !important;
        word-break: keep-all !important;
        div.outerdiv {
          // border: 1px solid rgb(127, 220, 20);
          display: flex;
          flex: 0.9;
          // background-color: darksalmon;
          div.productImage {
            max-width: 2rem !important;
            flex-basis: 5rem !important;
          }

          div.productNameAndDesc {
            // background-color: yellowgreen;
            margin-left: 0.6rem !important;
            flex-basis: 25rem !important;
            font-size: $iPadFontSize !important;
            text-align: left;
            word-break: keep-all !important;
          }
        }
      }

      div.secondHalf {
        // border: 1px solid crimson;
        display: flex;
        flex: 1 !important;

        div.sumAssuredValue {
          // border: 1px solid crimson;
          flex-basis: 10rem !important;
          word-break: keep-all;
          font-size: $iPadFontSize !important;
        }

        div.Status {
          // background-color: rgb(228, 233, 240);
          // border: 1px solid crimson;
          margin-left: 0.3rem !important;
          flex-basis: 15rem !important;
          font-size: $iPadFontSize !important;
          word-break: keep-all;
        }
      }

      div.applicationDetailsButton {
        // background-color: antiquewhite !important;
        margin-left: 0.4rem !important;
        flex-basis: 4rem !important;
        align-self: center !important;
        align-items: flex-end !important;
        padding-right: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        .continueButton {
          padding-right: 0 !important;
        }
        .continueButtonDisable {
          display: none;
        }

        div.subtitle2Btn {
          // background-color: grey;
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
          font-size: $iPadFontSize !important;
        }
      }
      div.policyDetailsButton {
        // background-color: rgb(175, 42, 175) !important;
        margin-left: 0.4rem !important;
        flex-basis: 4rem !important;
        align-self: center !important;
        align-items: flex-end !important;
        padding-right: 0 !important;
        display: flex !important;
        flex-direction: column !important;

        div.subtitle2Btn {
          // background-color: grey;
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
          font-size: $iPadFontSize !important;
        }
      }
    }
    hr {
      margin-left: 3rem !important;
      margin-right: 0.5rem !important;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .userApplication,
  .userPolicies {
    min-height: 20rem !important;
    .h6 {
      margin-left: 0.6rem !important;
      margin-top: 0.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    // background-color: $yellowmagic !important;
    div.noOngoingApplications,
    .noPoliciesIssued {
      // background-color: cadetblue !important;
      display: flex;
      margin-top: 6rem;
      font-size: 2rem;
      justify-content: center;
      align-self: center;
    }

    .ui.list .listingItems {
      // background-color: pink;
      // border: 1px solid crimson;
      display: flex;
      margin-left: 0.6rem !important;
      margin-right: 0.6rem !important;

      div.productImageAndNameWithProductOptionName {
        // border: 1px solid crimson;
        // background-color: rgb(204, 160, 66);
        display: flex;
        flex: 0.75 !important;
        word-break: keep-all !important;
        div.outerdiv {
          // border: 1px solid rgb(127, 220, 20);
          display: flex;
          flex: 0.9;
          // background-color: darksalmon;
          div.productImage {
            max-width: 2rem !important;
            flex-basis: 5rem !important;
          }

          div.productNameAndDesc {
            // background-color: yellowgreen;
            margin-left: 0.6rem !important;
            flex-basis: 25rem !important;
            font-size: $iPadFontSize !important;
            text-align: left;
            word-break: keep-all !important;
          }
        }
      }

      div.secondHalf {
        // background-color: rgb(100, 240, 175);
        // border: 1px solid crimson;
        display: flex;
        flex: 1 !important;

        div.sumAssuredValue {
          // border: 1px solid crimson;
          flex-basis: 10rem !important;
          word-break: keep-all;
          font-size: $iPadFontSize !important;
        }

        div.Status {
          // background-color: rgb(228, 233, 240);
          // border: 1px solid crimson;
          margin-left: 0.3rem !important;
          flex-basis: 15rem !important;
          font-size: $iPadFontSize !important;
          word-break: keep-all;
        }
      }

      div.applicationDetailsButton {
        // background-color: antiquewhite !important;
        margin-left: 0.4rem !important;
        flex-basis: 4rem !important;
        align-self: center !important;
        align-items: flex-end !important;
        padding-right: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        .continueButton {
          padding-right: 0 !important;
        }
        .continueButtonDisable {
          display: none;
        }

        div.subtitle2Btn {
          // background-color: grey;
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
          font-size: $iPadFontSize !important;
        }
      }
      div.policyDetailsButton {
        // background-color: rgb(175, 42, 175) !important;
        margin-left: 0.4rem !important;
        flex-basis: 4rem !important;
        align-self: center !important;
        align-items: flex-end !important;
        padding-right: 0 !important;
        display: flex !important;
        flex-direction: column !important;

        div.subtitle2Btn {
          // background-color: grey;
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
          font-size: $iPadFontSize !important;
        }
      }
    }
    hr {
      margin-left: 3rem !important;
      margin-right: 0.5rem !important;
    }
  }
}

.mobileDashBoardButton {
  max-width: 100%;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  margin-bottom: 1rem !important;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .userApplication,
  .userPolicies {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
    .h6 {
      margin-top: 1.4rem !important;
      margin-bottom: 1rem !important;
      margin-left: 0rem !important;
      margin-right: 0rem !important;
    }

    div.noOngoingApplications,
    .noOngoingPolicies {
      font-size: 1.7rem !important;
    }

    .ui.list .listingItems {
      margin-left: 0rem !important;
      margin-right: 0rem !important;
      flex-direction: column !important;
      flex-basis: 3rem !important;
      div.productImageAndNameWithProductOptionName {
        div.outerdiv {
          flex-direction: column !important;
          div.productImage {
            flex-basis: 2rem !important;
          }

          div.productNameAndDesc {
            margin-left: 0rem !important;
            flex-basis: 4rem !important;
            margin-top: 0rem !important;
            width: max-content !important;
          }
        }
      }

      div.secondHalf {
        flex-direction: column !important;

        div.sumAssuredValue {
          flex-basis: 3rem !important;
          .body1 {
            justify-content: flex-start !important;
          }
          .ui.tiny.header {
            text-align: left !important;
          }
        }

        div.Status {
          margin-left: 0rem !important;
          flex-basis: 5rem !important;
        }
      }

      div.applicationDetailsButton {
        flex-basis: 2rem !important;
        align-self: flex-start !important;
      }

      div.policyDetailsButton {
        flex-basis: 2rem !important;
        align-self: flex-start !important;
      }
    }

    hr {
      margin-left: 0rem !important;
      margin-right: 0rem !important;
    }
  }
}

.userApplication,
.userPolicies {
  min-height: 20rem !important;
  .h6 {
    // background-color: aquamarine;
    // border: 1px solid crimson;
    // padding-left: 1.7rem;
    margin-top: 1.4rem !important;
    margin-left: 1.4rem;
    margin-right: 1.4rem;
    margin-bottom: 2.54rem;
  }
  // background-color: $yellowmagic !important;
  div.noOngoingApplications,
  .noOngoingPolicies {
    // background-color: cadetblue;
    display: flex;
    margin-top: 6rem;
    font-size: 2rem;
    justify-content: center;
    align-self: center;
  }
  .ui.list:last-child {
    margin-bottom: 1.4rem;
  }
  .ui.list .listingItems {
    // border-bottom: 1px solid crimson;
    margin-left: 1.4rem;
    margin-right: 1.4rem;
    margin-bottom: -0.4rem;
    // background-color: pink;
    // padding-bottom: 0.2rem;
    display: flex;
    div.productImageAndNameWithProductOptionName {
      // border: 1px solid crimson;
      // background-color: rgb(204, 160, 66);
      display: flex;
      flex: 0.73;
      justify-content: flex-start;
      word-break: keep-all;
      div.outerdiv {
        display: flex;
        flex: 0.96;
        // border: 1px solid darkgreen;
        div.productImage {
          padding-top: 0.35rem;
          // max-width: 2.5rem;
          flex-basis: 5rem;
          .productImg {
            width: 2.3rem;
            height: 1.3rem;
            border-radius: 0.2rem;
          }
        }

        div.productNameAndDesc {
          // background-color: yellowgreen;
          margin-left: 1rem;
          flex-basis: 25rem;
          text-align: left;
          word-break: keep-all;
        }
      }
    }

    div.secondHalf {
      // border: 1px solid crimson;
      // background-color: cyan;
      display: flex;
      flex: 1;
      // justify-content: space-between;

      div.sumAssuredValue {
        // border: 1px solid darkgreen;
        // background-color: rgb(41, 128, 0);
        flex-basis: 12rem;
        word-break: break-all;
        .body1 {
          // border: 1px solid darkgreen;
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          span {
            margin-left: -0.25rem;
            // color: #4cb050;
          }
        }
        .ui.tiny.header {
          text-align: center;
        }
      }

      div.Status {
        // border: 1px solid darkgreen;
        //background-color: rgb(90, 202, 150);
        margin-left: 0.8rem;
        flex-basis: 20rem;
        word-break: keep-all;
      }
    }

    div.applicationDetailsButton {
      // background-color: rgb(187, 231, 200);
      // border: 1px solid crimson;
      flex-basis: 12rem;
      align-self: center;
      justify-content: flex-end;
      padding-right: 1.3rem;
      display: flex;
      .continueButton {
        padding-right: 1.4rem;
      }
      .continueButtonDisable {
        display: none;
      }

      div.subtitle2Btn {
        // background-color: grey;
        font-size: 0.875rem;
        cursor: pointer;
      }
    }

    div.policyDetailsButton {
      // background-color: rgb(187, 231, 200);
      // border: 1px solid crimson;
      flex-basis: 12rem;
      padding-right: 1.4rem;
      align-self: center;
      justify-content: flex-end;
      display: flex;
      // .icon{
      //   background-color: brown;
      // }

      div.subtitle2Btn {
        // background-color: grey;
        font-size: 0.875rem;
        cursor: pointer;
      }
    }
  }
  hr {
    border: 1px solid rgba(99, 96, 96, 0.15);
    margin-left: 4.5rem;
    margin-right: 2.5rem;
  }
}

.applicationLoader {
  margin: auto;
}

.loaderParent {
  display: flex;
  flex-direction: column;
}
