.claimRegistrationForm {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  flex-direction: column;

  .bg_claim_registration_card {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.175);
    background-color: white;
    padding: 1rem;
    width: 50rem;
    height: fit-content; /* Set the desired height for the card */
    margin-bottom: 2rem;
    margin-top: 2rem;

    form > div.row.form-group:nth-child(1) {
      justify-content: center;
    }

    .ui.floated.header, .ui[class*="left floated"].header{
     margin: 1rem;
    display: flex;
    flex-direction: column;
    }

    @media screen and (min-width: 320px) and (max-width: 510px) {
      width: 98%;
      padding: 1rem 0;

      form {
        margin: auto;
        padding: 0 0.5rem;

        .row[class*="root__"] > .grid {
          flex: 1;
        }
      }
    }

    @media screen and (min-width: 510px) and (max-width: 768px) {
      width: 98%;

      form {
        .row[class*="root__"] > .grid {
          flex: 1;
        }
      }
    }

    // Additional styles for the last step
    &.lastStep {
      margin-top: 2rem;
      margin-bottom: 4rem;
    }
  }

  .ms-3.mb-3.mt-4 {
    margin: 1rem 0rem;
    flex-direction: column;

    button {
      margin-bottom: 0rem;
    }
  }

  button[type="submit"] {
    margin-bottom: 2rem;
  }
}
