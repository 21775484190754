@import 'assets/styles/variables.scss';

.dividedSegment .leftSegment {
  -webkit-flex: 5;
     -moz-flex: 5;
          flex: 5;
  border-right: 2px solid $backgroundGrey;
  padding: 2.5rem 5rem 2rem 2.5rem;
}
.dividedSegment .rightSegment {
  -webkit-flex: 4;
     -moz-flex: 4;
          flex: 4;
  padding: 2.5rem 3rem 2rem 3rem;
}