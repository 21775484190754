:root {
  --font-family: "Roboto", sans-serif;
  --font-family-l: "RobotoLight", sans-serif;
  --font-family-r: "RobotoRegular", sans-serif;
  --font-family-m: "RobotoMedium", sans-serif;
  --font-size: 16px;
  --font-size-xs: 10px;
  --font-size-s: 12px;
  --font-size-m: 14px;
  --font-size-l: 18px;
  --font-size-xl: 20px;

  /** Brand Colors **/
  --secondary: 2, 112, 246; // #0270f6 -> Used in Button, Input Fields, and On Product Lisitng Card Top Border when mouse is over the card.
  --secondaryLight: 33, 150, 243; // #2196f3 -> Radio, Checkbox, and Listing Card Top border the lighter color
  --primary: 0, 59, 144; // #003b90 -> Header Background
  --primaryDark: 10, 49, 86; // #0a3156 -> Onboard section bg
  --primaryLight: 40, 53, 147; // #283593 -> Used in Fields Label color

  --text-color: rgba(0, 0, 0, 0.87);
  --text-color-light: rgba(0, 0, 0, 0.4);

  /** Color Palette **/
  --blue: #0270f6;
  --paleBlue: #aacbf3;
  --purple: #283593;
  --grey: #666666;
  --green: #4cb050;
  --yellow: #f8bf2e;
  --black: #212121;
  --dullgreen: #54b458;
  --zambezi: #585858;
  --lightblue: #2196f3;
  --blueGrey: #3a5163;
  --dullGrey: #9b9b9b;
  --red: #db2828;
  --white: #ffffff;
  --backgroundGrey: #f7f7f7;
  --greySeperator: rgba(151, 151, 151, 0.2);

  --headerHeight: 4em;
  --buttonBoxShadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.35);

  --shadow-sm-dark : 0px 2px 5px 0px rgba(0, 0, 0, 0.35); 
  --shadow-md-dark : 0px 2px 10px 2px rgba(0, 0, 0, 0.35); 
  --shadow-lg-dark : 0px 2px 15px 5px rgba(0, 0, 0, 0.35); 

  --shadow-sm-light : 0px 2px 5px 0px rgba(255, 255, 255, 0.2); 
  --shadow-md-light : 0px 2px 10px 2px rgba(255, 255, 255, 0.2); 
  --shadow-lg-light : 0px 2px 15px 5px rgba(255, 255, 255, 0.2); 
}