
.modalContainer {
  width: fit-content !important;
  .modal_close_btn {
    text-align: right;
  }
  .content {
    text-align: center;
    .ui.small.header {
      margin-bottom: 1rem;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 512px) {
    // .modalContainer
    width: 90% !important;
    .modal_close_btn {
      svg {
      font-size: 1.5rem;
      }
    }
  }

  @media screen and (min-width: 512px) and (max-width: 768px) {
    width: 60% !important;
  }
}