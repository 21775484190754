@import "./root";
@import "./variables";
@import "./semantic-ui/all";

html,
body,
#root {
  font-size: $font-size !important;
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    font-size: $font-size-m !important;
  }
  @media only screen and (min-width: 1920px) and (max-width: 2400px) {
    font-size: $font-size-l !important;
  }
  @media only screen and (min-width: 2401px) {
    font-size: $font-size-xl !important;
  }
}

#mobileNumberDropDown {
  input.search {
    width: 14px !important;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}
.smartcovrUiDisplay {
  height: calc(100vh - $headerHeight - 0.56rem);
  @media only screen and (max-width: 520px) {
    height: calc(100vh - $headerHeight);
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}
.row > * {
  padding: 0;
}
.fieldformat {
  font-weight: bold;
  white-space: nowrap;
}
.flexContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}
.alignFlexItems {
  -webkit-box-align: center;
  -moz-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ui.medium.form {
  font-size: 0.875em;
}

.ui.button:not(.icon) {
  margin-top: 0.75rem;
}
.ui.button > .image:not(.button) {
  display: inline-block;
}
.ui.button:not(.image) > .image:not(.button):not(.dropdown),
.ui.button > .image:not(.button) {
  margin: 0 0.42857143em 0 -0.21428571em;
}

.ui.form .field {
  margin: 0;
}

.ui.form .field label {
  margin: 0em 0em 0.28571429rem 0em;
  color: rgb($primaryLight);
  font-size: 0.92857142em;
  font-weight: bold;
  text-transform: none;
}

.ui.form .fields.error .field label,
.ui.form .field.error label,
.ui.form .fields.error .field .input,
.ui.form .field.error .input {
  color: rgb($primaryLight);
}

.ui.checkbox input.hidden + label {
  color: $grey;
}
.ui.checkbox input:checked + label {
  color: rgb($secondaryLight);
}

.ui.fluid.menu .item:last-child::before {
  display: none;
}
.ui.list .list > .item .header,
.ui.list > .item .header {
  font-weight: 400;
  font-family: $font-family-r;
  margin: 0.25em 0;
}

.ui.uiheader.noStyle > .icon {
  font-size: 1em;
  padding-left: 0em;
  margin: 0;
}

.ui.search > .results {
  max-height: 300px;
  overflow-y: auto;
  z-index: 1;
}
/** Semantic Sidebar to makie it fixed at top (mobile) **/
/* Page Context */
.pushable:not(body) {
  transform: none;
}

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed;
}

/** Color Classes **/

.white {
  color: $white !important;
}
.black {
  color: $black !important;
}
.blueGrey {
  color: $blueGrey !important;
}
.dullGrey {
  color: $dullGrey !important;
}
.zambezi {
  color: $zambezi !important;
}
.secondary:not(.button) {
  color: rgb($secondary) !important;
}
.secondaryLight:not(.button) {
  color: rgb($secondaryLight) !important;
}
.primary:not(.button) {
  color: rgb($primary) !important;
}
.primaryDark:not(.button) {
  color: rgb($primaryDark) !important;
}
.primaryLight:not(.button) {
  color: rgb($primaryLight) !important;
}
.grey:not(.button) {
  color: $grey !important;
}

/** Background Color Classes **/
.white-bg {
  background-color: $white !important;
}
.black-bg {
  background-color: $black !important;
}
.blueGrey-bg {
  background-color: $blueGrey !important;
}
.dullGrey-bg {
  background-color: $dullGrey !important;
}
.secondary-bg {
  background-color: rgb($secondary) !important;
}
.secondaryLight-bg {
  background-color: rgb($secondaryLight) !important;
}
.primary-bg {
  background-color: rgb($primary) !important;
}
.primaryDark-bg {
  background-color: rgb($primaryDark) !important;
}
.primaryLight-bg {
  background-color: rgb($primaryLight) !important;
}

/** Size classes **/
.size10 {
  width: 10px !important;
}
.size12 {
  width: 12px !important;
}
.size14 {
  width: 14px !important;
}
.size16 {
  width: 16px !important;
}
