@import 'assets/styles/variables.scss';

.display__tabs {
  &-list {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .react-tabs__tab {
    font-size: 14px;
    font-family: "RobotoMedium";
    color: $grey;
    padding: 12px 10px;
    bottom: 0;
    border: none;
  }
  .react-tabs__tab--selected {
    border-bottom-style: none;
    color: rgb($secondary);
  }
  .react-tabs__tab-panel--selected {
    margin: 16px;
  }
}
