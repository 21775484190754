.nomineeCard.ui.card {
  -webkit-box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  -moz-box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
  box-shadow: 0 5px 15px 0 rgba(37, 44, 97, 0.15), 0 2px 4px 0 rgba(93, 100, 148, 0.2);
}
.nomineeCard .cardSection {
  padding: 1em;
}
.ui.form.jsonSchemaForm .nomineeCard .ui.transparent.button.editNomineeButton,
.ui.form.jsonSchemaForm .nomineeCard .ui.transparent.button.removeNomineeButton {
  margin-left: 0px;
  padding-top: 0px;
}
