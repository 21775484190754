
/*******************************
             Sticky
 *******************************/

 .ui.sticky {
  position: static;
  -webkit-transition: none;
  transition: none;
  z-index: 800;
}

/*******************************
             States
 *******************************/

/* Bound */

.ui.sticky.bound {
  position: absolute;
  left: auto;
  right: auto;
}

/* Fixed */

.ui.sticky.fixed {
  position: fixed;
  left: auto;
  right: auto;
}

/* Bound/Fixed Position */

.ui.sticky.bound.top,
.ui.sticky.fixed.top {
  top: 0px;
  bottom: auto;
}

.ui.sticky.bound.bottom,
.ui.sticky.fixed.bottom {
  top: auto;
  bottom: 0px;
}

/*******************************
             Types
 *******************************/

.ui.native.sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
}