@import 'assets/styles/variables.scss';

 .group-clear:after {
   clear: both;
 }
 .group-clear {
   clear: both;
   *zoom: 1;
 }
 .ui.header.productCategoryTitle {
  text-transform: uppercase;
  margin-top: 1rem !important;
  margin-left: 1rem;
 }

.ui.card.productCard {
  border-radius: 2px;
  margin: 0.75rem;
}
.ui.card.cardViewMobile .insuredwithlogo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
  flex-direction: column;
}
.ui.card.cardViewMobile .knowMoreButtonMobile {
  background-color: $backgroundGrey;
  margin: 0px;
  padding: 0.5em 1.5em;
}
.ui.card.cardViewMobile, .ui.card.productCard {
  -webkit-box-shadow: 0 5px 15px 0 rgba(74, 84, 158, 0.15), 0 2px 4px 0 rgba(93,100,148,0.2);
  -moz-box-shadow: 0 5px 15px 0 rgba(37,44,97,0.15), 0 2px 4px 0 rgba(93,100,148,0.2);
  box-shadow: 0 5px 15px 0 rgba(37,44,97,0.15), 0 2px 4px 0 rgba(93,100,148,0.2) !important;
  -webkit-transition: all 0.50s cubic-bezier(.25, .8, .25, 1) !important;
  -moz-transition: all 0.50s cubic-bezier(.25, .8, .25, 1) !important;
  -o-transition: all 0.50s cubic-bezier(.25, .8, .25, 1) !important;
  transition: all 0.50s cubic-bezier(.25, .8, .25, 1) !important;
}
.ui.card.cardViewMobile:hover, .ui.card.productCard:hover {
  -webkit-box-shadow: 0 10px 28px rgba(157, 216, 242, 0.40), 0 10px 20px rgba(10, 0, 10, 0.22);
  -moz-box-shadow: 0 10px 28px rgba(157, 216, 242, 0.40), 0 10px 20px rgba(10, 0, 10, 0.22);
  box-shadow: 0 10px 28px rgba(157, 216, 242, 0.40), 0 10px 20px rgba(10, 0, 10, 0.22) !important;
}
.ui.card.cardViewMobile .basicDetails {
  padding: 1rem;
}
.ui.cards.productCards {
  width: 100%;
  margin-top: 1rem;
  margin-left: 0px;
  margin-bottom: 2.5rem;
}

.ui.cards.productCards .ui.card.productCard:hover {
  -webkit-border-top: 4px solid rgb($secondary);
  -moz-border-top: 4px solid rgb($secondary);
  border-top: 4px solid rgb($secondary);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.ui.cards.productCards .ui.card.productCard {
  width: 20.625rem;
  min-height: 29rem;
  padding: 2rem 1.5rem 2rem !important;
  -webkit-border-top: 4px solid rgba($secondaryLight, 0.2);
  -moz-border-top: 4px solid rgba($secondaryLight, 0.2);
  border-top: 4px solid rgba($secondaryLight, 0.2);
  /* background-image: linear-gradient(#fff, #f5f5fa); */
  max-width: 20.625rem !important;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.ui.cards.productCards .ui.card.productCard .ui.list {
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
}

.ui.cards.productCards .ui.card.productCard.editable {
  background-color: #eceff1;
  /* background-image: linear-gradient(#eceff1, #f5f5fa); */
}

.ui.cards.productCards .ui.card.productCard hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 767px) {
  .ui.cards.productCards .ui.card.productCard {
    margin: 0.75rem auto;
  }
}

.ui.cards.productCards .ui.card.productCard:hover .ui.green.statistic>.value {
  color: #53d581;
}

.ui.cards.productCards #cover.ui.green.statistic>.value {
  font-size: 1.2em !important;
}

.ridersAndFeatures {
  margin-bottom: 0.5rem;
  min-height: 6rem;
  flex: 1;
}

.payoutTerms {
  margin-bottom: 2rem;
  // min-height: 6rem;
}

.payoutTerms .ui.list {
  margin-top: 0.5rem;
}

.listItemStyle {
  width: 100%;
  // min-height: 64%;
  overflow-y: auto;
  max-height: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  padding: 1rem;
  z-index: 10;
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 0px 4px 2px rgba(37,44,97,0.15), 0 2px 4px 0 rgba(93,100,148,0.2);
  -moz-box-shadow: 0px 0px 4px 2px rgba(37,44,97,0.15), 0 2px 4px 0 rgba(93,100,148,0.2);
  box-shadow: 0px 0px 4px 2px rgba(37,44,97,0.15), 0 2px 4px 0 rgba(93,100,148,0.2);
}
.riderEdit {
  margin-left: 0px;
  margin-top: 0.5rem;
}
.payoutTermCardView {
  align-items: center;
}
.payoutTermCaption {
  -moz-white-space: nowrap;
  -o-white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: calc(100% - 1em);
}
.riderCaption {
  -moz-white-space: nowrap;
  -o-white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  max-width: 9.125rem;
}

// Styling for Skeleton RdiersCaption
.riderCaption span {
  margin: 0 5px;
}
.riderCaption.hoverLink:hover {
  -moz-text-decoration: underline;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  cursor: pointer;
}
.outerDivStyle {
  height: 100%;
  overflow: hidden;
}

.detailedView .innerDivStyle {
  width: 103%;
  overflow: auto;
  height: 100%;
  padding-right: 1rem;
}

.detailedView .rightColumn {
  -moz-text-align: right;
  text-align: right;
  -webkit-flex-direction: column;
     -moz-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: auto;
}
// .pegToBottom {
//   position: absolute;
//   bottom: 2rem
// }
.pegToBottom1 {
  position: absolute;
  bottom: 2rem;
}
// Styling for Skeleton Button
.pegToBottom1 span{
  margin: 0 5px;
}
.detailedView {
  width: 100% !important;
  // padding: 0rem 4rem 0rem 4rem !important;
  margin: auto;
}
.riderListItemCard .floatRight {
  margin-left: auto;
}
.riderListItemCard .tooltipIcon {
  margin: auto 0rem auto 0.25rem;
}
.riderListItemCard .ui.checkbox {
  margin: auto 0.25rem auto 0rem;
}
.riderListItemCard .riderContent {
  margin: auto 0.125rem;
  max-width: 65%;
}
.riderListItemCard .payoutContent {
  margin: auto 0.125rem;
  max-width: 80%;
}
.riderListItemCard .ui.header.riderDesc {
  margin-bottom: 0.5rem;
}

.detailedView .row {
  height: 100%;
}
.detailedView .ui.grid > .row > .column.scrollableColumn {
  padding-right: 0px;
  height: 100%;
}
.productCard .ui.list > .item.flexItems, .detailedView .ui.list > .item.flexItems {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}
.productCard .flexItems .twoItemsFlex, .detailedView .flexItems .twoItemsFlex {
  flex: 0.5;
  -moz-text-align: center;
  text-align: center;
}
.moreButton {
  float: left;
  cursor: pointer;
  width: 100%;
  letter-spacing: 1.5px;
}
.riderListItemCard .ui.form.jsonSchemaForm {
  margin-bottom: 2.5rem;
  position: unset;
}
.productCard .editAddonsButtonGroup {
  bottom: auto;
  position: absolute;
}
.cardViewMobile .ui.list > .item.benefitsListItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
  -webkit-box-align: center;
   -moz-align-items: center;
     -ms-flex-align: center;
        align-items: center;
}
.cardViewMobile .benefitsListItem .riderCaption {
    padding-left: 0.5rem;
    padding-right: 0.3rem;
}
.cardViewMobile .mobileGrid {
  -webkit-flex-direction: row;
     -moz-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  margin-top: 1.142rem;
}
.cardViewMobile .mobileGrid .ui.list {
  margin: 0.5em 0em;
}
.cardViewMobile .rightColumn {
  -moz-text-align: right;
  text-align: right;
  -webkit-flex-direction: column;
     -moz-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: auto;
}
// .inputFieldAnnuity {
//   padding: 1.2em 0 0 0.4em !important;
// }
