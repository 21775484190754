#root_inspectionDetails_inspectionMethod-field {
  display: flex;
  gap: 50px;
  .inspectionMethodWidget {
    height: 100%;
    width: 100%;
    margin: 0;
    
    
    .content {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      padding: 1.5em;
      gap: 1rem;
    }
    &.selected {
      transition: 0.1s border ease-out;
      box-shadow: 0 5px 15px 0 #2195f338, 0 2px 4px 0 #2195f31c;

      .chip {
        position: absolute;
        left: 5px;
        top: 5px;
        color: #fff;
        background-color: #2195f398;
        width: 25px;
        height: 25px;
        line-height: 25px;
        border-radius: 50%;
      }
    }
    .cardWidgetBtn {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      margin-top: 0 !important;
      position: absolute;
      .box:before,
      label:before,
      .box:after,
      label:after {
        display: none !important;
      }
      label {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        margin-bottom: 0 !important;
        padding: 0 !important;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        #card-label {
          margin-bottom: 10px;
        }
      }
    }
  }
}
