.reportTableDiv {
  .MuiPaper-root {
    display: grid;
  }
  p {
    margin: 0;
  }
  .MuiTablePagination-toolbar {
    padding: 0;
    gap: 0.5rem;
  }
  .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    margin: 0;
  }
  @media only screen and (max-width: 425px) {
    .MuiTablePagination-toolbar {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

// .MTableToolbar-root-16 {
//     padding-right: 8px;
//     background-color: whitesmoke;
// }

// }

// .MuiTablePagination-toolbar {
//     min-height: 52px;
//     padding-right: 2px;
//     background-color: whitesmoke;
// }

// .MuiTablePagination-input {
//     color: inherit;
//     font-size: inherit;
//     flex-shrink: 0;
//     // background-color: antiquewhite;
//     position: absolute !important;
// }

// .MuiTablePagination-spacer {
//     flex: inherit !important;
//     // flex: 1 !important;
//     margin-left: 5rem !important;
// }
