@import 'assets/styles/variables.scss';

.fade {
  transition: ease-in 0.15s;
  transition-property: opacity, transform;
  padding-bottom: 8px;
}

.fade-entering {
  opacity: 0;
  transform: translateY(-10%);
  padding-bottom: 8px;
}
.board {
  margin: auto;
  width: 50%;
  list-style-type: none;
  padding: 0;
}

.message-block {
  justify-content: space-between;
}
.msgicon {
  font-size: 1em !important;
}
.msgdiv {    
  position: fixed;
  width: 100%;
  z-index: 30;
  bottom: 24px;
}
.closebutton {
  float: right !important;
  margin: 0 !important;
  background-color: #94c3a6 !important;
  padding: 4px 7px !important;
  border-radius: 7px !important;
  box-shadow: 0px 0px 0px 1px #00ae42 inset, 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.customNotifications .ui.message {
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  color: $white !important;
  background-color: #ccc;
  -webkit-box-shadow: 0 0 12px #999;
  box-shadow: 0 0 12px #999;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: .9;
}
.customNotifications .ui.success.message {
  cursor: pointer;
  background-color: $green;
}
.customNotifications .ui.error.message {
  cursor: pointer;
  background-color: $red;
}
.customNotifications .ui.info.message {
  background-color: rgb($secondary);
}
