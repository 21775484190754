@import "assets/styles/variables.scss";

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  cursor: default !important;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
.onboardHeader {
  max-height: 500px;
}

.intro-section {
  background-size: cover;
  padding: 100px;
  max-height: inherit;

  h1,
  .banner-desc {
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  }
  .banner-desc {
    font-size: 20px;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 10px;
  }
}
.horizontal-divider {
  display: none;
}
.vertical-divider {
  display: block;
}
.jumbotron {
  margin-bottom: 3rem;

  h1 {
    font-size: 2.8rem !important;
    padding: 10px;
    font-weight: 500;
  }
}
.main {
  background: #fff;
  background-size: cover;
  padding: 60px;
}
.main2 {
  background: #def6ff;
  background-size: cover;
  padding: 60px;
}
.main3 {
  background-size: cover;
  padding: 100px;
}
.main4 {
  background-size: cover;
  padding: 60px;
}

.form-container {
  margin-top: -105px;
}

.main.cardSection {
  .cardContent {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0.25rem;

    .cardContentImageContainer {
      width: 80px;
      height: 80px;
      img.cardContentImage {
        object-fit: contain !important;
      }
    }
    .cardContentTitle {
      margin: 0;
      padding:0;
      font-size: 1rem !important;
  
    }
    .cardContentDescription {
      display: block;
      font-size: 0.85rem;
      color: $blueGrey;
    }
  }
}

.accordion {
  .accordion-button:focus {
    box-shadow: 0 0 0 0.25rem rgba($secondaryLight, 0.2);
    border-color: rgba($secondaryLight, 0.2);
  }
  .accordion-button:not(.collapsed) {
    background-color: rgba(($secondaryLight), 0.3);
    color: rgb($secondary);
  }
  .accordion-button svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    flex-shrink: 0;
    transition: transform 0.2s ease-in-out;
  }
  .accordion-button:not(.collapsed) svg {
    fill: rgb($secondary);
    transform: rotate(180deg);
  }
  .accordion-button::after {
    display: none;
  }
}
.shadow-card {
  list-style: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  padding-left: 0;
  position: relative;
  box-shadow: 0 0 15px rgba(169, 169, 169, 0.25);
  -webkit-box-shadow: 0 0 15px rgba(169, 169, 169, 0.25);
  -moz-box-shadow: 0 0 15px rgba(169, 169, 169, 0.25);
  display: inline-block;
  width: 100%;
  padding: 25px;
}
.submit-btn {
  background: rgb($primary) !important;
  border: 0;
  padding: 10px 91px !important;
  color: #fff !important;
  transition: 0.4s !important;
  cursor: pointer;
  border-radius: 4px !important;
  float: right;
  text-transform: uppercase;
  font-weight: normal !important;
  margin: 0px 36px !important;
}

.submit-btn:disabled {
  color: #989898;
  background-color: #cccccc;
  border-color: #cccccc;
}

@media (max-width: 1366px) {
  .intro-section {
    padding: 60px;
  }
  h1 {
    font-size: 40px;
    padding: 10px;
  }
  .banner-desc {
    font-size: 19px;
  }
}

@media (max-width: 1024px) {
  .form-container {
    margin-top: -45px;
  }
  .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 0;
  }

  .banner-desc {
    font-size: 20px;
    width: 100%;
    margin: 0 auto;
  }
  h1 {
    font-size: 41px;
    padding: 10px;
  }
  .submit-btn {
    margin: 0px 10px;
  }
  .intro-section {
    background-size: cover;
    padding: 0;
  }
  .main {
    background: #fff;
    background-size: cover;
    padding: 10px;
  }
  .main2 {
    background: #def6ff;
    background-size: cover;
    padding: 30px;
  }
  .main3 {
    background-size: cover;
    padding: 18px;
  }
  .main4 {
    background: rgb($primaryDark);
    background-size: cover;
    padding: 20px 0;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 35px;
    padding: 10px;
  }
  .banner-desc {
    font-size: 19px;
    width: 100%;
    margin: 0 auto;
  }
  .submit-btn {
    float: none;
    margin: 0 auto;
  }
}

@media (max-width: 640px) {
  .intro-section {
    background-size: cover;
    padding: 19px 0 0;
    background: rgb($primaryDark) !important;
  }
  h1 {
    font-size: 30px;
    padding: 10px;
  }
  .jumbotron {
    padding: 0 10px;
    margin-bottom: 0;
  }
  .submit-btn {
    float: none;
    margin: 0 auto;
  }
  .horizontal-divider {
    display: block;
    margin: 0 auto;
    padding: 10px;
  }
  .vertical-divider {
    display: none;
  }
}

@media (max-width: 320px) {
  h1 {
    font-size: 23px;
    padding: 10px;
  }
  .banner-desc {
    font-size: 13px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 15px;
  }
}

/* Added by smartcovr */

.welcomeHeader {
  background: $backgroundGrey;
  padding: 48px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .welcomeHeader {
    background: $backgroundGrey;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 80px;
    align-items: center;
  }
  .rowCenter {
    margin: 0px;
    padding: 0px;
    text-align: center;
    display: inline-block;
  }
  .banner-desc {
    font-size: 20px !important;
    width: 100% !important;
    margin: 0 auto !important;
    padding-bottom: 32px !important;
  }
  .submit-btn {
    border: 0;
    padding: 10px 20px !important;
    color: #fff;
    transition: 0.4s;
    cursor: pointer;
    border-radius: 4px !important;
    text-transform: uppercase;
    margin-top: 0px;
  }
  .horizontal-divider {
    display: block;
    margin: 0 auto;
    padding: 10px;
  }
  .vertical-divider {
    display: none;
  }
}

@media screen and (max-width: 780px) {
  .wow {
    animation: none !important;
    visibility: visible !important;
  }
}
