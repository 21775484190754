@import "assets/styles/variables.scss";

.idv-filter {
  // display: flex;
  // flex-direction: column;
  // align-items: stretch;
  // height: 100%;
  // justify-content: space-evenly;
  &-title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    @media screen and (max-width: 767px) {
      margin-bottom: 0.2rem;
    }
    .ui.header {
      margin-bottom: 0;
    }
  }

  &-content {
    @media screen and (max-width: 767px) {
      text-align: left;
      font-size: 1rem;

      .icon {
        font-size: 0.9rem;
      }
    }
    .icon,
    &-amount {
      color: green;
    }
  }
  .edit-idv-btn {
    color: rgb($secondary);
    font-weight: 600;
    cursor: pointer;
    margin-left: 0.5rem;
  }
}
.idv-modal {
  position: absolute;
  top: 4em;
  max-width: -webkit-fill-available;
  min-width: 25rem;
  width: 100%;
  &-header {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  &-title {
    display: flex;
    align-items: center;
    .ui.header {
      margin: 0;
    }
  }
  .idv-modal-amount {
    display: flex;
    align-items: center;
    margin: 0 !important;

    i.icon {
      margin: 0;
    }
  }
  .rc-slider {
    margin: 2.5rem auto;
    &-rail,
    &-track,
    &-step {
      height: 10px;
    }

    &-rail {
      background-color: rgba($secondaryLight, 0.1);
    }
    &-track {
      background-color: rgb($secondaryLight);
    }
    &-dot {
      display: none;
    }
    &-handle {
      height: 20px;
      width: 20px;
      cursor: pointer;
      border: 2px solid rgba($secondaryLight, 0.5);
    }

    &-mark {
      top: 20px;
      font-size: 14px;
      font-weight: 600;
      i.icon {
        margin: 0 !important;
      }
      :first-child {
        white-space: nowrap;
        left: 10% !important;
      }
      :last-child {
        white-space: nowrap;
        left: 90% !important;
      }
    }
    &-tooltip {
      width: fit-content;
      padding-top: 4px;
      &-arrow {
        border-top-color: rgb($secondaryLight);
      }
      &-inner {
        border-radius: 8px;
        padding: 10px;
        height: auto;
        background-color: rgb($secondaryLight);
        box-shadow: 0px 10px 15px -3px rgba($secondaryLight, 0.3);
      }
    }
  }

  @media screen and (min-width: 320px) and (max-width: 510px) {
    // idv-modal
    min-width: 98%;
    &-header {
      padding: 1rem 0.8rem !important;
    }
    &-content {
      padding: 0.5rem 0.8rem !important;
    }
    .rc-slider {
      margin: 2rem auto;
      max-width: 95%;
      &-mark {
        top: 25px;
        font-size: 14px;
        font-weight: 500;
        :first-child {
          white-space: nowrap;
          left: 12% !important;
        }
        :last-child {
          white-space: nowrap;
          left: 88% !important;
        }
      }
    }
  }

  @media screen and (min-width: 510px) and (max-width: 768px) {
    &-header {
      padding: 1.2rem 1rem !important;
    }
    &-content {
      padding: 0.5rem 1rem !important;
    }
    .rc-slider {
      margin: 2rem auto;
      max-width: 95%;
      &-mark {
        top: 20px;
        font-size: 14px;
        font-weight: 500;
        :first-child {
          white-space: nowrap;
          left: 8% !important;
        }
        :last-child {
          white-space: nowrap;
          left: 92% !important;
        }
      }
    }
  }
}
