.policyDetailsMainPageSegment {
    // background-color: aliceblue !important;
    padding: 0 !important;
    .titleAndDownloadBrochure {
        margin: 2.45rem 2.45rem 2.6875rem 2.45rem !important;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 767px){
             margin: 1.6rem 0.5rem 1rem 0.5rem !important;
        }
        // background-color: gainsboro;
        // border: 1px solid grey;
        H6 {
            margin-bottom: 0;
            // border-bottom: 1px solid red;
        }
        .downloadLinks {
            // background-color: aquamarine;
            display: flex;
            flex-direction: column;
            // align-items: center;
        }
    }
    .block {
        border-top: 2px solid #f7f7f7;
        // background-color: antiquewhite;
        H6 {
            margin-top: 0;
        }
    }
    .sumAssured {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 1.45rem;
        .caption {
            // background-color: aquamarine;
            margin-bottom: -0.51rem;
        }
        .sumAssuredValue {
            background-color: burlywood;
            // display: flex;
            justify-content: center;
        }
        H5 {
            border-bottom: 2px solid rgba(130, 140, 144, 0.3);
            padding-bottom: 1.125rem !important;
            margin-bottom: 1rem !important;
        }
    }
    .premiumData {
        // padding: 5px;
        margin-bottom: 1.8rem;
        // border: 1px solid red;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 767px){
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        .lastPremiumDate {
            // border: 1px solid green;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            margin-right: 1.5rem;

            @media screen and (max-width: 767px){
                justify-content: center;
            }

            H6 {
                margin-bottom: 0.35rem !important;
                @media screen and (max-width: 767px){
                    text-align: center;
                }
            }
            .subtitle2 {
                margin-top: 0;
                @media screen and (max-width: 767px){
                    text-align: center;
                }
                // background-color: aqua;
            }
        }
        .numberOfPremiums {
            // border: 1px solid goldenrod;
            display: flex;
            align-items: center;
            flex-direction: column;
            // also try with max width 400px
            @media screen and (max-width: 400px){
                width: 120px !important; 
                height: 120px !important;
                justify-content: center;
            }
            @media screen and (min-width: 401px) and (max-width: 767px){
                justify-content: center;
            }
        }
        .nextPremiumDate {
            // border: 1px solid brown;
            display: flex;
            align-items: center;
            margin-left: 1.5rem;
            flex-direction: column;
            H6 {
                margin-bottom: 0.35rem !important;
                @media screen and (max-width: 767px){
                    text-align: center;
                    justify-content: center;
                }
            }
            .subtitle2 {
                margin-top: 0;
                // background-color: aqua;
                @media screen and (max-width: 767px){
                    text-align: center;
                }
            }
        }
    }
}

div.dataButton {
    margin-top: 1.5rem;
    margin-bottom: -2.4375rem;
    color: black;
    // background-color: bisque;
    font-weight: 500;
    font-size: 0.82rem;
    cursor: pointer;
}
