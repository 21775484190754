.tabs {
	ul {
		display: flex;
	}
	ul li {
		flex: 1 0 25%;
	}
}

.tabs-titles {
	list-style: none;
	padding: 0px;
	margin: 0;
}

.tab-title {
	background-color: #99a1a4;
	text-align: center;
	cursor: pointer;
	margin-left: 1px;
	.title {
		padding: 1rem !important;
		text-transform: uppercase !important;
		color: #f5f5f5 !important;
	}
}

.tab-title--active {
	background-color: #707b81;
	color: #f5f5f5;
}

.tab-content {
	margin: 2.5rem 2.5rem 1.5rem 2.5rem;
	@media screen and (max-width: 767px) {
		margin: 1rem 0.5rem 1rem 0.5rem !important;
		.policyDetailsTabContent {
			div.riderData > .riderContentBody {
				display: unset !important;
				.sumAssuredAndPremiumDiv {
					display: flex;
					justify-content: space-between;
					margin-top: 0.5rem;
				}
			}
			div.features > div > .featureContent {
				.featureHeadingAndDescription {
					flex: 0.7 !important;
				}
				.featureType {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					flex: 0.3 !important;
					margin-left: 1rem;
					padding-right: 0rem !important;
				}
			}
		}
	}
	// border: 1px solid palevioletred;
	.policyUpdatesTabContent {
		// background-color: antiquewhite;
		H6 {
			margin-bottom: 1.5rem;
		}
		.upcomingPremiumsForPolicy {
			// border: 1px solid green;
		}
		.previousPremiumForPolicy {
			// border: 2px solid blue;
			margin-top: 3.5rem;
		}
		.premiumDataContent,
		.premiumDataContentVm {
			margin-bottom: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid rgba(74, 74, 74, 0.2);
			// background-color: aqua;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.premiumDetails {
				display: flex;
				flex: 0.55;
				flex-direction: column;
				// background-color: blanchedalmond;
				.subtitle1 {
					margin-bottom: 0;
					font-weight: 500;
				}
				.body2 {
					margin: 0.25rem 0 0.25rem 0;
				}
			}
			.payButton,
			.payAmount {
				flex: 0.35;
				// margin-right: 2rem;
				display: flex;
				justify-content: center;
				// border: 1px solid darkgreen;
				// background-color: chartreuse;
				.buttonStyle {
					min-width: 60%;
					max-width: 60%;
					@media screen and (max-width: 767px) {
						min-width: 100% !important;
						max-width: 100% !important;
					}
				}
				.body1 {
					// border: 1px solid darkgreen;
					display: flex;
					align-items: center;
					margin: 0;
					H6 {
						margin: 0;
						margin-left: -0.35rem;
						padding: 0;
					}
				}
			}
		}

		.premiumDataContentVm:nth-last-child(2) {
			// background-color: cyan;
			border-bottom: none;
			padding-bottom: 0;
		}
		.premiumDataContent:last-child {
			// background-color: yellow;
			border-bottom: none;
			padding-bottom: 0;
		}
	}
	.policyDetailsTabContent {
		// border: 1px solid saddlebrown;
		.productData {
			// background-color: #61b151;
			margin-bottom: 1.5rem;
		}
		div.row {
			display: flex;
			align-content: center;
			width: 100%;
			// background-color: yellow;
			margin-bottom: 1rem;

			div.columnData {
				display: flex;
				flex-basis: 50%;
				// border: 1px solid greenyellow;
				// background-color: mediumaquamarine;
			}

			div.td {
				display: flex;
				flex: 1;
				justify-content: space-between;
				margin-right: 1rem;
				// background-color: sandybrown;

				.hrtag {
					// background-color: lawngreen;
					margin-top: 0.5rem;
					margin-bottom: 1rem;
					// margin-right: 4rem;
				}

				.TDataTitle {
					// background-color: lawngreen;
					display: flex;
					flex-basis: 3rem;
				}

				.TDataValue {
					// background-color: lightcoral;
					display: flex;
					flex-basis: 3rem;
					word-break: keep-all;

					.amountWithIcon {
						margin: 0;
						display: flex;
						justify-content: flex-start;
						// background-color: aqua;
					}
				}

				div.A {
					margin-left: 0.5rem;
				}
			}

			.caption {
				// background-color: burlywood;
				// margin-top: 0.5rem;
			}
		}
		div.features > div {
			// background-color: aqua;
			width: 100%;
			.titleHeader {
				margin-bottom: 1rem;
			}
			.featureContent {
				// border: 1px solid red;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid grey;
				margin-bottom: 1rem;
				padding-bottom: 1rem;
				.featureHeadingAndDescription {
					// background-color: aliceblue;
					flex: 0.55;
				}
				.featureHeadingAndDescription p {
					margin-bottom: 0;
				}
				.featureType {
					// background-color: aquamarine;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					flex: 0.45;
					margin-left: 1rem;
					padding-right: 5rem;
					.featureTypeText {
						color: #61b151;
						font-size: medium;
					}
				}
			}
			.featureContent:last-child {
				border-bottom: none;
			}
		}
		div.riderData {
			// border: 1px solid green;
			display: flex;
			flex: 1;
			flex-direction: column;

			div.riderContentBody {
				// border: 1px solid red;
				padding-bottom: 1rem;
				margin: 0.5rem;
				margin-left: 0;
				margin-right: 0.3rem;
				word-break: keep-all;
				border-bottom: 1px solid rgba(41, 41, 40, 0.678);
				// background-color: aquamarine;
				display: flex;
				.riderNameAndDescription {
					flex: 0.5;
					// background-color: aquamarine;
				}
				.riderNameAndDescription p {
					margin-bottom: 0;
				}

				.riderValues {
					flex: 0.25;
					margin-left: 0.3rem;
					margin-right: 0.3rem;
					display: flex;
					align-items: center;
					flex-direction: column;
					// border: 1px solid crimson;
					.iconAndAmount {
						// border: 1px solid crimson;
						display: flex;
						align-items: center;
					}
					.amount {
						font-size: medium;
						margin-left: -0.2rem;
					}
				}
			}
			div.riderContentBody:last-child {
				border-bottom: none;
			}
		}
		div.productBenefitsData {
			display: flex;
			flex: 1;
			flex-direction: column;
			// background-color: cadetblue;
			.titleHeader {
				margin-bottom: 1rem;
			}
			.pbData {
				border-bottom: 1px solid grey;
				margin-bottom: 1rem;
				padding-bottom: 1rem;
			}
			.pbData p {
				margin-bottom: 0;
			}
			.pbData:last-child {
				border-bottom: none;
			}
		}
	}
}
