.productImage {
  height: 1.25rem !important;
  width: 3rem !important;
  margin-bottom: 0 !important;
  object-fit: cover;
}
.carProductImage, .bikeProductImage {
  height: 1.6rem !important;
  width: 3rem !important;
  margin-bottom: 0 !important;
  object-fit: cover;
}

.termProductImage {
  height: 1.25rem;
  width: 3rem !important;
  margin-bottom: 0 !important;
  object-fit: cover;
}
.healthProductImage {
  height: 1.25rem;
  width: 3rem !important;
  margin-bottom: 0 !important;
  object-fit: cover;
}